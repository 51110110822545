import { DatePicker, Divider, Form, Modal } from "antd";
import { ExportToCsv } from "export-to-csv";
import { isEmpty } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hubsAction } from "../../../actions";
import { toolsAction } from "../../../actions/tools.actions";
import CustomInput from "../../../components/input/input";
import CustomSelect from "../../../components/select/select";
import {
  generateAggReport,
  generateStandardReport,
} from "../../../utils/generateResourceReport";

const { RangePicker } = DatePicker;

export default function ResourceDefinitions(props) {
  const dispatch = useDispatch();
  const { resources, resourceReportData } = useSelector((state) => state.tools);
  const { activeHubs } = useSelector((state) => state.hubs);
  const locationState = props.location.state;
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const [generateReportData, setGenerateReportData] = useState({
    hubId: null,
    trackingNo: null,
    dateRange: [],
    reportType: null,
  });
  const [hubOptions, setHubOptions] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      toolsAction.getResource(`?company_id=${props.location.state.companyId}`)
    );
    dispatch(hubsAction.getActiveHubs(props.location.state.companyId));
  }, []);

  useEffect(() => {
    if (activeHubs) {
      const hubs = activeHubs.map((hub) => ({
        label: hub.hub_name,
        value: hub.hub_id,
      }));
      setHubOptions(hubs);
    }
  }, [activeHubs]);

  useEffect(() => {
    if (!isEmpty(resourceReportData)) {
      const csvOptions = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        useTextFile: false,
        useBom: true,
        showLabels: true,
        useKeysAsHeaders: true,
      };
      let csvData = { data: [], headers: [] };
      if (generateReportData.reportType === "standard") {
        csvData = generateStandardReport(resourceReportData);
      } else if (generateReportData.reportType === "aggregated") {
        csvData = generateAggReport(resourceReportData, resources);
      }
      const csvExporter = new ExportToCsv(csvOptions);
      csvExporter.generateCsv(csvData);
      setGenerateReportData({
        hubId: null,
        trackingNo: null,
        dateRange: [],
        reportType: null,
      });
      setShowGenerateReportModal(false);
    }
  }, [resourceReportData]);

  const handleCompanyNameClick = () => {
    props.history.goBack();
  };

  const handleEditResourcesClick = () => {
    props.history.push({
      pathname: `/tools/resource-definitions/${locationState.companyName}/${locationState.companyId}/edit-resources`,
      state: {
        companyName: locationState.companyName,
        companyId: locationState.companyId,
      },
    });
  };

  const displayGenerateReportModal = () => {
    setShowGenerateReportModal(true);
  };

  const hideGenerateReportModal = () => {
    setGenerateReportData({
      hubId: null,
      trackingNo: null,
      dateRange: [],
      reportType: null,
    });
    setShowGenerateReportModal(false);
  };

  const onGenerateReport = () => {
    const requestPayload = {
      hub_id: generateReportData.hubId,
    };
    if (generateReportData.trackingNo) {
      requestPayload.tracking_nos = [generateReportData.trackingNo];
    }
    if (generateReportData.dateRange.length) {
      requestPayload.created_before = new Date(generateReportData.dateRange[0]);
      requestPayload.created_after = new Date(generateReportData.dateRange[1]);
    }
    if (generateReportData.reportType === "standard") {
      dispatch(toolsAction.getStandardResourceReport(requestPayload));
    } else if (generateReportData.reportType === "aggregated") {
      dispatch(toolsAction.getAggregatedResourceReport(requestPayload));
    }
  };

  const onFormItemValueChange = (name, value) => {
    setGenerateReportData({
      ...generateReportData,
      [name]: value,
    });
  };

  const renderGenerateReportModal = () => {
    return (
      <Modal
        className="generate-csv-modal"
        visible={showGenerateReportModal}
        footer={null}
        okText="Confirm"
        okButtonProps={{ className: "modal-primary-button" }}
        cancelButtonProps={{ className: "modal-secondary-button" }}
        trackinNo
        cancelText="Cancel"
      >
        <div className="text-left">
          <h2 className="generate-csv-heading mb-0">Generate Report</h2>
          <p className="generate-csv-paragraph mb-0">
            The report in CSV will be sent to your email address after it is
            generated.
          </p>
        </div>
        <Form
          form={form}
          onFinish={onGenerateReport}
          initialValues={generateReportData}
        >
          <div className="mt-4">
            <Form.Item
              name="hubId"
              rules={[
                {
                  type: "string",
                  required: true,
                },
              ]}
            >
              <CustomSelect
                label="Hub"
                active={generateReportData.hubId}
                onChange={(value) => onFormItemValueChange("hubId", value)}
                options={hubOptions}
              />
            </Form.Item>
          </div>
          <div className="mt-4">
            <Form.Item
              name="trackingNo"
              rules={[
                {
                  type: "string",
                  required: false,
                },
              ]}
            >
              <CustomInput
                label="Tracking number (Optional)"
                placeholder="Type here"
                value={generateReportData.trackingNo}
                onChange={(e) =>
                  onFormItemValueChange("trackingNo", e.target.value)
                }
              />
            </Form.Item>
          </div>
          <div name="mt-4">
            <Form.Item
              rules={[
                {
                  type: "array",
                  required: false,
                },
              ]}
            >
              <h6 className="form-label">Date Range (Optional)</h6>
              <RangePicker
                value={generateReportData.dateRange}
                onChange={(value) => onFormItemValueChange("dateRange", value)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <div className="mt-4">
            <Form.Item
              name="reportType"
              rules={[
                {
                  type: "string",
                  required: true,
                },
              ]}
            >
              <CustomSelect
                label="Report type"
                active={generateReportData.reportType}
                onChange={(value) => onFormItemValueChange("reportType", value)}
                options={[
                  {
                    label: "Standard columns (TN, Name, Tag, Count)",
                    value: "standard",
                  },
                  {
                    label: "Columns made up by resource name",
                    value: "aggregated",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <button
              className="modal-secondary-button"
              onClick={hideGenerateReportModal}
            >
              Cancel
            </button>
            <button className="modal-primary-button" type="submit">
              Confirm
            </button>
          </div>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      <div className="bg-container">
        <p className="title bread-crumb-container">Resource Definitions</p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column">
            <h2 className="heading-link" onClick={handleCompanyNameClick}>
              Filtered by: {locationState && locationState.companyName}
            </h2>
            <div>
              <button
                className="secondary-button secondary-button-margin-md"
                onClick={handleEditResourcesClick}
              >
                Edit Resources
              </button>
              <button
                className="primary-button secondary-button-margin-md ml-2"
                onClick={displayGenerateReportModal}
              >
                Generate Report
              </button>
            </div>
          </div>
          <div className="table-responsive table-fixed mt-5">
            <table className="table table-striped table-borderless packages-table">
              <thead className="table-header--bottom-border">
                <tr>
                  <th>Name</th>
                  <th>Tag</th>
                </tr>
              </thead>
              <tbody>
                {resources.length > 0 &&
                  resources.map((resource) => {
                    return (
                      <tr key={resource.id}>
                        <td>{resource.resource_name}</td>
                        <td>{resource.tag_name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {renderGenerateReportModal()}
    </>
  );
}
