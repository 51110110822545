import { loginTypes } from '../types';

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null
}

const loginReducer = (state=initialState,action) => {
  switch(action.type) {
    case loginTypes.LOGIN_LOADING:
      return { ...state, loading: true }
    case loginTypes.LOGIN_SUCCESS:
      return { ...state, successMessage: action.payload, loading: false }  
    case loginTypes.LOGIN_FAILURE:
      return { ...state, errorMessage: action.payload, loading: false } 
    case loginTypes.CLEAR_RESPONSE:
      return { ...state, errorMessage: null }     
    default:
      return state;        
  }
}

export { loginReducer }