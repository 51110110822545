import React, { useState } from 'react';

const SortColumnHeader = ({ callBack, headerText }) => {
  const [showSortIcon, setShowSortIcon] = useState(false);

  const mouseEnterHandler = (e) => {
    e.stopPropagation();
    setShowSortIcon(true);
  }

  const mouseLeaveHandler = (e) => {
    e.stopPropagation();
    setShowSortIcon(false);
  }
  return (
    <span onClick={callBack}>
      {showSortIcon && <i className="fas fa-sort mr-1"></i>}
      <span className="cursor-pointer" onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>{headerText}</span>
    </span>
  )
}

export default SortColumnHeader;