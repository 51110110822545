import axios from 'axios';
import { ROOT_URL } from './rootUrl';

const createCompanyScanUser = (data) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/hub_scanners/`,
    data : data,
    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
  }).then(res => res.data)
}

const updateCompanyScanUser = (data) => {
  return axios({
    method: 'PUT',
    url: `${ROOT_URL}/api/hms/admin/hub_scanners/`,
    data : data,
    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
  }).then(res => res.data)
}

const getCompanyScanUsers = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hub_scanners/?company_id=${data.companyId}&is_active=${1}`,
    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
  }).then(res => res.data.data)
}

const getCompanyInactiveScanUsers = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hub_scanners/?company_id=${data.companyId}&is_active=${0}`,
    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
  }).then(res => res.data.data)
}

const createHubScanUser = (data) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/hub_scanners/`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const updateHubScanUser = (data) => {
  return axios({
    method: 'PUT',
    url: `${ROOT_URL}/api/hms/admin/hub_scanners/`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getHubScanUsers = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hub_scanners/?company_id=${data.companyId}&hub_id=${data.hubId}&is_active=${1}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data.data)
}

const getHubInactiveScanUsers = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hub_scanners/?company_id=${data.companyId}&hub_id=${data.hubId}&is_active=${0}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data.data)
}

export const scanUsersService = {
  createCompanyScanUser,
  updateCompanyScanUser,
  getCompanyScanUsers,
  getCompanyInactiveScanUsers,

  createHubScanUser,
  updateHubScanUser,
  getHubScanUsers,
  getHubInactiveScanUsers
}