import React from 'react';
import { Route } from 'react-router-dom';
import Nav from '../../components/nav/nav';
import HubManagersFilter from './hubManagersFilter/hubManagersFilter';
import CompanyManagersList from './companyManagersList/companyManagersList';
import AddCompanyManager from './addCompanyManager/addCompanyManager';
import HubManagersList from './hubManagersList/hubManagersList';
import AddHubManager from './addHubManager/addHubManager';


export default function ScanUsers() {
  return (
    <>
      <Nav />
      <Route exact path="/hub-managers" component={HubManagersFilter} />
      <Route exact path="/hub-managers/:companyName/:companyId" component={CompanyManagersList} />
      <Route exact path="/hub-managers/company/manager/add/new/:companyId" component={AddCompanyManager} />
      <Route exact path="/hub-managers/company/manager/update/:user/:companyId/:hubManagerId" component={AddCompanyManager} />

      <Route exact path="/hub-managers/:companyName/:hubName/:companyId/:hubId" component={HubManagersList} />
      <Route exact path="/hub-managers/company/hub/manager/add/new/:companyId/:hubId" component={AddHubManager} />
      <Route exact path="/hub-managers/company/hub/manager/update/:user/:companyId/:hubId/:hubManagerId" component={AddHubManager} />
    </>
  )
}
