/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { message, Divider } from 'antd';
import { scanUsersAction, companyAction, hubsAction } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as _ from 'lodash';
import './style.scss'


export default function HubScanUserDetails() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const {
    hubActiveScanUsers,
    hubInactiveScanUsers,
    hubScanUserCreated,
    createHubScanUserSuccessMessage,
    hubScanUserUpdated,
    updateHubScanUserSuccessMessage
  } = useSelector(state => state.scanUsers);

  const { companies } = useSelector(state => state.companies);
  const { activeHubs } = useSelector(state => state.hubs);

  useEffect(() => {
    dispatch(scanUsersAction.getHubScanUsers(params));
    dispatch(scanUsersAction.getHubInactiveScanUsers(params));
  }, [params, dispatch]);

  useEffect(() => {
    if (hubScanUserCreated) {
      message.success(createHubScanUserSuccessMessage);
      dispatch(scanUsersAction.resetCreatedHubScanUserData());
    }
  }, [hubScanUserCreated, createHubScanUserSuccessMessage, dispatch]);

  useEffect(() => {
    if (hubScanUserUpdated) {
      message.success(updateHubScanUserSuccessMessage);
      dispatch(scanUsersAction.resetUpdatedHubScanUserData());
    }
  }, [hubScanUserUpdated, updateHubScanUserSuccessMessage, dispatch]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
    if (activeHubs && activeHubs.length === 0) {
      dispatch(hubsAction.getActiveHubs(params.companyId));
    };
  }, [companies, activeHubs, params.companyId, dispatch]);

  const company = _.find(companies, x => String(x.id) === params.companyId);
  const hub = _.find(activeHubs, x => String(x.hub_id) === params.hubId);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleScanUserClick}>Scan Users</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="link-title" onClick={handleCompanyBreadCrumbClick}>{company && company.name}</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="title">{hub && hub.hub_name}</span>
        </p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column">
            <h2
              className="heading-link"
              onClick={handleCompanyNameClick}>
              Company: {company && company.name} &nbsp; {params.hubId && '|'} &nbsp; {params.hubId && `Hub: ${hub && hub.hub_name}`}
            </h2>
            <button className="secondary-button secondary-button-margin-md" onClick={handleAddScanUserClick}>Add Scan User</button>
          </div>
          <Divider className="gray-divider" />
          <div className="d-flex flex-column heading-area">
            <h2 className="heading heading-area">Active Scan Users</h2>
            <span className="sub-heading">Click on a scan user to edit them.</span>
          </div>
          <div className="row">
            {renderActiveUsers()}
          </div>
          {hubInactiveScanUsers && hubInactiveScanUsers.length > 0 &&
            <React.Fragment>
              <Divider className="gray-divider mt-0" />
              <div className="d-flex flex-column heading-area">
                <h2 className="heading">Inactive Scan Users</h2>
              </div>
              <div className="row">
                {renderInactiveUsers()}
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </>
  )

  function handleCompanyBreadCrumbClick() {
    let company = _.find(companies, x => String(x.id) === params.companyId);
    history.push(`/scan-users/${company.name.toLowerCase()}/${params.companyId}`);
  }

  function handleCompanyNameClick() {
    history.push({
      pathname: `/scan-users`,
      state: {
        companyId: params.companyId,
        hubId: params.hubId
      }
    })
  }

  function renderActiveUsers() {
    return hubActiveScanUsers.map((user, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 scan-user-link-container"
        onClick={() => handleScanUserDetailsClick(user)}>
        <span className="text-link">{user.hub_scanner_name}</span>
      </div>
    })
  }

  function renderInactiveUsers() {
    return hubInactiveScanUsers.map((user, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 scan-user-link-container"
        onClick={() => handleScanUserDetailsClick(user)}>
        <span className="text-link">{user.hub_scanner_name}</span>
      </div>
    })
  }

  function handleScanUserDetailsClick(user) {
    dispatch(scanUsersAction.resetCreatedHubScanUserData());
    dispatch(scanUsersAction.resetUpdatedHubScanUserData());
    history.push(`/scan-users/company/hub/user/update/${user.hub_scanner_name.toLowerCase()}/${params.companyId}/${params.hubId}/${user.hub_scanner_id}`);
  }

  function handleScanUserClick() {
    history.push('/scan-users');
  }

  function handleAddScanUserClick() {
    dispatch(scanUsersAction.resetCreatedHubScanUserData());
    dispatch(scanUsersAction.resetUpdatedHubScanUserData());
    history.push(`/scan-users/company/hub/user/add/${params.companyId}/${params.hubId}`)
  }
}
