import { locationTypes } from '../types';
import { locationService } from '../services';
import * as _ from 'lodash';


const getSortedLocations = (countries) => {
  countries = _.orderBy(countries, 'country_name', 'asc');
  countries = countries.map((country) => {
    country.value = country.country_name;
    country.label = country.country_name;
    return country;
  });
  return countries;
}

const getCountries = () => {
  const loading = () => ({ type: locationTypes.FETCH_COUNTRIES_LOADING })
  const success = (payload) => ({ type: locationTypes.FETCH_COUNTRIES_SUCCESS, payload })
  const failure = (payload) => ({ type: locationTypes.FETCH_COUNTRIES_FAILURE, payload })

  return async function (dispatch) {
    loading();
    try {
      let countries = await locationService.getCountries();
      countries = getSortedLocations(countries)
      dispatch(success(countries));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}

const getSortedStates = (states) => {
  states = _.orderBy(states, 'state_name', 'asc');
  states = states.map((state) => {
    state.value = state.state_name;
    state.label = state.state_name;
    return state;
  });
  return states;
}

const getStates = (data) => {
  const loading = () => ({ type: locationTypes.FETCH_STATES_LOADING })
  const success = (payload) => ({ type: locationTypes.FETCH_STATES_SUCCESS, payload })
  const failure = (payload) => ({ type: locationTypes.FETCH_STATES_FAILURE, payload })

  return async function (dispatch) {
    loading();
    try {
      let states = await locationService.getStates(data);
      states = getSortedStates(states)
      dispatch(success(states));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}

const getSortedCities = (cities) => {
  cities = _.orderBy(cities, 'city_name', 'asc');
  cities = cities.map((city) => {
    city.value = city.city_name;
    city.label = city.city_name;
    return city;
  });
  return cities;
}

const getCities = (data) => {
  const loading = () => ({ type: locationTypes.FETCH_CITIES_LOADING })
  const success = (payload) => ({ type: locationTypes.FETCH_CITIES_SUCCESS, payload })
  const failure = (payload) => ({ type: locationTypes.FETCH_CITIES_FAILURE, payload })

  return async function (dispatch) {
    loading();
    try {
      let cities = await locationService.getCities(data);
      cities = getSortedCities(cities)
      dispatch(success(cities));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}

export const locationAction = {
  getCountries,
  getStates,
  getCities
}
