import axios from 'axios';
import { ROOT_URL } from './rootUrl';

const getHubPackges = async (payload) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/get_hub_tracking_nos_metadata/`,
    data: payload,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }).then(res => res.data);
}

const getHubCargo = async (payload) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/cargo_level_report_for_cargo_reconciliation_json/?page=${payload.page_number}&page_size=${payload.pageSize}`,
    data: payload,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }).then(res => res.data);
}

const generatePackageCSV = (data) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/mail_hub_tracking_nos_metadata/`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const generatePackageLevelReport = (data) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/mail_cargo_recon_package_level_report/`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const generateCargoLevelReport = (data) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/mail_cargo_recon_cargo_level_report/`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

export const hubPackageServices = {
  getHubPackges,
  getHubCargo,
  generatePackageCSV,
  generatePackageLevelReport,
  generateCargoLevelReport
}
