/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import './style.scss';

export default function companyCard({ status, hub, onHubCardClick }) {
  const cardContainerClass = status === 'active' ? 'active-company-hub-card-container' : 'in-active-company-hub-card-container';
  const hubNameClass = status === 'active' ? 'active-company-hub-name mb-0' : 'in-active-company-hub-name mb-0';

  return (
    <div className={cardContainerClass} onClick={() => hubDetails(status, hub)}>
      <div className="d-flex flex-column text-center">
        <h2 className={hubNameClass}>{hub.hub_name}</h2>
        <p className="hub-address mb-0">{hub.address}, <span className="country-name">{hub.country}</span></p>
      </div>
    </div>
  )

  function hubDetails(status, hub) {
    if (status === 'active') {
      onHubCardClick(hub)
    }
  }
}
