export const companiesTypes = {
  CREATE_COMPANY_LOADING: 'CREATE_COMPANY_LOADING',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE: 'CREATE_COMPANY_FAILURE',
  RESET_CREATE_COMPANY_DATA: 'RESET_CREATE_COMPANY_DATA',

  UPDATE_COMPANY_LOADING: 'UPDATE_COMPANY_LOADING',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',
  RESET_UPDATE_COMPANY_DATA: 'RESET_UPDATE_COMPANY_DATA',

  FETCH_COMPANIES_LOADING: 'FETCH_COMPANIES_LOADING',
  FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANIES_FAILURE: 'FETCH_COMPANIES_FAILURE',
  
  FETCH_INACTIVE_COMPANIES_LOADING: 'FETCH_INACTIVE_COMPANIES_LOADING',
  FETCH_INACTIVE_COMPANIES_SUCCESS: 'FETCH_INACTIVE_COMPANIES_SUCCESS',
  FETCH_INACTIVE_COMPANIES_FAILURE: 'FETCH_INACTIVE_COMPANIES_FAILURE'
}