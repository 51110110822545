export const formatDaysAndHours = (dateString, search) => {
  if (dateString) {
    if (dateString.includes(search)) {
      const dateStringArr = dateString.split(",");
      return `${dateStringArr[0]}, ${
        dateStringArr[1].trim().split(":")[0]
      } hrs`;
    } else {
      const dateStringArr = dateString.trim().split(":");
      return `${dateStringArr[0]} hrs`;
    }
  }
  return "-";
};

export const formatDecimalToDayString = (num) => {
  if (num || num === 0) {
    const numStrArr = num.toString().split(".");
    if (numStrArr[0] === "0") {
      return "0 days";
    }
    return `${numStrArr[0]}.${numStrArr[1]} days`;
  }
  return "-";
};

