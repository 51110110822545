/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { companyAction, hubsAction, scanUsersAction, scanTypesAction } from '../../../actions'
import CustomInput from '../../../components/input/input';
import CustomSelect from '../../../components/select/select';
import CustomMultiSelect from '../../../components/multiSelect/multiSelect';
import * as _ from 'lodash';
import './style.scss';


export default function CompanyAddScanUser() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({
    hub_scanner_name: '',
    hub_scanner_key: '',
    hub_id: [],
    is_active: true,
    is_all_scan_types: true,
    excluded_scan_types: [],
    included_scan_types: []
  })

  const statusOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const {
    companyActiveScanUsers,
    companyInactiveScanUsers,
    companyScanUserCreated,
    createCompanyScanUserErrorMessage,
    companyScanUserUpdated,
    updateCompanyScanUserErrorMessage
  } = useSelector(state => state.scanUsers);

  const { companies } = useSelector(state => state.companies);
  const { activeHubs } = useSelector(state => state.hubs);

  const { scanTypes, janioScanTypes } = useSelector(state => state.scanTypes);

  const company = _.find(companies, x => String(x.id) === params.companyId);
  const user = getCompanyUserData();

  const [addError, setAddError] = useState(createCompanyScanUserErrorMessage);
  const [updateError, setUpdateError] = useState(updateCompanyScanUserErrorMessage);
  const [scanTypesState, setScanTypesState] = useState([]);
  const [janioScanTypesState, setJanioScanTypesState] = useState([]);

  useEffect(() => {
    if (!_.isEqual(addError, createCompanyScanUserErrorMessage)) {
      setAddError(createCompanyScanUserErrorMessage);
    }
  }, [createCompanyScanUserErrorMessage]);

  useEffect(() => {
    if (!_.isEqual(updateError, updateCompanyScanUserErrorMessage)) {
      setUpdateError(updateCompanyScanUserErrorMessage);
    }
  }, [updateCompanyScanUserErrorMessage]);

  useEffect(() => {
    if (companyScanUserCreated) {
      goToUserDetailsPage();
    }
  }, [companyScanUserCreated]);

  useEffect(() => {
    if (companyScanUserUpdated) {
      goToUserDetailsPage();
    }
  }, [companyScanUserUpdated]);

  useEffect(() => {
    if (params.userId && user) {
      setState({
        ...state,
        'is_active': user.is_active,
        'hub_scanner_name': user.hub_scanner_name,
        'hub_id': [user.hub_id],
        'included_scan_types': user.scan_types
      })

      form.setFieldsValue({
        'is_active': user.is_active,
        'hub_scanner_name': user.hub_scanner_name,
        'hub_id': [user.hub_id],
        'included_scan_types': user.scan_types
      })
    }
  }, [params, user, form]);

  useEffect(() => {
    dispatch(scanTypesAction.getActiveScanTypes(params));
    if (params.companyId !== 5) {
      dispatch(scanTypesAction.getJanioScanTypes());
    }
  }, []);

  useEffect(() => {
    if (!_.isEqual(scanTypes, scanTypesState)) {
      let scanTypesToSave = _.orderBy(scanTypes, 'scan_type_name', 'asc');
      scanTypesToSave = scanTypesToSave.map((type) => {
        type.value = type.scan_type_name;
        type.label = type.scan_type_name;
        return type;
      });
      setScanTypesState(scanTypesToSave);
    }
  }, [scanTypes])

  useEffect(() => {
    if (!_.isEqual(janioScanTypes, janioScanTypesState)) {
      let scanTypesToSave = _.orderBy(janioScanTypes, 'scan_type_name', 'asc');
      scanTypesToSave = scanTypesToSave.map((type) => {
        type.value = type.scan_type_name;
        type.label = type.scan_type_name;
        return type;
      });
      setJanioScanTypesState(scanTypesToSave);
    }
  }, [janioScanTypes]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    }
    if (activeHubs && activeHubs.length === 0) {
      dispatch(hubsAction.getActiveHubs(params.companyId))
    }
  }, [companies, activeHubs, params, dispatch]);

  useEffect(() => {
    if (companyActiveScanUsers.length === 0 || companyInactiveScanUsers.length === 0) {
      dispatch(scanUsersAction.getCompanyScanUsers(params));
      dispatch(scanUsersAction.getCompanyInactiveScanUsers(params));
    };
  }, [params, dispatch]);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleScanUsersClick}>Scan Users</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="link-title" onClick={goToUserDetailsPage}>{company && company.name}</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="title">{breadCrumbText()}</span>
        </p>
        <div className="bg-container-body">
          <Form form={form} onFinish={onAddScanUser} initialValues={state}>
            <div className="d-flex heading-area">
              <h2 className="heading">Scan User Information</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="hub_id"
                  rules={[{ type: 'array', required: true, message: 'Please select a hub first' }]}
                  validateStatus={getValidateStatus('hub_id') ? 'error' : 'validating'}
                  help={getError('hub_id')}>
                  <CustomMultiSelect
                    label="Scan User hub(s)"
                    active={state.hub_id}
                    onChange={(value) => onSelectionChange('hub_id', value)}
                    options={getFilteredHubs()}
                    placeholder="Add hub(s)"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="hub_scanner_name"
                  rules={[{ type: 'string', required: true, message: 'Please type a username first' }]}
                  validateStatus={getValidateStatus('hub_scanner_name') ? 'error' : 'validating'}
                  help={getError('hub_scanner_name')}>
                  <CustomInput
                    label="Scan User Name"
                    name="hub_scanner_name"
                    onChange={handleChange}
                    placeholder="Type here"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="hub_scanner_key"
                  rules={getScanUserKeyRules()}
                  validateStatus={getValidateStatus('hub_scanner_key') ? 'error' : 'validating'}
                  help={getError('hub_scanner_key')}>
                  <CustomInput
                    label="Scan User Key"
                    name="hub_scanner_key"
                    onChange={handleChange}
                    placeholder="Type here"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="is_active"
                  className="mb-0">
                  <CustomSelect
                    label="Is Active"
                    active={state.is_active}
                    onChange={(value) => onSelectionChange('is_active', value)}
                    options={statusOptions}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
            </div>
            <div className="d-flex heading-area">
              <h2 className="heading">Scan Type Permissions for Scan User</h2>
            </div>
            <div className="row">

              {/* <div className="col-md-4 col-sm-6 col-12">
                  <Form.Item
                    name="is_all_scan_types"
                    className="mb-md-0">
                    <CustomSelect
                      label="Has All Scan Types"
                      active={state.is_all_scan_types}
                      onChange={(value) => onSelectionChange('is_all_scan_types', value)}
                      options={statusOptions}
                    />
                  </Form.Item>
                </div> */}

              <div className="col-md-8 col-sm-12">
                <Form.Item
                  name="included_scan_types"
                  className="mb-0">
                  <CustomMultiSelect
                    label="Inclusions (Scan types included for this user)"
                    active={state.included_scan_types}
                    onChange={(value) => onSelectionChange('included_scan_types', value)}
                    options={[...scanTypesState, ...janioScanTypesState]}
                    placeholder="Add scan types to be included, if any"
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="blue-divider" />
              </div>
            </div>
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button className="secondary-button" onClick={goToUserDetailsPage}>Cancel</button>
              <button type="submit" className="primary-button primary-button-margin-bottom-sm">{getButtonText()}</button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );

  function getCompanyUserData() {
    let userData = _.find(companyActiveScanUsers, x => String(x.hub_scanner_id) === params.userId);
    if (!userData) {
      userData = _.find(companyInactiveScanUsers, x => String(x.hub_scanner_id) === params.userId);
    }
    return userData;
  }

  function getScanUserKeyRules() {
    if (params.userId) {
      return null;
    }
    return [{ type: 'string', required: true, message: 'Please type a user key first' }]
  }

  function getValidateStatus(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getError(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getAddError(name) {
    if (addError && addError[name] && addError[name].length > 0) {
      return addError[name][0];
    }
  }

  function getUpdateError(name) {
    if (updateError && updateError[name] && updateError[name].length > 0) {
      return updateError[name][0];
    }
  }

  function breadCrumbText() {
    if (params.userId) {
      return `Edit ${user && user.hub_scanner_name}`;
    }
    return 'Add Scan User';
  }

  function getButtonText() {
    if (params.userId) {
      return 'Edit Scan User';
    }
    return 'Add Scan User';
  }

  function getFilteredHubs() {
    let filteredHubs = [];
    activeHubs.forEach((hub) => {
      hub.label = hub.hub_name;
      hub.value = hub.hub_id;
      filteredHubs.push(hub);
    });
    return filteredHubs
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value
    })
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    })
  }

  function getUserData() {
    let data = {
      hub_scanner_name: state.hub_scanner_name,
      hub_id: state.hub_id.toString(),
      included_scan_types: state.included_scan_types,
      excluded_scan_types: [],
      is_active: state.is_active,
      is_all_scan_types: state.is_all_scan_types
    }
    if (!_.isEmpty(state.hub_scanner_key)) {
      data.hub_scanner_key = state.hub_scanner_key
    }
    return data;
  }

  function onAddScanUser() {
    let data = getUserData();
    if (params.userId) {
      data.hub_scanner_id = user.hub_scanner_id;
      dispatch(scanUsersAction.updateCompanyScanUser(data));
      return;
    }
    dispatch(scanUsersAction.createCompanyScanUser(data));
  }

  function goToUserDetailsPage() {
    let company = _.find(companies, x => String(x.id) === params.companyId)
    history.push(`/scan-users/${company.name.toLowerCase()}/${params.companyId}`);
  }

  function handleScanUsersClick() {
    history.push('/scan-users');
  }
}
