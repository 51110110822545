import React from 'react';
import { Route } from 'react-router-dom';
import Nav from '../../components/nav/nav';
import FilterHubs from './filterHub/filterHub';
import HubsList from './hubsList/hubsList';
// import HubDetails from './hubDetails/hubDetails';
import AddHub from './addHub/addHub';
import HubPackageOrDetailsContainer from './hubDetails/hubPackageOrDetailsContainer';


export default function Hubs() {
  return (
    <>
      <Nav />
      <Route exact path="/hubs" component={FilterHubs} />
      <Route exact path="/hubs/hubsList/:companyId" component={HubsList} />
      <Route exact path="/hubs/hubDetails/:hubName/:hubId/:companyId" component={HubPackageOrDetailsContainer} />
      <Route exact path="/hubs/company/hub/add/:companyId" component={AddHub} />
      <Route exact path="/hubs/company/hub/update/:hubName/:hubId/:companyId" component={AddHub} />
    </>
  );
}
