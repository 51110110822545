/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import './style.scss';

export default function FilterBulkExceptions() {
  const history = useHistory();
  return (
    <>
      <div className="bg-container">
        <p className="title bread-crumb-container">Bulk Exceptions</p>
        <div className="bg-container-body">
          <button className="primary-button primary-button-margin-md" onClick={goToBulkException}>
            Add Exception
          </button>
        </div>
      </div>
    </>
  );

  function goToBulkException() {
    history.push('/tools/addBulkException');
  }
}