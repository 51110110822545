import React, { useEffect } from 'react';
import { message, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { companyAction} from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';
import CompanyCard from '../../../components/companyCard/companyCard';
import './style.scss';


export default function CompaniesList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    companies,
    inactiveCompanies,
    companyCreated,
    addCompanySuccessMessage,
    companyUpdated,
    updateCompanySuccessMessage
  } = useSelector(state => state.companies);

  useEffect(() => {
    dispatch(companyAction.getActiveCompanies());
    dispatch(companyAction.getInactiveCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companyCreated) {
      message.success(addCompanySuccessMessage);
      dispatch(companyAction.resetCreateCompanyData());
    }
  }, [companyCreated, addCompanySuccessMessage, dispatch]);

  useEffect(() => {
    if (companyUpdated) {
      message.success(updateCompanySuccessMessage);
      dispatch(companyAction.resetUpdateCompanyData());
    }
  }, [companyUpdated,updateCompanySuccessMessage, dispatch]);

  return (
    <>
      <div className="bg-container">
        <p className="title bread-crumb-container">Companies</p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column heading-area">
            <h2 className="heading">Active Companies</h2>
            <button className="primary-button primary-button-margin-md" onClick={handleAddCompanyClick}>Add New Company</button>
          </div>
          <div className="row">
            {companies && companies.length > 0 && companies.map((company, index) => {
              return <div key={index} className="col-md-6 col-sm-12 company-card">
                <CompanyCard status='active' company={company} />
              </div>
            })}
          </div>

          {inactiveCompanies && inactiveCompanies.length > 0 &&
            <React.Fragment>
              <Divider className="gray-divider" />
                <div className="d-flex justify-content-between heading-area">
                  <h2 className="heading">Inactive Companies</h2>
                </div>
                <div className="row">
                  {inactiveCompanies.map((company, index) => {
                    return <div key={index} className="col-md-6 col-sm-12 company-card">
                      <CompanyCard status='inactive' company={company} />
                    </div>
                  })}
                </div>
            </React.Fragment>
          }
        </div>
      </div>
    </>
  )

  function handleAddCompanyClick() {
    dispatch(companyAction.resetCreateCompanyData());
    dispatch(companyAction.resetUpdateCompanyData());
    history.push("/companies/company/add/new");
  }
}
