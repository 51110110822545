import { hubsOrderTypes } from '../types';

const initialState = {
  loading:false,
  orderErrorMessage:null,
  metricsErrorMessage:null,
  hubsOrder:{},
  metrics:{}
}

const hubsOrderReducer = (state=initialState, action) => {
  switch(action.type) {
    case hubsOrderTypes.FETCH_HUBS_ORDERS_LOADING:
      return {...state, loading: true}
    case hubsOrderTypes.FETCH_HUBS_ORDERS_SUCCESS:
      return {...state, hubsOrder:action.payload, loading:false}
    case hubsOrderTypes.FETCH_HUBS_ORDERS_FAILURE:
      return {...state, orderErrorMessage:action.payload, loading:false}
    case hubsOrderTypes.FETCH_HUBS_ORDER_METRICS_LOADING:
      return {...state, loading:true}
    case hubsOrderTypes.FETCH_HUBS_ORDER_METRICS_SUCCESS:
      return {...state, metrics:action.payload, loading:false}
    case hubsOrderTypes.FETCH_HUBS_ORDER_METRICS_FAILURE:
      return {...state, metricsErrorMessage:action.payload,loading:false}
    case hubsOrderTypes.CLEAR_HUBS_ORDERS:
      return {...state, hubsOrder:{}, metrics:{}}
    default:
      return state;
  }
}

export { hubsOrderReducer }