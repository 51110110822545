import { scanUsersTypes } from '../types';
import { scanUsersService } from '../services';

const createCompanyScanUser = (data) => {
  const loading = () => ({ type: scanUsersTypes.CREATE_COMPANY_SCAN_USER_LOADING })
  const success = (payload) => ({ type: scanUsersTypes.CREATE_COMPANY_SCAN_USER_SUCCESS, payload })
  const failure = (payload) => ({ type: scanUsersTypes.CREATE_COMPANY_SCAN_USER_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await scanUsersService.createCompanyScanUser(data);
      let successMessage = 'Scan user has been added';
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetCreatedCompanyScanUserData = () => {
  return function (dispatch) {
    dispatch({ type: scanUsersTypes.RESET_CREATED_COMPANY_SCAN_USER_DATA });
  }
}

const updateCompanyScanUser = (data) => {
  const loading = () => ({ type: scanUsersTypes.UPDATE_COMPANY_SCAN_USER_LOADING })
  const success = (payload) => ({ type: scanUsersTypes.UPDATE_COMPANY_SCAN_USER_SUCCESS, payload })
  const failure = (payload) => ({ type: scanUsersTypes.UPDATE_COMPANY_SCAN_USER_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await scanUsersService.updateCompanyScanUser(data);
      let successMessage = `Scan user ${data.hub_scanner_name} has been edited`;
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetUpdatedCompanyScanUserData = () => {
  return function (dispatch) {
    dispatch({ type: scanUsersTypes.RESET_UPDATED_COMPANY_SCAN_USER_DATA });
  }
}

const getCompanyScanUsers = (data) => {
  const loading = () => ({ type: scanUsersTypes.FETCH_COMPANY_SCAN_USERS_LOADING })
  const success = (payload) => ({ type: scanUsersTypes.FETCH_COMPANY_SCAN_USERS_SUCCESS, payload })
  const failure = (payload) => ({ type: scanUsersTypes.FETCH_COMPANY_SCAN_USERS_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      const result = await scanUsersService.getCompanyScanUsers(data);
      dispatch(success(result))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const getCompanyInactiveScanUsers = (data) => {
  const loading = () => ({ type: scanUsersTypes.FETCH_INACTIVE_COMPANY_SCAN_USERS_LOADING })
  const success = (payload) => ({ type: scanUsersTypes.FETCH_INACTIVE_COMPANY_SCAN_USERS_SUCCESS, payload })
  const failure = (payload) => ({ type: scanUsersTypes.FETCH_INACTIVE_COMPANY_SCAN_USERS_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      const result = await scanUsersService.getCompanyInactiveScanUsers(data);
      dispatch(success(result))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const createHubScanUser = (data) => {
  const loading = () => ({ type: scanUsersTypes.CREATE_HUB_SCAN_USER_LOADING })
  const success = (payload) => ({ type: scanUsersTypes.CREATE_HUB_SCAN_USER_SUCCESS, payload })
  const failure = (payload) => ({ type: scanUsersTypes.CREATE_HUB_SCAN_USER_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await scanUsersService.createHubScanUser(data);
      let successMessage = 'Scan user has been added';
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetCreatedHubScanUserData = () => {
  return function (dispatch) {
    dispatch({ type: scanUsersTypes.RESET_CREATED_HUB_SCAN_USER_DATA });
  }
}

const updateHubScanUser = (data) => {
  const loading = () => ({ type: scanUsersTypes.UPDATE_HUB_SCAN_USER_LOADING })
  const success = (payload) => ({ type: scanUsersTypes.UPDATE_HUB_SCAN_USER_SUCCESS, payload })
  const failure = (payload) => ({ type: scanUsersTypes.UPDATE_HUB_SCAN_USER_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await scanUsersService.updateHubScanUser(data);
      let successMessage = `Scan user ${data.hub_scanner_name} has been edited`;
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetUpdatedHubScanUserData = () => {
  return function (dispatch) {
    dispatch({ type: scanUsersTypes.RESET_UPDATED_HUB_SCAN_USER_DATA });
  }
}

const getHubScanUsers = (data) => {
  const loading = () => ({ type: scanUsersTypes.FETCH_HUB_SCAN_USERS_LOADING })
  const success = (payload) => ({ type: scanUsersTypes.FETCH_HUB_SCAN_USERS_SUCCESS, payload })
  const failure = (payload) => ({ type: scanUsersTypes.FETCH_HUB_SCAN_USERS_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      const result = await scanUsersService.getHubScanUsers(data);
      dispatch(success(result))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const getHubInactiveScanUsers = (data) => {
  const loading = () => ({ type: scanUsersTypes.FETCH_INACTIVE_HUB_SCAN_USERS_LOADING })
  const success = (payload) => ({ type: scanUsersTypes.FETCH_INACTIVE_HUB_SCAN_USERS_SUCCESS, payload })
  const failure = (payload) => ({ type: scanUsersTypes.FETCH_INACTIVE_HUB_SCAN_USERS_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      const result = await scanUsersService.getHubInactiveScanUsers(data);
      dispatch(success(result))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

export const scanUsersAction = {
  createCompanyScanUser,
  resetCreatedCompanyScanUserData,
  updateCompanyScanUser,
  resetUpdatedCompanyScanUserData,
  getCompanyScanUsers,
  getCompanyInactiveScanUsers,

  createHubScanUser,
  resetCreatedHubScanUserData,
  updateHubScanUser,
  resetUpdatedHubScanUserData,
  getHubScanUsers,
  getHubInactiveScanUsers
}
