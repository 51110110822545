import { hubPackagesTypes } from '../types'
import { hubPackageServices } from '../services';

export const getHubPackagesData = (reqPayload) => {
  const loading = () => ({ type: hubPackagesTypes.GET_HUB_PACKAGES_DATA_LOADING });
  const success = (resPayload) => ({ type: hubPackagesTypes.GET_HUB_PACKAGES_DATA_SUCCESS, payload: resPayload });
  const failure = (resPayload) => ({ type: hubPackagesTypes.GET_HUB_PACKAGES_DATA_ERROR, payload: resPayload });
  const saveOriginal = (resPayload) => ({ type: hubPackagesTypes.SAVE_HUB_PACKAGES_DATA_SUCCESS, payload: resPayload })

  return async function (dispatch) {
    dispatch(loading());
    try {
      const resData = await hubPackageServices.getHubPackges(reqPayload);
      dispatch(success(resData));
      if (reqPayload.saveOriginal) {
        dispatch(saveOriginal(resData));
      }
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}

export const getHubCargoData = (reqPayload) => {
  const loading = () => ({ type: hubPackagesTypes.GET_HUB_CARGO_DATA_LOADING });
  const success = (resPayload) => ({ type: hubPackagesTypes.GET_HUB_CARGO_DATA_SUCCESS, payload: resPayload });
  const failure = (resPayload) => ({ type: hubPackagesTypes.GET_HUB_CARGO_DATA_ERROR, payload: resPayload });

  return async function (dispatch) {
    dispatch(loading());
    try {
      const resData = await hubPackageServices.getHubCargo(reqPayload);
      dispatch(success(resData.cargo_recon_report));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}