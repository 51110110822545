/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { companyAction } from '../../../actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CustomSelect from '../../../components/select/select';
import * as _ from 'lodash';
import './style.scss';


export default function FilterScanTypes() {
  const dispatch = useDispatch();
  const { companies } = useSelector(state => state.companies);
  const history = useHistory();
  const location = useLocation();

  const [form] = Form.useForm();

  const [state, setState] = useState({
    companyId: undefined,
  });

  useEffect(() => {
    if (location.state) {
      setState(location.state);
      form.setFieldsValue({
        companyId : location.state.companyId,
      });
    }
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    }
  }, [companies, location, form, dispatch]);

  return (
    <>
      <div className="bg-container">
        <p className="title bread-crumb-container">Scan Types</p>
        <div className="bg-container-body">
          <div className="d-flex flex-column heading-area">
            <h2 className="heading heading-area">Filter Scan Types</h2>
            <span className="sub-heading">Filter by Company to view Scan Types.</span>
          </div>
          <Form form={form} onFinish={onFilterType} initialValues={state}>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="companyId"
                  className="mb-md-0"
                  rules={[{ type: 'string', required: true, message: 'Please select a company first' }]}>
                  <CustomSelect
                    label="Company"
                    active={state.companyId}
                    onChange={(value) => onSelectionChange('companyId', value)}
                    options={getCompanies()}
                    placeholder="Filter by company"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <button type="submit" className="primary-button primary-filter-button-margin-sm mb-md-0">Filter Scan Types</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  )

  function onFilterType() {
    let company = _.find(companies, x => String(x.id) === state.companyId);
    history.push(`/scan-types/${company.name.toLowerCase()}/${company.id}`);
  }

  function getCompanies() {
    let companiesList = [];
    companies && companies.length > 0 && companies.forEach((company) => {
      company.label = company.name;
      company.value = String(company.id);
      companiesList.push(company);
    });
    return companiesList;
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    });
  }
}
