let ROOT_URL = 'https://api.janio.asia';
let SCANNER_URL = 'https://api.janio.asia/api';


if (window.location.host.includes('staging') || window.location.host.includes('localhost')) {
    ROOT_URL = 'https://api.staging.janio.asia';
}

if (window.location.host.includes('staging') || window.location.host.includes('localhost')) {
  SCANNER_URL = 'https://scanner.staging.janio.asia/api';
}

export { ROOT_URL, SCANNER_URL };
