import { loginTypes } from '../types';
import { loginService } from '../services';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const login = (data) => {
  const loading = () => ({type:loginTypes.LOGIN_LOADING})
  const success = (payload) => ({type:loginTypes.LOGIN_SUCCESS,payload})
  const failure = (payload) => ({type:loginTypes.LOGIN_FAILURE,payload})

  return async function(dispatch) {
    loading()
    try {
      const result = await loginService.login(data);
      if (data.remember_me) {
        cookies.set('token', result.token, { path: '/' });
      }
      localStorage.setItem('token',result.token);
      localStorage.setItem('user',result.full_name);
      localStorage.setItem('user_role', result.user_role)
      dispatch(success(result.full_name));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const clearResponse = () => {
  return async function(dispatch) {
    dispatch({type:loginTypes.CLEAR_RESPONSE})
  }
}

export const loginAction = {
  login,
  clearResponse
}



