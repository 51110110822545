/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import Nav from '../../components/nav/nav';
import background from '../../assets/images/login_background.png';
import './style.scss';

export default function AccountInformation() {
  const hub = null;

  return (
    <>
      <Nav />
      <div className="bg-container">
        {/* <p className="title bread-crumb-container">Account Information</p> */}
        <div >

          {/* <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column margin-bottom-30">
            <h2 className="heading">Account Information</h2>
            <button className="secondary-button secondary-button-margin-md">Edit Account</button>
          </div> */}

          <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '50vh' }}>
            <img className="login-background" alt="background" src={background} />
            <h1 className="coming-soon-heading">Coming Soon in 2021</h1>
            <p className="coming-soon-text">This page is under development and will be live soon.</p>
          </div>

          {/* <div className="row">
            <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-md-0">
              <h4 className="label-text">Name</h4>
              <span className="text-span-black">Cliff</span>
            </div>
            <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
              <h4 className="label-text">Company</h4>
              <span className="text-span-black">Janio Asia</span>
            </div>
            <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
              <h4 className="label-text">Email/Username</h4>
              <span className="text-span-black">cliff.lim@janio.asia</span>
            </div>
            <div className="col-md-3 col-sm-4 col-12">
              <h4 className="label-text">Password</h4>
              <span className="text-span-black">Password</span>
            </div>
          </div> */}

        </div>
      </div>
    </>
  );
}