import { companyAdminTypes } from '../types';

const initialState = {
  fetchCompanyActiveAdminsLoading: false,
  fetchCompanyActiveAdminsErrorMessage: null,
  companyActiveAdmins: [],

  fetchCompanyInactiveAdminsLoading: false,
  fetchCompanyInactiveAdminsErrorMessage: null,
  companyInactiveAdmins: [],

  createCompanyAdminLoading: false,
  createCompanyAdminErrorMessage: null,
  companyAdminCreated: false,
  createCompanyAdminSuccessMessage: '',

  updateCompanyAdminLoading: false,
  updateCompanyAdminErrorMessage: null,
  companyAdminUpdated: false,
  updateCompanyAdminSuccessMessage: '',
}

const companyAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case companyAdminTypes.FETCH_COMPANY_ACTIVE_ADMINS_LOADING:
      return { ...state, fetchCompanyActiveAdminsLoading: true }
    case companyAdminTypes.FETCH_COMPANY_ACTIVE_ADMINS_SUCCESS:
      return { ...state, companyActiveAdmins: action.payload, fetchCompanyActiveAdminsLoading: false }
    case companyAdminTypes.FETCH_COMPANY_ACTIVE_ADMINS_FAILURE:
      return { ...state, fetchCompanyActiveAdminsErrorMessage: action.payload, fetchCompanyActiveAdminsLoading: false }
    
    case companyAdminTypes.FETCH_COMPANY_INACTIVE_ADMINS_LOADING:
      return { ...state, fetchCompanyInactiveAdminsLoading: true }
    case companyAdminTypes.FETCH_COMPANY_INACTIVE_ADMINS_SUCCESS:
      return { ...state, companyInactiveAdmins: action.payload, fetchCompanyInactiveAdminsLoading: false }
    case companyAdminTypes.FETCH_COMPANY_INACTIVE_ADMINS_FAILURE:
      return { ...state, fetchCompanyInactiveAdminsErrorMessage: action.payload, fetchCompanyInactiveAdminsLoading: false }

    case companyAdminTypes.CREATE_COMPANY_ADMIN_LOADING:
      return { ...state, createCompanyAdminLoading: true }
    case companyAdminTypes.CREATE_COMPANY_ADMIN_SUCCESS:
      return { ...state, companyAdminCreated: true, createCompanyAdminSuccessMessage: action.payload, createCompanyAdminLoading: false };
    case companyAdminTypes.CREATE_COMPANY_ADMIN_FAILURE:
      return { ...state, createCompanyAdminErrorMessage: action.payload, createCompanyAdminLoading: false }
    case companyAdminTypes.RESET_CREATED_COMPANY_ADMIN_DATA:
      return { ...state, createCompanyAdminLoading: false, createCompanyAdminErrorMessage: null, companyAdminCreated: false, createCompanyAdminSuccessMessage: '' };
    
    case companyAdminTypes.UPDATE_COMPANY_ADMIN_LOADING:
      return { ...state, updateCompanyAdminLoading: true };
    case companyAdminTypes.UPDATE_COMPANY_ADMIN_SUCCESS:
      return { ...state, companyAdminUpdated: true, updateCompanyAdminSuccessMessage: action.payload, updateCompanyAdminLoading: false };
    case companyAdminTypes.UPDATE_COMPANY_ADMIN_FAILURE:
      return { ...state, updateCompanyAdminErrorMessage: action.payload, updateCompanyAdminLoading: false };
    case companyAdminTypes.RESET_UPDATED_COMPANY_ADMIN_DATA:
      return { ...state, updateCompanyAdminLoading: false, updateCompanyAdminErrorMessage: null, companyAdminUpdated: false, updateCompanyAdminSuccessMessage: '' };
    default:
      return state;
  }
}

export { companyAdminReducer }