/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { message, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { companyAction, hubsAction, hubManagersAction } from '../../../actions';
import * as _ from 'lodash';
import './style.scss';


export default function HubManagersList() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const [state] = useState(location.state)
  const { companies } = useSelector(state => state.companies);
  const { activeHubs } = useSelector(state => state.hubs);

  const {
    hubActiveManagers,
    hubInactiveManagers,
    hubManagerCreated,
    createhubManagerSuccessMessage,
    hubManagerUpdated,
    updatehubManagerSuccessMessage
  } = useSelector(state => state.hubManagers);

  useEffect(() => {
    dispatch(hubManagersAction.getHubActiveManagers(params));
    dispatch(hubManagersAction.getHubInactiveManagers(params));
  }, [params, dispatch]);

  useEffect(() => {
    if (hubManagerCreated) {
      message.success(createhubManagerSuccessMessage);
      dispatch(hubManagersAction.resetCreatedHubManagerData());
    }
  }, [hubManagerCreated, createhubManagerSuccessMessage, dispatch]);

  useEffect(() => {
    if (hubManagerUpdated) {
      message.success(updatehubManagerSuccessMessage);
      dispatch(hubManagersAction.resetUpdatedHubManagerData());
    }
  }, [hubManagerUpdated, updatehubManagerSuccessMessage, dispatch]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
    if (activeHubs && activeHubs.length === 0) {
      dispatch(hubsAction.getActiveHubs(params.companyId));
    };
  }, [companies, activeHubs, params, dispatch]);

  const company = _.find(companies, x => String(x.id) === params.companyId);
  const hub = _.find(activeHubs, x => String(x.hub_id) === params.hubId);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleHubManagerClick}>Hub Managers</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="link-title" onClick={handleCompanyNameClick}>{company && company.name}</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="title">{hub && hub.hub_name}</span>
        </p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column">
            <h2
              className="heading-link"
              onClick={handleFilteredByHeadingClick}>
              Filtered by: {company && company.name} | {hub && hub.hub_name}
            </h2>
            <button className="secondary-button secondary-button-margin-md" onClick={handleAddHubManagerClick}>Add Hub Manager</button>
          </div>
          <Divider className="gray-divider" />
          <div className="d-flex flex-column">
            <h2 className="heading heading-area">Active Hub Managers</h2>
            <span className="sub-heading heading-area">Click on a hub manager to edit them.</span>
          </div>
          <div className="row">
            {renderActiveManagers()}
          </div>

          <React.Fragment>
            <Divider className="gray-divider mt-0" />
            <div className="d-flex flex-column">
              <h2 className="heading heading-area">Inactive Hub Managers</h2>
            </div>
            <div className="row">
              {renderInactiveManagers()}
            </div>
          </React.Fragment>
        </div>
      </div>
    </>
  )

  function handleCompanyNameClick() {
    history.push(`/hub-managers/${company.name.toLowerCase()}/${params.companyId}`);
  }

  function handleFilteredByHeadingClick() {
    history.push({
      pathname: `/hub-managers`,
      state: {
        companyId: params.companyId,
        hubId: params.hubId
      }
    })
  }

  function renderActiveManagers() {
    return hubActiveManagers.map((manager, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 hub-managers-link-container"
        onClick={() => handleManagerDetailsClick(manager)}>
        <span className="text-link">{manager.display_name}</span>
        {manager.is_point_of_contact && <span className="text-span-black">&nbsp;(Point of Contact)</span>}
      </div>
    })
  }

  function renderInactiveManagers() {
    return hubInactiveManagers.map((manager, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 hub-managers-link-container"
        onClick={() => handleManagerDetailsClick(manager)}>
        <span className="text-link">{manager.display_name}</span>
      </div>
    })
  }

  function handleManagerDetailsClick(hubManager) {
    dispatch(hubManagersAction.resetCreatedHubManagerData());
    dispatch(hubManagersAction.resetUpdatedHubManagerData());
    history.push(`/hub-managers/company/hub/manager/update/${hubManager.display_name}/${params.companyId}/${params.hubId}/${hubManager.id}`);
  }

  function handleHubManagerClick() {
    history.push('/hub-managers');
  }

  function handleAddHubManagerClick() {
    dispatch(hubManagersAction.resetCreatedHubManagerData());
    dispatch(hubManagersAction.resetUpdatedHubManagerData());

    let locationState = {
      state: state
    };
    history.push({
      pathname: `/hub-managers/company/hub/manager/add/new/${params.companyId}/${params.hubId}`,
      state: locationState
    })
  }
}
