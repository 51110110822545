import axios from 'axios';
import { ROOT_URL } from './rootUrl';

const createScanType = (scanTypeData) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/scan_types/`,
    data: scanTypeData,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const updateScanType = (scanTypeData) => {
  return axios({
    method: 'PUT',
    url: `${ROOT_URL}/api/hms/admin/scan_types/?id=${scanTypeData.id}`,
    data: scanTypeData,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getActiveScanTypes = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/scan_types/?company_id=${data.companyId}&is_active=${1}`,
    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
  }).then(res => res.data)
}

const getInactiveScanTypes = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/scan_types/?company_id=${data.companyId}&is_active=${0}`,
    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
  }).then(res => res.data)
}

const getJanioScanTypes = () => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/scan_types/?company_id=${5}&is_active=${1}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

export const scanTypesService = {
  createScanType,
  updateScanType,
  getActiveScanTypes,
  getInactiveScanTypes,
  getJanioScanTypes
}