/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { message, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { companyAction, hubManagersAction } from '../../../actions';
import * as _ from 'lodash';
import './style.scss';


export default function CompanyManagersList() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { companies } = useSelector(state => state.companies);

  const {
    companyActiveManagers,
    companyInactiveManagers,
    companyManagerCreated,
    createCompanyManagerSuccessMessage,
    companyManagerUpdated,
    updateCompanyManagerSuccessMessage
  } = useSelector(state => state.hubManagers);


  useEffect(() => {
    dispatch(hubManagersAction.getCompanyActiveManagers(params));
    dispatch(hubManagersAction.getCompanyInactiveManagers(params));
  }, [params, dispatch]);

  useEffect(() => {
    if (companyManagerCreated) {
      message.success(createCompanyManagerSuccessMessage);
      dispatch(hubManagersAction.resetCreatedCompanyManagerData());
    }
  }, [companyManagerCreated, createCompanyManagerSuccessMessage, dispatch]);

  useEffect(() => {
    if (companyManagerUpdated) {
      message.success(updateCompanyManagerSuccessMessage);
      dispatch(hubManagersAction.resetUpdatedCompanyManagerData());
    }
  }, [companyManagerUpdated, updateCompanyManagerSuccessMessage, dispatch]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
  }, [companies, dispatch]);

  const company = _.find(companies, x => String(x.id) === params.companyId);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleHubManagerClick}>Hub Managers</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="title">{company && company.name}</span>
        </p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column">
            <h2
              className="heading-link"
              onClick={handleCompanyNameClick}>
              Filtered by: {company && company.name}
            </h2>
            <button className="secondary-button secondary-button-margin-md" onClick={handleAddHubManagerClick}>Add Hub Manager</button>
          </div>
          <Divider className="gray-divider" />
          <div className="d-flex flex-column">
            <h2 className="heading heading-area">Active Hub Managers</h2>
            <span className="sub-heading heading-area">Click on a hub manager to edit them.</span>
          </div>
          <div className="row">
            {renderActiveManagers()}
          </div>

          <React.Fragment>
            <Divider className="gray-divider mt-0" />
            <div className="d-flex flex-column">
              <h2 className="heading heading-area">Inactive Hub Managers</h2>
            </div>
            <div className="row">
              {renderInactiveManagers()}
            </div>
          </React.Fragment>
        </div>
      </div>
    </>
  )

  function handleCompanyNameClick() {
    history.push({
      pathname: `/hub-managers`,
      state: {
        companyId: params.companyId
      }
    })
  }

  function renderActiveManagers() {
    return companyActiveManagers.map((manager, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 company-manager-link-container"
        onClick={() => handleManagersDetailsClick(manager)}>
        <span className="text-link">{manager.display_name}</span>
        {manager.is_point_of_contact && <span className="text-span-black">&nbsp;(Point of Contact)</span>}
      </div>
    })
  }

  function renderInactiveManagers() {
    return companyInactiveManagers.map((manager, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 company-manager-link-container"
        onClick={() => handleManagersDetailsClick(manager)}>
        <span className="text-link">{manager.display_name}</span>
      </div>
    })
  }

  function handleManagersDetailsClick(hubManager) {
    dispatch(hubManagersAction.resetCreatedCompanyManagerData());
    dispatch(hubManagersAction.resetUpdatedCompanyManagerData());
    history.push(`/hub-managers/company/manager/update/${hubManager.display_name}/${params.companyId}/${hubManager.id}`);
  }

  function handleHubManagerClick() {
    history.push('/hub-managers');
  }

  function handleAddHubManagerClick() {
    dispatch(hubManagersAction.resetCreatedCompanyManagerData());
    dispatch(hubManagersAction.resetUpdatedCompanyManagerData());
    history.push(`/hub-managers/company/manager/add/new/${params.companyId}`);
  }
}
