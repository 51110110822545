import axios from 'axios';
import { ROOT_URL } from './rootUrl';

const createHub = (data) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/hubs/`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const updateHub = (data) => {
  return axios({
    method: 'PUT',
    url: `${ROOT_URL}/api/hms/admin/hubs/?hub_id=${data.hub_id}`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getActiveHubs = (companyId) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hubs/?company_id=${companyId}&is_active=${1}`,
    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
  }).then(res => res.data)
}

const getInactiveHubs = (companyId) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hubs/?company_id=${companyId}&is_active=${0}`,
    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
  }).then(res => res.data)
}

export const hubsService = {
  createHub,
  updateHub,
  getActiveHubs,
  getInactiveHubs
}
