import axios from 'axios';
import { ROOT_URL } from './rootUrl';

const createCompanyAdmin = (adminData) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/company_admins/?company_id=${adminData.company_id}`,
    data: adminData,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const updateCompanyAdmin = (adminData) => {
  return axios({
    method: 'PUT',
    url: `${ROOT_URL}/api/hms/admin/company_admins/?company_id=${adminData.company_id}&admin_email=${adminData.admin_email}`,
    data: adminData,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getCompanyActiveAdmins = (companyId) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/company_admins/?company_id=${companyId}&is_active=${1}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data.data)
}

const getCompanyInactiveAdmins = (companyId) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/company_admins/?company_id=${companyId}&is_active=${0}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data.data)
}

export const companyAdminService = {
  getCompanyActiveAdmins,
  getCompanyInactiveAdmins,
  createCompanyAdmin,
  updateCompanyAdmin
}