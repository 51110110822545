/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { message, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { companyAction, hubsAction } from '../../../actions';
import HubCard from '../../../components/hubCard/hubCard';
import * as _ from 'lodash';
import './style.scss'

export default function HubsList() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { companies } = useSelector(state => state.companies);
  const {
    activeHubs,
    inactiveHubs,
    hubCreated,
    createHubSuccessMessage,
    hubUpdated,
    updateHubSuccessMessage
  } = useSelector(state => state.hubs);

  useEffect(() => {
    dispatch(hubsAction.getActiveHubs(params.companyId));
    dispatch(hubsAction.getInactiveHubs(params.companyId));
  }, [params, dispatch]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
  }, [dispatch, companies]);

  useEffect(() => {
    if (hubCreated) {
      message.success(createHubSuccessMessage);
      dispatch(hubsAction.resetCreatedHubData());
    }
  }, [hubCreated, createHubSuccessMessage, dispatch]);

  useEffect(() => {
    if (hubUpdated) {
      message.success(updateHubSuccessMessage);
      dispatch(hubsAction.resetUpdatedHubData());
    }
  }, [hubUpdated, updateHubSuccessMessage, dispatch]);

  const company = _.find(companies, x => String(x.id) === params.companyId);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleHubsClick}>Hubs</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="title">{company && company.name}</span>
        </p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column">
            <h2 className="heading-link" onClick={handleCompanyNameClick}>
              Filtered by: {company && company.name}
            </h2>
            <button className="secondary-button secondary-button-margin-md" onClick={handleAddHubClick}>Add Hub</button>
          </div>
          <Divider className="gray-divider" />
          <div className="d-flex flex-column">
            <h2 className="heading heading-area">Active Hubs</h2>
          </div>
          <div className="row">
            {renderActiveHubs()}
          </div>

          {inactiveHubs && inactiveHubs.length > 0 &&
            <React.Fragment>
              <Divider className="gray-divider mt-0" />
              <div className="d-flex flex-column">
                <h2 className="heading heading-area">Inactive Hubs</h2>
              </div>
              <div className="row">
                {renderInactiveHubs()}
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </>
  );

  function handleCompanyNameClick() {
    history.push({
      pathname: `/hubs`,
      state: {
        companyId: params.companyId
      }
    });
  }

  function renderActiveHubs() {
    return activeHubs.map((hub, index) => {
      return <div key={index} className="col-md-6 col-sm-12 hub-card">
        <HubCard key={index} status='active' hub={hub} onHubCardClick={onHubCardClick} />
      </div>
    })
  }

  function renderInactiveHubs() {
    return inactiveHubs.map((hub, index) => {
      return <div key={index} className="col-md-6 col-sm-12 hub-card">
        <HubCard key={index} status='inactive' hub={hub} onHubCardClick={onHubCardClick} />
      </div>
    });
  }

  function onHubCardClick(hub) {
    dispatch(hubsAction.resetCreatedHubData());
    dispatch(hubsAction.resetUpdatedHubData());
    let hubName = hub.hub_name.replace(/\s+/g, '-').toLowerCase();
    history.push(`/hubs/hubDetails/${hubName}/${hub.hub_id}/${params.companyId}`);
  }

  function handleHubsClick() {
    history.push('/hubs');
  }

  function handleAddHubClick() {
    dispatch(hubsAction.resetCreatedHubData());
    dispatch(hubsAction.resetUpdatedHubData());
    history.push(`/hubs/company/hub/add/${params.companyId}`);
  }
}
