export const generateStandardReport = (data) => {
  let processedData = data.filter((d) => d.times_used);
  processedData = data.map((d) => {
    return {
      TN: d.tracking_no,
      Name: d.resource_name,
      Tag: d.tag_name,
      Count: d.times_used,
    };
  });
  return processedData;
};

export const generateAggReport = (data, resources) => {
  const trackingNos = Object.keys(data);
  const processedData = [];
  trackingNos.forEach((trackingNo) => {
    const resourcesUsed = {
      TN: trackingNo,
    };
    resources.forEach((resource) => {
      resourcesUsed[resource.resource_name] = 0;
    });
    data[trackingNo].forEach((resource) => {
      resourcesUsed[resource.resource_name] = resource.times_used;
    });
    processedData.push(resourcesUsed);
  });
  return processedData;
};
