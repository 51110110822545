import React from 'react';
import { Route } from 'react-router-dom';
import Nav from '../../components/nav/nav';
import FilterCompanyAdmins from './filterCompanyAdmins/filterCompanyAdmins';
import CompanyAdminDetails from './companyAdminDetails/companyAdminDetails';
import AddCompanyAdmin from './addCompanyAdmin/addCompanyAdmin';


export default function CompanyAdmins() {
  return (
    <>
      <Nav />
      <Route exact path="/company-admins" component={FilterCompanyAdmins} />
      <Route exact path="/company-admins/:adminName" component={CompanyAdminDetails} />
      <Route exact path="/company-admins/admin/add" component={AddCompanyAdmin} />
      <Route exact path="/company-admins/admin/update/:adminName" component={AddCompanyAdmin} />
    </>
  );
}
