import { Divider, Input, Select, Modal } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { toolsAction } from "../../../actions/tools.actions";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomInput from "../../../components/input/input";
import { cloneDeep } from "lodash";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

function EditResources(props) {
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [tags, setTags] = useState([]);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [tagName, setTagName] = useState("");

  useEffect(() => {
    props.dispatch(
      toolsAction.getResourceTags(
        `?company_id=${props.location.state.companyId}`
      )
    );
    return () => {
      props.dispatch(toolsAction.resourceCleanUp());
    };
  }, []);

  useEffect(() => {
    if (
      props.resourceCreated ||
      props.resourceEdited ||
      props.resourceDeleted
    ) {
      setLoading(false);
      props.history.goBack();
    }
  }, [props.resourceCreated, props.resourceEdited, props.resourceDeleted]);

  useEffect(() => {
    if (props.resources && props.resources.length) {
      setResources(props.resources);
    }
    if (props.tags && props.tags.length) {
      setTags(props.tags);
    }
  }, [props.resources, props.tags]);

  const onNameChangeHandler = (e, idx) => {
    const copiedResources = cloneDeep(resources);
    if (!copiedResources[idx].created) {
      copiedResources[idx].edited = true;
    }
    copiedResources[idx].resource_name = e.target.value;
    setResources(copiedResources);
  };

  const onTagChangeHandler = (value, idx) => {
    console.log(value);
    const copiedResources = cloneDeep(resources);
    if (!copiedResources[idx].created) {
      copiedResources[idx].edited = true;
    }
    copiedResources[idx].tag_id = value;
    setResources(copiedResources);
  };

  const onDeleteResourceHandler = (idx) => {
    const copiedResources = cloneDeep(resources);
    copiedResources[idx].deleted = true;
    setResources(copiedResources);
  };

  const onAddNewResourceHandler = () => {
    const copiedResources = cloneDeep(resources);
    copiedResources.push({
      id: copiedResources.length,
      resource_name: "",
      tag_id: "",
      default_increment: 1,
      created: true,
    });
    setResources(copiedResources);
  };

  const onEditResourceHandler = async () => {
    setLoading(true);
    const deleteResources = resources.filter((resource) => {
      return resource.deleted;
    });
    const editResources = resources.filter((resource) => {
      return resource.edited;
    });
    const createdResources = resources.filter((resource) => {
      return resource.created && resource.resource_name;
    });
    if (deleteResources.length > 0) {
      props.dispatch(
        toolsAction.deleteResource({
          resources: deleteResources,
          companyId: props.location.state.companyId,
        })
      );
    }

    if (editResources.length > 0) {
      props.dispatch(
        toolsAction.updateResource({
          resources: editResources,
          companyId: props.location.state.companyId,
        })
      );
    }

    if (createdResources.length > 0) {
      props.dispatch(
        toolsAction.createResource({
          resources: createdResources,
          companyId: props.location.state.companyId,
        })
      );
    }
  };

  const onConfirmTagNameClick = () => {
    hideAddTagModal();
    props.dispatch(
      toolsAction.createResourceTag({
        tag_name: tagName,
        company_id: props.location.state.companyId,
      })
    );
  };

  const displayAddTagModal = () => {
    setShowAddTagModal(true);
  };

  const hideAddTagModal = () => {
    setShowAddTagModal(false);
  };
  const renderAddTagModal = () => {
    return (
      <Modal
        className="generate-csv-modal"
        visible={showAddTagModal}
        footer={null}
        okText="Confirm"
        okButtonProps={{ className: "modal-primary-button" }}
        cancelButtonProps={{ className: "modal-secondary-button" }}
        cancelText="Cancel"
      >
        <div className="text-left">
          <h2 className="generate-csv-heading mb-0">Create Tag</h2>
          <p className="generate-csv-paragraph mb-0">
            Once created, the new tag can be selected from a list in Tag field.
          </p>
        </div>
        <div className="mt-4">
          <CustomInput
            label="Tag name"
            name="tag-name"
            placeholder="Type here"
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
          />
        </div>
        <Divider />
        <div className="d-flex justify-content-between">
          <button className="modal-secondary-button" onClick={hideAddTagModal}>
            Cancel
          </button>
          <button
            className="modal-primary-button"
            onClick={onConfirmTagNameClick}
          >
            Confirm
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div className="bg-container">
        <p className="title bread-crumb-container">Resource Definitions</p>
        <div className="bg-container-body">
          <div className="heading-area">
            <h2 className="heading heading-area">Edit Resource Defintions</h2>
          </div>
          <div className="table-responsive mt-2" style={{ width: "60%" }}>
            <table className="table table-borderless packages-table">
              <thead className="table-header--bottom-border">
                <tr>
                  <th>Name</th>
                  <th>Tag</th>
                </tr>
              </thead>
              <tbody>
                {resources.length > 0 &&
                  resources.map((resource, idx) => {
                    if (!resource.deleted) {
                      return (
                        <tr key={Symbol(resource.id + idx).description}>
                          <td>
                            <CustomInput
                              value={resource.resource_name}
                              onChange={(e) => onNameChangeHandler(e, idx)}
                            />
                          </td>
                          <td className="d-flex align-items-center">
                            <Select
                              value={resource.tag_id}
                              size="default"
                              onChange={(value) =>
                                onTagChangeHandler(value, idx)
                              }
                              style={{ width: "100%" }}
                            >
                              {tags.length > 0 &&
                                tags.map((tag) => (
                                  <Option value={tag.value} key={tag.value}>
                                    {tag.label}
                                  </Option>
                                ))}
                            </Select>
                            <i
                              className="fa fa-trash-alt fnt-16 cursor-pointer ml-4"
                              aria-hidden="true"
                              onClick={() => onDeleteResourceHandler(idx)}
                            />
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })}
              </tbody>
            </table>
          </div>
          <div className="d-flex">
            <button
              className="secondary-button secondary-button-margin-md mr-4"
              onClick={onAddNewResourceHandler}
            >
              <i
                className="fa fa-plus-circle fnt-16 cursor-pointer"
                aria-hidden="true"
              />
              <span className="ml-2">Add resource definitions</span>
            </button>
            <button
              className="secondary-button secondary-button-margin-md"
              onClick={displayAddTagModal}
            >
              <i
                className="fa fa-plus-circle fnt-16 cursor-pointer"
                aria-hidden="true"
              />
              <span className="ml-2">Create Tag</span>
            </button>
          </div>
          <Divider style={{ backgroundColor: "#050593" }} />
          <div className="d-flex justify-content-between mt-4">
            <button
              className="secondary-button secondary-button-margin-md"
              onClick={() => {
                props.history.goBack();
              }}
            >
              Cancel
            </button>
            <button
              className="primary-button secondary-button-margin-md"
              onClick={onEditResourceHandler}
            >
              {loading ? <Spin indicator={antIcon} /> : "Edit Resources"}
            </button>
          </div>
        </div>
      </div>
      {renderAddTagModal()}
    </>
  );
}

const mapStateToProps = ({ tools }) => {
  return {
    resources: tools.resources,
    tags: tools.tags,
    resourceCreated: tools.resourceCreated,
    resourceEdited: tools.resourceEdited,
    resourceDeleted: tools.resourceDeleted,
  };
};

export default connect(mapStateToProps)(EditResources);
