import { toolsTypes } from "../types";
import { toolsService } from "../services";

const addBulkException = (data) => {
  const loading = () => ({ type: toolsTypes.ADD_BULK_EXCEPTION_LOADING });
  const success = () => ({ type: toolsTypes.ADD_BULK_EXCEPTION_SUCCESS });
  const failure = (payload) => ({
    type: toolsTypes.ADD_BULK_EXCEPTION_FAILURE,
    payload,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      await toolsService.addBulkException(data);
      dispatch(success());
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  };
};

const createResource = (data) => {
  return async function (dispatch) {
    try {
      const requests = [];
      data.resources.forEach((d) => {
        requests.push(
          toolsService.createResourceApi({
            resource_name: d.resource_name,
            tag_id: d.tag_id,
            default_increment: d.default_increment,
            company_id: data.companyId,
          })
        );
      });
      Promise.all(requests);
      dispatch({
        type: toolsTypes.CREATE_RESOURCE_SUCCESS,
      });
    } catch (err) {
      console.log(err);
      const errorMessage = err.response.data;
      dispatch({
        type: toolsTypes.CREATE_RESOURCE_ERROR,
        payload: errorMessage,
      });
    }
  };
};

const updateResource = (data) => {
  return async function (dispatch) {
    try {
      const requests = [];
      data.resources.forEach((d) => {
        console.log(d);
        requests.push(
          toolsService.updateResourceApi({
            resource_id: d.id,
            new_resource_name: d.resource_name,
            new_tag_id: d.tag_id,
            new_default_increment: d.default_increment,
            company_id: data.companyId,
          })
        );
      });
      dispatch({
        type: toolsTypes.UPDATE_RESOURCE_SUCCESS,
      });
    } catch (err) {
      console.log(err);
      const errorMessage = err.response.data;
      dispatch({
        type: toolsTypes.UPDATE_RESOURCE_ERROR,
        payload: errorMessage,
      });
    }
  };
};

const deleteResource = (data) => {
  return async function (dispatch) {
    const requests = [];
    try {
      data.resources.forEach((d) => {
        requests.push(
          toolsService.deleteResourceApi({
            resource_id: d.id,
            company_id: data.companyId,
          })
        );
      });
      Promise.all(requests);
      dispatch({
        type: toolsTypes.DELETE_RESOURCE_SUCCESS,
      });
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch({
        type: toolsTypes.DELETE_RESOURCE_ERROR,
        payload: errorMessage,
      });
    }
  };
};

const getResource = (params) => {
  return async function (dispatch) {
    try {
      const res = await toolsService.getResourceApi(params);
      dispatch({
        type: toolsTypes.GET_RESOURCE_SUCCESS,
        payload: res.resource_details,
      });
    } catch (err) {
      console.log(err);
      const errorMessage = err.response.data;
      dispatch({
        type: toolsTypes.GET_RESOURCE_ERROR,
        payload: errorMessage,
      });
    }
  };
};

const getResourceTags = (params) => {
  return async function (dispatch) {
    try {
      const res = await toolsService.getResourceTagsApi(params);
      const data = res.resource_tag_details.map((tag) => ({
        label: tag.tag_name,
        value: tag.id,
      }));
      dispatch({
        type: toolsTypes.GET_RESOURCE_TAG_SUCCESS,
        payload: data,
      });
    } catch (err) {
      console.log("get resource tag error", err);
    }
  };
};

const resourceCleanUp = () => {
  return function (dispatch) {
    dispatch({
      type: toolsTypes.RESOURCE_CLEANUP,
    });
  };
};

const createResourceTag = (data) => {
  return async function (dispatch) {
    try {
      await toolsService.createResourceTagApi(data);
      const res = await toolsService.getResourceTagsApi(
        `?company_id=${data.company_id}`
      );
      const tagData = res.resource_tag_details.map((tag) => ({
        label: tag.tag_name,
        value: tag.tag_name,
      }));
      dispatch({
        type: toolsTypes.GET_RESOURCE_TAG_SUCCESS,
        payload: tagData,
      });
    } catch (err) {
      console.log("Create tag error", err);
    }
  };
};

const getStandardResourceReport = (data) => {
  return async function (dispatch) {
    try {
      const reportData = await toolsService.getStandardResourceReportApi(data);
      dispatch({
        type: toolsTypes.GET_STANDARD_RESOURCE_REPORT_DATA_SUCCESS,
        payload: reportData,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const getAggregatedResourceReport = (data) => {
  return async function (dispatch) {
    try {
      const reportData = await toolsService.getAggregatedResourceReportApi(
        data
      );
      dispatch({
        type: toolsTypes.GET_AGGREGATED_RESOURCE_REPORT_DATA_SUCCESS,
        payload: reportData,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const toolsAction = {
  addBulkException,
  createResource,
  updateResource,
  deleteResource,
  getResource,
  getResourceTags,
  resourceCleanUp,
  createResourceTag,
  getStandardResourceReport,
  getAggregatedResourceReport,
};
