import { scanUsersTypes } from '../types';

const initialState = {
  createCompanyScanUserloading: false,
  createCompanyScanUserErrorMessage: null,
  companyScanUserCreated: false,
  createCompanyScanUserSuccessMessage: '',

  updateCompanyScanUserloading: false,
  updateCompanyScanUserErrorMessage: null,
  companyScanUserUpdated: false,

  updateCompanyScanUserSuccessMessage: '',
  companyScanUsersloading: false,
  companyScanUsersErrorMessage: null,
  companyActiveScanUsers: [],

  companyInactiveScanUsersloading: false,
  companyInactiveScanUsersErrorMessage: null,
  companyInactiveScanUsers: [],

  createHubScanUserloading: false,
  createHubScanUserErrorMessage: null,
  hubScanUserCreated: false,
  createHubScanUserSuccessMessage: '',
  
  updateHubScanUserloading: false,
  updateHubScanUserErrorMessage: null,
  hubScanUserUpdated: false,
  updateHubScanUserSuccessMessage: '',

  hubScanUsersloading: false,
  hubScanUsersErrorMessage: null,
  hubActiveScanUsers: [],

  hubInactiveScanUsersloading: false,
  hubInactiveScanUsersErrorMessage: null,
  hubInactiveScanUsers: []
}

const scanUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case scanUsersTypes.CREATE_COMPANY_SCAN_USER_LOADING:
      return { ...state, createCompanyScanUserloading: true }
    case scanUsersTypes.CREATE_COMPANY_SCAN_USER_SUCCESS:
      return { ...state, companyScanUserCreated: true, createCompanyScanUserloading: false, createCompanyScanUserSuccessMessage: action.payload }
    case scanUsersTypes.CREATE_COMPANY_SCAN_USER_FAILURE:
      return { ...state, createCompanyScanUserErrorMessage: action.payload, createCompanyScanUserloading: false }
    case scanUsersTypes.RESET_CREATED_COMPANY_SCAN_USER_DATA:
      return { ...state, createCompanyScanUserloading: false, createCompanyScanUserErrorMessage: null, companyScanUserCreated: false, createCompanyScanUserSuccessMessage: '' }

    case scanUsersTypes.UPDATE_COMPANY_SCAN_USER_LOADING:
      return { ...state, updateCompanyScanUserloading: true }
    case scanUsersTypes.UPDATE_COMPANY_SCAN_USER_SUCCESS:
      return { ...state, companyScanUserUpdated: true, updateCompanyScanUserloading: false, updateCompanyScanUserSuccessMessage: action.payload }
    case scanUsersTypes.UPDATE_COMPANY_SCAN_USER_FAILURE:
      return { ...state, updateCompanyScanUserErrorMessage: action.payload, updateCompanyScanUserloading: false }
    case scanUsersTypes.RESET_UPDATED_COMPANY_SCAN_USER_DATA:
      return { ...state, updateCompanyScanUserloading: false, updateCompanyScanUserErrorMessage: null, companyScanUserUpdated: false, updateCompanyScanUserSuccessMessage: '' }
    
    case scanUsersTypes.FETCH_COMPANY_SCAN_USERS_LOADING:
      return { ...state, companyScanUsersloading: true }
    case scanUsersTypes.FETCH_COMPANY_SCAN_USERS_SUCCESS:
      return { ...state, companyActiveScanUsers: action.payload, companyScanUsersloading: false }
    case scanUsersTypes.FETCH_COMPANY_SCAN_USERS_FAILURE:
      return { ...state, companyScanUsersErrorMessage: action.payload, companyScanUsersloading: false }
    
    case scanUsersTypes.FETCH_INACTIVE_COMPANY_SCAN_USERS_LOADING:
      return { ...state, companyInactiveScanUsersloading: true }
    case scanUsersTypes.FETCH_INACTIVE_COMPANY_SCAN_USERS_SUCCESS:
      return { ...state, companyInactiveScanUsers: action.payload, companyInactiveScanUsersloading: false }
    case scanUsersTypes.FETCH_INACTIVE_COMPANY_SCAN_USERS_FAILURE:
      return { ...state, companyInactiveScanUsersErrorMessage: action.payload, companyInactiveScanUsersloading: false }

    case scanUsersTypes.CREATE_HUB_SCAN_USER_LOADING:
      return { ...state, createHubScanUserloading: true }
    case scanUsersTypes.CREATE_HUB_SCAN_USER_SUCCESS:
      return { ...state, hubScanUserCreated: true, createHubScanUserloading: false, createHubScanUserSuccessMessage: action.payload }
    case scanUsersTypes.CREATE_HUB_SCAN_USER_FAILURE:
      return { ...state, createHubScanUserErrorMessage: action.payload, createHubScanUserloading: false }
    case scanUsersTypes.RESET_CREATED_HUB_SCAN_USER_DATA:
      return { ...state, createHubScanUserloading: false, createHubScanUserErrorMessage: null, hubScanUserCreated: false, createHubScanUserSuccessMessage: '' }
    
    case scanUsersTypes.UPDATE_HUB_SCAN_USER_LOADING:
      return { ...state, updateHubScanUserloading: true }
    case scanUsersTypes.UPDATE_HUB_SCAN_USER_SUCCESS:
      return { ...state, hubScanUserUpdated: true, updateHubScanUserloading: false, updateHubScanUserSuccessMessage: action.payload }
    case scanUsersTypes.UPDATE_HUB_SCAN_USER_FAILURE:
      return { ...state, updateHubScanUserErrorMessage: action.payload, updateHubScanUserloading: false }
    case scanUsersTypes.RESET_UPDATED_HUB_SCAN_USER_DATA:
      return { ...state, updateHubScanUserloading: false, updateHubScanUserErrorMessage: null, hubScanUserUpdated: false, updateHubScanUserSuccessMessage: '' }
    
    case scanUsersTypes.FETCH_HUB_SCAN_USERS_LOADING:
      return { ...state, hubScanUsersloading: true }
    case scanUsersTypes.FETCH_HUB_SCAN_USERS_SUCCESS:
      return { ...state, hubActiveScanUsers: action.payload, hubScanUsersloading: false }
    case scanUsersTypes.FETCH_HUB_SCAN_USERS_FAILURE:
      return { ...state, hubScanUsersErrorMessage: action.payload, hubScanUsersloading: false }
    
    case scanUsersTypes.FETCH_INACTIVE_HUB_SCAN_USERS_LOADING:
      return { ...state, hubInactiveScanUsersloading: true }
    case scanUsersTypes.FETCH_INACTIVE_HUB_SCAN_USERS_SUCCESS:
      return { ...state, hubInactiveScanUsers: action.payload, hubInactiveScanUsersloading: false }
    case scanUsersTypes.FETCH_INACTIVE_HUB_SCAN_USERS_FAILURE:
      return { ...state, hubInactiveScanUsersErrorMessage: action.payload, hubInactiveScanUsersloading: false }
    default:
      return state;
  }
}

export { scanUsersReducer }