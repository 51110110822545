import { hubManagersTypes } from '../types'

const initialState = {
  createCompanyManagerLoading: false,
  createCompanyManagerErrorMessage: null,
  companyManagerCreated: false,
  createCompanyManagerSuccessMessage: '',

  updateCompanyManagerLoading: false,
  updateCompanyManagerErrorMessage: null,
  companyManagerUpdated: false,
  updateCompanyManagerSuccessMessage: '',

  companyActiveManagersLoading: false,
  companyActiveManagersErrorMessage: null,
  companyActiveManagers: [],

  companyInactiveManagersLoading: false, 
  companyInactiveManagersErrorMessage: null,
  companyInactiveManagers: [],

  createHubManagerLoading: false,
  createHubManagerErrorMessage: null,
  hubManagerCreated: false,
  createhubManagerSuccessMessage: '',

  updateHubManagerLoading: false,
  updateHubManagerErrorMessage: null,
  hubManagerUpdated: false,
  updatehubManagerSuccessMessage: '',

  hubActiveManagersLoading: false,
  hubActiveManagersErrorMessage: null,
  hubActiveManagers: [],

  hubInactiveManagersLoading: false,
  hubInactiveManagersErrorMessage: null,
  hubInactiveManagers: []
}

const hubManagersReducer = (state = initialState, action) => {
  switch (action.type) {
    case hubManagersTypes.CREATE_COMPANY_MANAGER_LOADING:
      return { ...state, createCompanyManagerLoading: true }
    case hubManagersTypes.CREATE_COMPANY_MANAGER_SUCCESS:
      return { ...state, companyManagerCreated: true, createCompanyManagerLoading: false, createCompanyManagerSuccessMessage: action.payload }
    case hubManagersTypes.CREATE_COMPANY_MANAGER_FAILURE:
      return { ...state, createCompanyManagerErrorMessage: action.payload, createCompanyManagerLoading: false }
    case hubManagersTypes.RESET_CREATED_COMPANY_MANAGER_DATA:
      return { ...state, createCompanyManagerLoading: false, createCompanyManagerErrorMessage: null, companyManagerCreated: false, createCompanyManagerSuccessMessage: '' }
    
    case hubManagersTypes.UPDATE_COMPANY_MANAGER_LOADING:
      return { ...state, updateCompanyManagerLoading: true }
    case hubManagersTypes.UPDATE_COMPANY_MANAGER_SUCCESS:
      return { ...state, companyManagerUpdated: true, updateCompanyManagerLoading: false, updateCompanyManagerSuccessMessage: action.payload }
    case hubManagersTypes.UPDATE_COMPANY_MANAGER_FAILURE:
      return { ...state, updateCompanyManagerErrorMessage: action.payload, updateCompanyManagerLoading: false }
    case hubManagersTypes.RESET_UPDATED_COMPANY_MANAGER_DATA:
      return { ...state, updateCompanyManagerLoading: false, updateCompanyManagerErrorMessage: null, companyManagerUpdated: false, updateCompanyManagerSuccessMessage: '' }
    
    case hubManagersTypes.FETCH_COMPANY_ACTIVE_MANAGERS_LOADING:
      return { ...state, companyActiveManagersLoading: true }
    case hubManagersTypes.FETCH_COMPANY_ACTIVE_MANAGERS_SUCCESS:
      return { ...state, companyActiveManagers: action.payload, companyActiveManagersLoading: false }
    case hubManagersTypes.FETCH_COMPANY_ACTIVE_MANAGERS_FAILURE:
      return { ...state, companyActiveManagersErrorMessage: action.payload, companyActiveManagersLoading: false }
    
    case hubManagersTypes.FETCH_COMPANY_INACTIVE_MANAGERS_LOADING:
      return { ...state, companyInactiveManagersLoading: true }
    case hubManagersTypes.FETCH_COMPANY_INACTIVE_MANAGERS_SUCCESS:
      return { ...state, companyInactiveManagers: action.payload, companyInactiveManagersLoading: false }
    case hubManagersTypes.FETCH_COMPANY_INACTIVE_MANAGERS_FAILURE:
      return { ...state, companyInactiveManagersErrorMessage: action.payload, companyInactiveManagersLoading: false }

    case hubManagersTypes.CREATE_HUB_MANAGER_LOADING:
      return { ...state, createHubManagerLoading: true }
    case hubManagersTypes.CREATE_HUB_MANAGER_SUCCESS:
      return { ...state, hubManagerCreated: true, createHubManagerLoading: false, createhubManagerSuccessMessage: action.payload }
    case hubManagersTypes.CREATE_HUB_MANAGER_FAILURE:
      return { ...state, createHubManagerErrorMessage: action.payload, createHubManagerLoading: false }
    case hubManagersTypes.RESET_CREATED_HUB_MANAGER_DATA:
      return { ...state, createHubManagerLoading: false, createHubManagerErrorMessage: null, hubManagerCreated: false, createhubManagerSuccessMessage: '' }
    
    case hubManagersTypes.UPDATE_HUB_MANAGER_LOADING:
      return { ...state, updateHubManagerLoading: true }
    case hubManagersTypes.UPDATE_HUB_MANAGER_SUCCESS:
      return { ...state, hubManagerUpdated: true, updateHubManagerLoading: false, updatehubManagerSuccessMessage: action.payload }
    case hubManagersTypes.UPDATE_HUB_MANAGER_FAILURE:
      return { ...state, updateHubManagerErrorMessage: action.payload, updateHubManagerLoading: false }
    case hubManagersTypes.RESET_UPDATED_HUB_MANAGER_DATA:
      return { ...state, updateHubManagerLoading: false, updateHubManagerErrorMessage: null, hubManagerUpdated: false, updatehubManagerSuccessMessage: '' }

    case hubManagersTypes.FETCH_HUB_ACTIVE_MANAGERS_LOADING:
      return { ...state, hubActiveManagersLoading: true }
    case hubManagersTypes.FETCH_HUB_ACTIVE_MANAGERS_SUCCESS:
      return { ...state, hubActiveManagers: action.payload, hubActiveManagersLoading: false }
    case hubManagersTypes.FETCH_HUB_ACTIVE_MANAGERS_FAILURE:
      return { ...state, hubActiveManagersErrorMessage: action.payload, hubActiveManagersLoading: false }
    
    case hubManagersTypes.FETCH_HUB_INACTIVE_MANAGERS_LOADING:
      return { ...state, hubInactiveManagersLoading: true }
    case hubManagersTypes.FETCH_HUB_INACTIVE_MANAGERS_SUCCESS:
      return { ...state, hubInactiveManagers: action.payload, hubInactiveManagersLoading: false }
    case hubManagersTypes.FETCH_HUB_INACTIVE_MANAGERS_FAILURE:
      return { ...state, hubInactiveManagersErrorMessage: action.payload, hubInactiveManagersLoading: false }
    default:
      return state;
  }
}

export { hubManagersReducer }