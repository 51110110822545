/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { companyAction, hubsAction } from '../../../actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CustomSelect from '../../../components/select/select'
import * as _ from 'lodash';
import './style.scss';


export default function ScanUsersPage() {
  const dispatch = useDispatch();
  const { companies } = useSelector(state => state.companies);
  const { activeHubs } = useSelector(state => state.hubs);
  const history = useHistory();
  const location = useLocation();

  const [form] = Form.useForm();

  const [state, setState] = useState({
    companyId: undefined,
    hubId: undefined
  });

  useEffect(() => {
    if (location.state) {
      setState(location.state);
      form.setFieldsValue({
        companyId: location.state.companyId,
        hubId : location.state.hubId,
      })
    }
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies())
    }
    if (activeHubs && activeHubs.length === 0) {
      dispatch(hubsAction.getActiveHubs(state.companyId));
    };
  }, [activeHubs, companies, form, location, dispatch, state.companyId]);

  return (
    <>
      <div className="bg-container">
        <p className="title bread-crumb-container">Scan Users</p>
        <div className="bg-container-body">
          <div className="d-flex flex-column heading-area">
            <h2 className="heading heading-area">Filter</h2>
            <span className="sub-heading">Filter by Company and/or Hub to view Scan Users.</span>
          </div>
          <Form form={form} onFinish={onFilterScanUsers} initialValues={state}>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="companyId"
                  className="mb-md-0"
                  rules={[{ type: 'string', required: true, message: 'Please select a company first' }]}>
                  <CustomSelect
                    label="Company"
                    active={state.companyId}
                    onChange={(value) => onSelectionChange('companyId', value)}
                    options={getCompanies()}
                    placeholder="Filter by company"
                  />
                </Form.Item>
              </div>

              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="hubId"
                  className="mb-md-0">
                  <CustomSelect
                    label="Hub (Optional)"
                    active={state.hubId}
                    onChange={(value) => onSelectionChange('hubId', value)}
                    options={getFilteredHubs()}
                    placeholder="Filter by hub"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <button type="submit" className="primary-button primary-filter-button-margin-sm mb-md-0">Filter Scan User</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  )

  function onFilterScanUsers() {
    let company = _.find(companies, x => String(x.id) === state.companyId);
    let hub = _.find(activeHubs, x => String(x.hub_id) === state.hubId);
    if (state.hubId) {
      let hubName = hub.hub_name.replace(/\s+/g, '-').toLowerCase();
      history.push(`/scan-users/${company.name.toLowerCase()}/${hubName}/${company.id}/${hub.hub_id}`);
    } else {
      history.push(`/scan-users/${company.name.toLowerCase()}/${company.id}`);
    }
  }

  function getCompanies() {
    let companiesList = [];
    companies && companies.length > 0 && companies.forEach((company) => {
      company.label = company.name
      company.value = String(company.id);
      companiesList.push(company);
    });
    return companiesList;
  }

  function getFilteredHubs() {
    let filteredHubs = [];
    if (state.companyId !== undefined) {
      filteredHubs = [];
      activeHubs.forEach((hub) => {
        hub.label = hub.hub_name;
        hub.value = String(hub.hub_id);
        filteredHubs.push(hub);
      });
    }
    return filteredHubs;
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    });
    if (name === 'companyId') {
      dispatch(hubsAction.getActiveHubs(value));
    }
  }

}
