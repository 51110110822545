/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { companyAction } from '../../../actions';
import * as _ from 'lodash';
import './style.scss';

export default function CompanyDetails() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const [state] = useState(params);
  const { companies } = useSelector(state => state.companies);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
  }, [dispatch, companies]);

  const company = _.find(companies, x => String(x.id) === state.companyId);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleCompaniesClick}>Companies</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="title">{company && company.name}</span>
        </p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column margin-bottom-30">
            <h2 className="heading">Company Information</h2>
            <button className="secondary-button secondary-button-margin-md" onClick={handleEditCompanyClick}>Edit Company</button>
          </div>
          <div className="company-information">
            <div className="row">
              <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-md-0">
                <h4 className="label-text">Company Name</h4>
                <span className="text-span-black">{company && company.name}</span>
              </div>
              <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
                <h4 className="label-text">Company Admins</h4>
                <span className="text-link" onClick={companyAdmins}>Link</span>
              </div>
              <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
                <h4 className="label-text">Hubs</h4>
                <span className="text-link" onClick={companyHubs}>Link</span>
              </div>
              <div className="col-md-3 col-sm-4 col-12">
                <h4 className="label-text">Scan Types</h4>
                <span className="text-link" onClick={companyScanTypes}>Link</span>
              </div>
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 margin-bottom-30">
                <h2 className="heading">Address</h2>
              </div>
              <div className="col-md-3 col-sm-4 col-12 margin-bottom-30">
                <h4 className="label-text">Country</h4>
                <span className="text-span-black">{company && company.country}</span>
              </div>
              <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
                <h4 className="label-text">State/Province</h4>
                <span className="text-span-black">{company && company.state}</span>
              </div>
              <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
                <h4 className="label-text">City</h4>
                <span className="text-span-black">{company && company.city}</span>
              </div>
              <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
                <h4 className="label-text">Address</h4>
                <span className="text-span-black">{company && company.address}</span>
              </div>
              <div className="col-md-3 col-sm-4 col-12">
                <h4 className="label-text">Postal Code</h4>
                <span className="text-span-black">{company && company.postal_code}</span>
              </div>
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 margin-bottom-30">
                <h2 className="heading">Point of Contact</h2>
              </div>
              <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
                <h4 className="label-text">Name</h4>
                <span className="text-span-black"></span>
              </div>
              <div className="col-md-3 col-sm-4 col-12">
                <h4 className="label-text">Email</h4>
                <span className="text-span-black"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  function handleEditCompanyClick() {
    history.push(`/companies/company/update/${company.id}/${company.name.toLowerCase()}`);
  }

  function handleCompaniesClick() {
    history.push('/companies');
  }

  function companyAdmins() {
    history.push({
      pathname: `/company-admins/${company.name.toLowerCase()}`,
      state: state
    });
  }

  function companyHubs() {
    history.push({
      pathname: `/hubs/hubsList/${company.id}`,
      state: state
    });
  }

  function companyScanTypes() {
    history.push({
      pathname: `/scan-types/${company.name.toLowerCase()}/${company.id}`,
      state: state
    });
  }
}
