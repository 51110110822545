/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import CustomInput from '../../../components/input/input';
import CustomSelect from '../../../components/select/select';
import { companyAction, companyAdminActions } from '../../../actions';
import * as _ from 'lodash';
import './style.scss';


export default function AddCompanyAdmin() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { companies } = useSelector(state => state.companies);

  const { 
    companyAdminCreated, 
    companyAdminUpdated, 
    createCompanyAdminErrorMessage, 
    updateCompanyAdminErrorMessage 
  } = useSelector(state => state.companyAdmin);


  const [addError, setAddError] = useState(createCompanyAdminErrorMessage);
  const [updateError, setUpdateError] = useState(updateCompanyAdminErrorMessage);

  const [state, setState] = useState({
    admin_name: '',
    admin_email: undefined,
    is_active: true,
    is_point_of_contact: false,
    password: '',
    confirm_password: ''
  });

  const [locationState] = useState(location.state);

  const statusOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  useEffect(() => {
    if (location.state && location.state.admin) {
      setState({
        admin_name: location.state.admin.admin_name,
        admin_email: location.state.admin.admin_email,
        is_active: location.state.admin.is_active,
        is_point_of_contact: location.state.admin.is_point_of_contact,
        password: location.state.admin.password,
        confirm_password: location.state.admin.confirm_password,
      });
      form.setFieldsValue({
        admin_name: location.state.admin.admin_name,
        admin_email: location.state.admin.admin_email,
        is_active: location.state.admin.is_active,
        is_point_of_contact: location.state.admin.is_point_of_contact,
        password: location.state.admin.password,
        confirm_password: location.state.admin.confirm_password,
      });
    }
  }, [location, form]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    }
  }, [companies, dispatch]);

  useEffect(() => {
    if (!_.isEqual(addError, createCompanyAdminErrorMessage)) {
      setAddError(createCompanyAdminErrorMessage);
    }
  }, [createCompanyAdminErrorMessage]);

  useEffect(() => {
    if (!_.isEqual(updateError, updateCompanyAdminErrorMessage)) {
      setUpdateError(updateCompanyAdminErrorMessage);
    }
  }, [updateCompanyAdminErrorMessage]);

  useEffect(() => {
    if (companyAdminCreated) {
      let company = _.find(companies, x => x.id === locationState.state.companyId);
      history.push({
        pathname: `/company-admins/${company.name.toLowerCase()}`,
        state: locationState.state
      });
    }
  }, [companyAdminCreated, history, companies, locationState.state]);

  useEffect(() => {
    if (companyAdminUpdated) {
      let company = _.find(companies, x => x.id === locationState.state.companyId);
      history.push({
        pathname: `/company-admins/${company.name.toLowerCase()}`,
        state: locationState.state
      });
    }
  }, [companyAdminUpdated, history, companies, locationState.state]);

  let company = _.find(companies, x => x.id === locationState.state.companyId);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleCompanyAdminsClick}>Company Admins</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="link-title" onClick={goToCompaniesDetailsPage}>{company && company.name}</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="title">{breadCrumbText()}</span>
        </p>
        <div className="bg-container-body">
          <Form form={form} onFinish={onAddCompanyAdmin} initialValues={state}>
            <div className="d-flex heading-area">
              <h2 className="heading mb-0">Company Admin Information</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="admin_name"
                  rules={[{ type: 'string', required: true, message: 'Please type a company admin name first' }]}
                  validateStatus={getValidateStatus('admin_name') ? 'error' : 'validating'}
                  help={getError('admin_name')}>
                  <CustomInput
                    label="Company Admin Name"
                    name="admin_name"
                    value={state.admin_name}
                    onChange={handleChange}
                    placeholder="Type here"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="admin_email"
                  rules={[{ type: 'string', required: true, message: 'Please type a company admin username/email first' }]}
                  validateStatus={getValidateStatus('admin_email') ? 'error' : 'validating'}
                  help={getError('admin_email')}>
                  <CustomInput
                    label="Company Admin Username/Email"
                    name="admin_email"
                    value={state.admin_email}
                    onChange={handleChange}
                    placeholder="Type here"
                    disabled={location.state.admin && location.state.admin.admin_email}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="is_active"
                >
                  <CustomSelect
                    label="Is Active"
                    active={state.is_active}
                    onChange={(value) => onSelectionChange('is_active', value)}
                    options={statusOptions}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="is_point_of_contact"
                  className="mb-0"
                >
                  <CustomSelect
                    label="Point of Contact?"
                    active={state.is_point_of_contact}
                    onChange={(value) => onSelectionChange('is_point_of_contact', value)}
                    options={statusOptions}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
            </div>
            <div className="d-flex heading-area">
              <h2 className="heading mb-0">{location.state.admin ? 'Change Password' : 'Password'}</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="password"
                  className="mb-md-0"
                  rules={getPasswordRules()}
                  validateStatus={getValidateStatus('password') ? 'error' : 'validating'}
                  help={getError('password')}>
                  <CustomInput
                    type="password"
                    label="Password"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                    placeholder="Type here"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="confirm_password"
                  className="mb-md-0"
                  rules={getPasswordRules()}
                  validateStatus={getValidateStatus('confirm_password') ? 'error' : 'validating'}
                  help={getError('confirm_password')}>
                  <CustomInput
                    type="password"
                    label="Confirm Password"
                    name="confirm_password"
                    value={state.confirm_password}
                    onChange={handleChange}
                    placeholder="Type here"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-12">
                <Divider className="blue-divider" />
              </div>
            </div>
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button type="button" className="secondary-button" onClick={goToCompaniesDetailsPage}>Cancel</button>
              <button type="submit" className="primary-button primary-button-margin-bottom-sm">{getButtonText()}</button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )

  function getPasswordRules() {
    if (location.state.admin) {
    return null;
    }
    return [{ type: 'string', required: true, message: 'Please type a password first' }];
  }

  function getValidateStatus(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getError(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getAddError(name) {
    if (addError && addError[name] && addError[name].length > 0) {
      return addError[name][0];
    }
  }

  function getUpdateError(name) {
    if (updateError && updateError[name] && updateError[name].length > 0) {
      return updateError[name][0];
    }
  }

  function breadCrumbText() {
    if (location.state && location.state.admin) {
      return `Edit Company Admin`;
    }
    return 'Add Company Admin';
  }

  function getButtonText() {
    if (location.state && location.state.admin) {
      return 'Edit Company Admin'
    }
    return 'Add Company Admin';
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value
    });
    resetAddErrorData(name);
    resetUpdateErrorData(name);
  }

  function resetAddErrorData(name) {
    if (addError && addError[name] && addError[name].length > 0) {
      let addErrorState = { ...addError };
      delete addErrorState[name];
      if (name === 'confirm_password') {
        delete addErrorState['password'];
      }
      if (name === 'password') {
        delete addErrorState['confirm_password'];
      }
      setAddError(addErrorState);
    }
  }

  function resetUpdateErrorData(name) {
    if (updateError && updateError[name] && updateError[name].length > 0) {
      let updateErrorState = { ...updateError };
      delete updateErrorState[name];
      if (name === 'confirm_password') {
        delete updateErrorState['password'];
      }
      if (name === 'password') {
        delete updateErrorState['confirm_password'];
      }
      setUpdateError(updateErrorState);
    }
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    })
  }

  function getCompanyAdminData() {
    const companyAdminData = { ...state };
    companyAdminData.company_id = locationState.state.companyId;
    return companyAdminData;
  }

  function onAddCompanyAdmin() {
    let companyAdminData = getCompanyAdminData();
    if (location.state && location.state.admin) {
      companyAdminData.id = location.state.admin.id;
      dispatch(companyAdminActions.updateCompanyAdmin(companyAdminData));
      return;
    }
    dispatch(companyAdminActions.createCompanyAdmin(companyAdminData));
  }

  function goToCompaniesDetailsPage() {
    let company = _.find(companies, x => x.id === locationState.state.companyId);
    history.push({
      pathname: `/company-admins/${company.name.toLowerCase()}`,
      state: locationState.state
    });
  }

  function handleCompanyAdminsClick() {
    history.push('/company-admins');
  }
}