import { commonTypes } from '../types';

const initialState = {
  exceptionTypesLoading: false,
  exceptionTypes: [],
  exceptionTypesError: false,
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonTypes.GET_EXCEPTION_TYPES_LOADING:
      return { ...state, exceptionTypesLoading: true }
    case commonTypes.GET_EXCEPTION_TYPES_SUCCESS:
      return { ...state, exceptionTypesLoading: false, exceptionTypes: action.payload };
    case commonTypes.GET_EXCEPTION_TYPES_FAILURE:
      return { ...state, exceptionTypesLoading: false, exceptionTypesError: action.payload }

    default:
      return state;
  }
}

export { commonReducer }
