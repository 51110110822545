import { companiesTypes } from '../types';
import { companyService } from '../services';
import * as _ from 'lodash';

const createCompany = (companyData) => {
  const loading = () => ({ type: companiesTypes.CREATE_COMPANY_LOADING })
  const success = (payload) => ({ type: companiesTypes.CREATE_COMPANY_SUCCESS, payload })
  const failure = (payload) => ({ type: companiesTypes.CREATE_COMPANY_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await companyService.createCompany(companyData);
      let successMessage = `New company ${companyData.company_name} has been added`;
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetCreateCompanyData = () => {
  return function (dispatch) {
    dispatch({ type: companiesTypes.RESET_CREATE_COMPANY_DATA })
  }
}

const updateCompany = (companyData) => {
  const loading = () => ({ type: companiesTypes.UPDATE_COMPANY_LOADING })
  const success = (payload) => ({ type: companiesTypes.UPDATE_COMPANY_SUCCESS, payload })
  const failure = (payload) => ({ type: companiesTypes.UPDATE_COMPANY_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await companyService.updateCompany(companyData);
      let successMessage = `Company ${companyData.company_name} is ${companyData.is_active ? 'active' : 'inactive'}`;
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetUpdateCompanyData = () => {
  return function (dispatch) {
    dispatch({ type: companiesTypes.RESET_UPDATE_COMPANY_DATA });
  }
}

const getActiveCompanies = () => {
  const loading = () => ({ type: companiesTypes.FETCH_COMPANIES_LOADING })
  const success = (payload) => ({ type: companiesTypes.FETCH_COMPANIES_SUCCESS, payload })
  const failure = (payload) => ({ type: companiesTypes.FETCH_COMPANIES_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      let companies = await companyService.getActiveCompanies()
      companies = _.orderBy(companies, ['name', 'asc']);
      dispatch(success(companies))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}
  
const getInactiveCompanies = () => {
  const loading = () => ({ type: companiesTypes.FETCH_INACTIVE_COMPANIES_LOADING })
  const success = (payload) => ({ type: companiesTypes.FETCH_INACTIVE_COMPANIES_SUCCESS, payload })
  const failure = (payload) => ({ type: companiesTypes.FETCH_INACTIVE_COMPANIES_FAILURE, payload })

  return async function (dispatch) {
    loading();
    try {
      let companies = await companyService.getInactiveCompanies();
      companies = _.orderBy(companies, ['name', 'asc'])
      dispatch(success(companies));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}

export const companyAction = {
  createCompany,
  resetCreateCompanyData,
  updateCompany,
  resetUpdateCompanyData,
  getActiveCompanies,
  getInactiveCompanies
}

