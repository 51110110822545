import { scanTypesTypes } from '../types';
import { scanTypesService } from '../services';
import * as _ from 'lodash';

const createScanType = (scanTypeData) => {
  const loading = () => ({ type: scanTypesTypes.CREATE_SCAN_TYPE_LOADING })
  const success = (payload) => ({ type: scanTypesTypes.CREATE_SCAN_TYPE_SUCCESS, payload })
  const failure = (payload) => ({ type: scanTypesTypes.CREATE_SCAN_TYPE_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await scanTypesService.createScanType(scanTypeData);
      let successMessage = `Scan type has been added.`;
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetCreateScanTypeData = () => {
  return function (dispatch) {
    dispatch({ type: scanTypesTypes.RESET_CREATE_SCAN_TYPE_DATA });
  }
}

const updateScanType = (scanTypeData) => {
  const loading = () => ({ type: scanTypesTypes.UPDATE_SCAN_TYPE_LOADING })
  const success = (payload) => ({ type: scanTypesTypes.UPDATE_SCAN_TYPE_SUCCESS, payload })
  const failure = (payload) => ({ type: scanTypesTypes.UPDATE_SCAN_TYPE_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await scanTypesService.updateScanType(scanTypeData);
      let successMessage = `Scan type ${scanTypeData.scan_type} has been edited.`;
      dispatch(success(successMessage));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}

const resetUpdatedScanTypeData = () => {
  return function (dispatch) {
    dispatch({ type: scanTypesTypes.RESET_UPDATED_SCAN_TYPE_DATA });
  }
}

const getActiveScanTypes = (data) => {
  const loading = () => ({ type: scanTypesTypes.FETCH_SCAN_TYPES_LOADING })
  const success = (payload) => ({ type: scanTypesTypes.FETCH_SCAN_TYPES_SUCCESS, payload })
  const failure = (payload) => ({ type: scanTypesTypes.FETCH_SCAN_TYPES_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      let result = await scanTypesService.getActiveScanTypes(data);
      result = _.filter(result, x => x.leg === 'inbound');
      dispatch(success(result))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const getInactiveScanTypes = (data) => {
  const loading = () => ({ type: scanTypesTypes.FETCH_INACTIVE_SCAN_TYPES_LOADING })
  const success = (payload) => ({ type: scanTypesTypes.FETCH_INACTIVE_SCAN_TYPES_SUCCESS, payload })
  const failure = (payload) => ({ type: scanTypesTypes.FETCH_INACTIVE_SCAN_TYPES_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      let result = await scanTypesService.getInactiveScanTypes(data);
      result = _.filter(result, x => x.leg === 'inbound');
      dispatch(success(result));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}

const getJanioScanTypes = () => {
  const loading = () => ({ type: scanTypesTypes.FETCH_JANIO_SCAN_TYPES_LOADING })
  const success = (payload) => ({ type: scanTypesTypes.FETCH_JANIO_SCAN_TYPES_SUCCESS, payload })
  const failure = (payload) => ({ type: scanTypesTypes.FETCH_JANIO_SCAN_TYPES_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      let result = await scanTypesService.getJanioScanTypes();
      result = _.filter(result, x => x.leg === 'inbound');
      dispatch(success(result))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}


export const scanTypesAction = {
  createScanType,
  resetCreateScanTypeData,
  updateScanType,
  resetUpdatedScanTypeData,
  getActiveScanTypes,
  getInactiveScanTypes,
  getJanioScanTypes
}