/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Form, Divider } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { companyAction, locationAction } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';
import CustomInput from '../../../components/input/input';
import CustomSelect from '../../../components/select/select';
import * as _ from 'lodash';
import './style.scss';


export default function AddCompany() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    companies,
    companyCreated,
    addCompanyErrorMessage,
    companyUpdated,
    updateCompanyErrorMessage
  } = useSelector(state => state.companies);

  const [addError, setAddError] = useState(addCompanyErrorMessage);
  const [updateError, setUpdateError] = useState(updateCompanyErrorMessage);

  const [form] = Form.useForm();

  const [state, setState] = useState({
    company_name: '',
    is_active: true,
    country_id: undefined,
    state_id: undefined,
    city_id: undefined,
    company_address: '',
    postal_code: ''
  });

  const company = params.companyId && _.find(companies, x => String(x.id) === params.companyId);

  const { countries, states, cities } = useSelector(state => state.location);

  useEffect(() => {
    if (!_.isEqual(addError, addCompanyErrorMessage)) {
      setAddError(addCompanyErrorMessage);
    }
  }, [addCompanyErrorMessage]);

  useEffect(() => {
    if (!_.isEqual(updateError, updateCompanyErrorMessage)) {
      setUpdateError(updateCompanyErrorMessage);
    }
  }, [updateCompanyErrorMessage]);

  useEffect(() => {
    dispatch(locationAction.getCountries());
  }, [dispatch]);

  useEffect(() => {
    if (companyCreated) {
      history.push("/companies");
    }
  }, [companyCreated, history]);

  useEffect(() => {
    if (companyUpdated) {
      history.push("/companies");
    }
  }, [companyUpdated, history]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
  }, [dispatch, companies]);

  useEffect(() => {
    if (params.companyId && company) {
      let stateData = {
        country: company.country
      }
      let cityData = {
        country: company.country,
        state: company.state,
      }
      dispatch(locationAction.getCountries());
      dispatch(locationAction.getStates(stateData));
      dispatch(locationAction.getCities(cityData));
      setState({
        ...state,
        company_name: company.name,
        is_active: true,
        country_id: company.country,
        state_id: company.state,
        city_id: company.city,
        company_address: company.address,
        postal_code: company.postal_code
      });

      form.setFieldsValue({
        company_name: company.name,
        is_active: true,
        country_id: company.country,
        state_id: company.state,
        city_id: company.city,
        company_address: company.address,
        postal_code: company.postal_code
      });
    }
  }, [params, company, form, dispatch]);

  const statusOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const companyBreadCrumbClass = company ? '' : 'd-none';

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleBreadCrumbsClick}>Companies</span>
          <span className={"bread-crumb " + companyBreadCrumbClass}>{'>'}</span>
          <span className={"link-title " + companyBreadCrumbClass} onClick={handleCompanyNameClick}>{company && company.name}</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="title">{getText()}</span>
        </p>
        <div className="bg-container-body">
          <Form form={form} onFinish={onAddCompany} initialValues={state}>
            <div className="d-flex heading-area">
              <h2 className="heading">Company Information</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="company_name"
                  className="mb-sm-0"
                  rules={[{ type: 'string', required: true, message: 'Please type a company name first' }]}
                  validateStatus={getValidateStatus('company_name') ? 'error' : 'validating'}
                  help={getError('company_name')}>
                  <CustomInput
                    label="Company Name"
                    name="company_name"
                    onChange={handleChange}
                    placeholder="Type here"
                    className="login-input"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="is_active"
                  className="mb-0">
                  <CustomSelect
                    label="Active?"
                    active={state.is_active}
                    onChange={(value) => onSelectionChange('is_active', value)}
                    options={statusOptions}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
            </div>
            <div className="d-flex heading-area">
              <h2 className="heading">Address</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                  <Form.Item
                    name="country_id"
                    rules={[{ type: 'string', required: true, message: 'Please select a country first' }]}
                    validateStatus={getValidateStatus('country_id') ? 'error' : 'validating'}
                    help={getError('country_id')}>
                    <CustomSelect
                      label="Country"
                      active={state.country_id}
                      onChange={(value) => onCountrySelectionChange('country_id', value)}
                      options={countries}
                      placeholder="Select Country"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4 col-sm-6 col-12">
                  <Form.Item
                    name="state_id"
                    rules={[{ type: 'string', required: true, message: 'Please select a state/province first' }]}
                    validateStatus={getValidateStatus('state_id') ? 'error' : 'validating'}
                    help={getError('state_id')}>
                    <CustomSelect
                      label="State/Province"
                      active={state.state_id}
                      onChange={(value) => onStateSelectionChange('state_id', value)}
                      options={states}
                      placeholder="Select state/province"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4 col-sm-6 col-12">
                  <Form.Item
                    name="city_id"
                    rules={[{ type: 'string', required: true, message: 'Please select a city first' }]}
                    validateStatus={getValidateStatus('city_id') ? 'error' : 'validating'}
                    help={getError('city_id')}>
                    <CustomSelect
                      label="City"
                      active={state.city_id}
                      onChange={(value) => onCitySelectionChange('city_id', value)}
                      options={cities}
                      placeholder="Select city"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-8 col-sm-12">
                  <Form.Item
                    name="company_address"
                    className="mb-md-0"
                    rules={[{ type: 'string', required: true, message: 'Please type an address first' }]}
                    validateStatus={getValidateStatus('company_address') ? 'error' : 'validating'}
                    help={getError('company_address')}>
                    <CustomInput
                      label="Address"
                      name="company_address"
                      onChange={handleChange}
                      placeholder="Type here"
                      className="login-input"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4 col-sm-12">
                  <Form.Item
                    name="postal_code"
                    className="mb-0"
                    rules={[{ type: 'string', required: true, message: 'Please type a postal code first' }]}
                    validateStatus={getValidateStatus('postal_code') ? 'error' : 'validating'}
                    help={getError('postal_code')}>
                    <CustomInput
                      label="Postal code"
                      name="postal_code"
                      onChange={handleChange}
                      placeholder="Type here"
                      className="login-input"
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Divider className="blue-divider" />
                </div>
            </div>
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button className="secondary-button" onClick={handleCancelButtonClick}>Cancel</button>
              <button type="submit" className="primary-button primary-button-margin-bottom-sm">{getText()}</button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );

  function getValidateStatus(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getError(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getAddError(name) {
    if (addError && addError[name] && addError[name].length > 0) {
      return addError[name][0];
    }
  }

  function getUpdateError(name) {
    if (updateError && updateError[name] && updateError[name].length > 0) {
      return updateError[name][0];
    }
  }

  function getText() {
    if (params.companyId) {
      return 'Edit Company';
    }
    return 'Add Company';
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value
    })
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    })
  }

  function onCountrySelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    });
    let data = {
      country: value
    }
    dispatch(locationAction.getStates(data))
  }

  function onStateSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    });
    let data = {
      country: state.country_id,
      state: value
    }
    dispatch(locationAction.getCities(data))
  }

  function onCitySelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    });
  }

  function getLocations() {
    const selectedCountry = _.find(countries, x => x.country_name === state.country_id);
    const selectedState = _.find(states, x => x.state_name === state.state_id);
    const selectedCity = _.find(cities, x => x.city_name === state.city_id);
    return { selectedCountry, selectedState, selectedCity };
  }

  function getCompanyData() {
    const { selectedCountry, selectedState, selectedCity } = getLocations();
    const companyData = {
      company_name: state.company_name,
      country_id: selectedCountry.country_id,
      state_id: selectedState.state_id,
      city_id: selectedCity.city_id,
      company_address: state.company_address,
      postal_code: state.postal_code,
      is_active: state.is_active
    }
    return companyData;
  }

  function onAddCompany() {
    let companyData = getCompanyData();
    if (params.companyId) {
      companyData.id = company.id;
      dispatch(companyAction.updateCompany(companyData));
      return;
    }
    dispatch(companyAction.createCompany(companyData));
  }

  function handleCompanyNameClick() {
    history.push(`/companies/${company.id}/${company.name.toLowerCase()}`);
  }

  function handleCancelButtonClick() {
    history.push("/companies");
  }

  function handleBreadCrumbsClick() {
    history.push("/companies");
  }
}
