import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

import Login from './pages/login/login';
import Companies from './pages/companies/companies';
import CompanyAdmins from './pages/companyAdmins/companyAdmins';
import Hubs from './pages/hubs/hubs';
import HubManagers from './pages/hubManagers/hubManagers';
import ScanUsers from './pages/scanUsers/scanUsers';
import ScanTypes from './pages/scanTypes/scanTypesPage';
import AccountInformation from './pages/accountInformation/accountInformation';
import Tools from './pages/tools';


const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute path="/companies" component={Companies} />
        <PrivateRoute path="/company-admins" component={CompanyAdmins} />
        <PrivateRoute path="/hubs" component={Hubs} />
        <PrivateRoute path="/hub-managers" component={HubManagers} />
        <PrivateRoute path="/scan-users" component={ScanUsers} />
        <PrivateRoute path="/scan-types" component={ScanTypes} />
        <PrivateRoute path="/account-information" component={AccountInformation} />
        <PrivateRoute path="/tools" component={Tools} />
      </Switch>
    </Router>
  )
}

export default Routes;