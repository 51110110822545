export const scanTypesTypes = {
  CREATE_SCAN_TYPE_LOADING: 'CREATE_SCAN_TYPE_LOADING',
  CREATE_SCAN_TYPE_SUCCESS: 'CREATE_SCAN_TYPE_SUCCESS',
  CREATE_SCAN_TYPE_FAILURE: 'CREATE_SCAN_TYPE_FAILURE',
  RESET_CREATE_SCAN_TYPE_DATA: 'RESET_CREATE_SCAN_TYPE_DATA',

  UPDATE_SCAN_TYPE_LOADING: 'UPDATE_SCAN_TYPE_LOADING',
  UPDATE_SCAN_TYPE_SUCCESS: 'UPDATE_SCAN_TYPE_SUCCESS',
  UPDATE_SCAN_TYPE_FAILURE: 'UPDATE_SCAN_TYPE_FAILURE',
  RESET_UPDATED_SCAN_TYPE_DATA: 'RESET_UPDATED_SCAN_TYPE_DATA',

  FETCH_SCAN_TYPES_LOADING: 'FETCH_SCAN_TYPES_LOADING',
  FETCH_SCAN_TYPES_SUCCESS: 'FETCH_SCAN_TYPES_SUCCESS',
  FETCH_SCAN_TYPES_FAILURE: 'FETCH_SCAN_TYPES_FAILURE',

  FETCH_INACTIVE_SCAN_TYPES_LOADING: 'FETCH_INACTIVE_SCAN_TYPES_LOADING',
  FETCH_INACTIVE_SCAN_TYPES_SUCCESS: 'FETCH_INACTIVE_SCAN_TYPES_SUCCESS',
  FETCH_INACTIVE_SCAN_TYPES_FAILURE: 'FETCH_INACTIVE_SCAN_TYPES_FAILURE',

  FETCH_JANIO_SCAN_TYPES_LOADING: 'FETCH_JANIO_SCAN_TYPES_LOADING',
  FETCH_JANIO_SCAN_TYPES_SUCCESS: 'FETCH_JANIO_SCAN_TYPES_SUCCESS',
  FETCH_JANIO_SCAN_TYPES_FAILURE: 'FETCH_JANIO_SCAN_TYPES_FAILURE'
}