export const hubManagersTypes = {
  CREATE_COMPANY_MANAGER_LOADING: 'CREATE_COMPANY_MANAGER_LOADING',
  CREATE_COMPANY_MANAGER_SUCCESS: 'CREATE_COMPANY_MANAGER_SUCCESS',
  CREATE_COMPANY_MANAGER_FAILURE: 'CREATE_COMPANY_MANAGER_FAILURE',
  RESET_CREATED_COMPANY_MANAGER_DATA: 'RESET_CREATED_COMPANY_MANAGER_DATA',

  UPDATE_COMPANY_MANAGER_LOADING: 'UPDATE_COMPANY_MANAGER_LOADING',
  UPDATE_COMPANY_MANAGER_SUCCESS: 'UPDATE_COMPANY_MANAGER_SUCCESS',
  UPDATE_COMPANY_MANAGER_FAILURE: 'UPDATE_COMPANY_MANAGER_FAILURE',
  RESET_UPDATED_COMPANY_MANAGER_DATA: 'RESET_UPDATED_COMPANY_MANAGER_DATA',

  FETCH_COMPANY_ACTIVE_MANAGERS_LOADING: 'FETCH_COMPANY_ACTIVE_MANAGERS_LOADING',
  FETCH_COMPANY_ACTIVE_MANAGERS_SUCCESS: 'FETCH_COMPANY_ACTIVE_MANAGERS_SUCCESS',
  FETCH_COMPANY_ACTIVE_MANAGERS_FAILURE: 'FETCH_COMPANY_ACTIVE_MANAGERS_FAILURE',

  FETCH_COMPANY_INACTIVE_MANAGERS_LOADING: 'FETCH_COMPANY_INACTIVE_MANAGERS_LOADING',
  FETCH_COMPANY_INACTIVE_MANAGERS_SUCCESS: 'FETCH_COMPANY_INACTIVE_MANAGERS_SUCCESS',
  FETCH_COMPANY_INACTIVE_MANAGERS_FAILURE: 'FETCH_COMPANY_INACTIVE_MANAGERS_FAILURE',

  CREATE_HUB_MANAGER_LOADING: 'CREATE_HUB_MANAGER_LOADING',
  CREATE_HUB_MANAGER_SUCCESS: 'CREATE_HUB_MANAGER_SUCCESS',
  CREATE_HUB_MANAGER_FAILURE: 'CREATE_HUB_MANAGER_FAILURE',
  RESET_CREATED_HUB_MANAGER_DATA: 'RESET_CREATED_HUB_MANAGER_DATA',

  UPDATE_HUB_MANAGER_LOADING: 'UPDATE_HUB_MANAGER_LOADING',
  UPDATE_HUB_MANAGER_SUCCESS: 'UPDATE_HUB_MANAGER_SUCCESS',
  UPDATE_HUB_MANAGER_FAILURE: 'UPDATE_HUB_MANAGER_FAILURE',
  RESET_UPDATED_HUB_MANAGER_DATA: 'RESET_UPDATED_HUB_MANAGER_DATA',

  FETCH_HUB_ACTIVE_MANAGERS_LOADING: 'FETCH_HUB_ACTIVE_MANAGERS_LOADING',
  FETCH_HUB_ACTIVE_MANAGERS_SUCCESS: 'FETCH_HUB_ACTIVE_MANAGERS_SUCCESS',
  FETCH_HUB_ACTIVE_MANAGERS_FAILURE: 'FETCH_HUB_ACTIVE_MANAGERS_FAILURE',
  
  FETCH_HUB_INACTIVE_MANAGERS_LOADING: 'FETCH_HUB_INACTIVE_MANAGERS_LOADING',
  FETCH_HUB_INACTIVE_MANAGERS_SUCCESS: 'FETCH_HUB_INACTIVE_MANAGERS_SUCCESS',
  FETCH_HUB_INACTIVE_MANAGERS_FAILURE: 'FETCH_HUB_INACTIVE_MANAGERS_FAILURE'
}