import React from "react";
import { Drawer } from "antd";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo_blue.png";
import Cookies from "universal-cookie";
import "./style.scss";

const cookies = new Cookies();

const Sidebar = ({ toggleSidebar, showSidebar }) => {
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload();
    cookies.remove("token");
  };

  const user = localStorage.getItem("user");

  return (
    <Drawer
      title=""
      drawerStyle={{ background: "#FFFFFF" }}
      placement="left"
      closable={false}
      onClose={toggleSidebar}
      visible={showSidebar}
    >
      <div className="sidebar">
        <div className="top-bar">
          <img
            style={{ padding: "5px", marginTop: "-15px", width: "90px" }}
            src={logo}
            alt="janio-logo-blue"
          />
        </div>
        <div className="top-bar-username">
          <span>Hello, {user}!</span>
        </div>

        <ul className="nav">
          <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading">
              Companies
            </span>
          </li>
          <li className="nav-item">
            <Link className="nav-link nav-subheading" to="/companies">
              Companies
            </Link>
            <Link className="nav-link nav-subheading" to="/company-admins">
              Company Admins
            </Link>
          </li>

          <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading">Hubs</span>
          </li>
          <li className="nav-item">
            <Link className="nav-link nav-subheading" to="/hubs">
              Hubs
            </Link>
            <Link className="nav-link nav-subheading" to="/hub-managers">
              Hub Managers
            </Link>
          </li>

          <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading">Scans</span>
          </li>
          <li className="nav-item">
            <Link className="nav-link nav-subheading" to="/scan-users">
              Scan Users
            </Link>
            <Link className="nav-link nav-subheading" to="/scan-types">
              Scan Types
            </Link>
          </li>

          <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading">Tools</span>
          </li>
          <li className="nav-item">
            <Link className="nav-link nav-subheading" to="/tools/bulkException">
              Exceptions in Bulk
            </Link>
          </li>
          <li>
            <Link
              className="nav-link nav-subheading"
              to="/tools/resource-definitions"
            >
              Resource Definitions
            </Link>
          </li>

          <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading">
              Account
            </span>
          </li>
          <li className="nav-item">
            <Link className="nav-link nav-subheading" to="/account-information">
              Account Information
            </Link>
            <span
              style={{ cursor: "pointer" }}
              className="nav-link nav-subheading"
              onClick={logout}
            >
              Logout
            </span>
          </li>
        </ul>
      </div>
    </Drawer>
  );
};

export default Sidebar;
