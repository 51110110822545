import { hubTypes } from '../types';
import { hubsService } from '../services';

const createHub = (data) => {
  const loading = () => ({ type: hubTypes.CREATE_HUB_LOADING })
  const success = (payload) => ({ type: hubTypes.CREATE_HUB_SUCCESS, payload })
  const failure = (payload) => ({ type: hubTypes.CREATE_HUB_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await hubsService.createHub(data);
      let successMessage = 'Hub has been added';
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetCreatedHubData = () => {
  return function (dispatch) {
    dispatch({ type: hubTypes.RESET_CREATED_HUB_DATA });
  }
}

const updateHub = (data) => {
  const loading = () => ({ type: hubTypes.UPDATE_HUB_LOADING })
  const success = (payload) => ({ type: hubTypes.UPDATE_HUB_SUCCESS, payload })
  const failure = (payload) => ({ type: hubTypes.UPDATE_HUB_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await hubsService.updateHub(data);
      let successMessage = 'Hub has been updated';
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetUpdatedHubData = () => {
  return function (dispatch) {
    dispatch({ type: hubTypes.RESET_UPDATED_HUB_DATA });
  }
}

const getActiveHubs = (companyId) => {
  const loading = () => ({ type: hubTypes.FETCH_ACTIVE_HUBS_LOADING })
  const success = (payload) => ({ type: hubTypes.FETCH_ACTIVE_HUBS_SUCCESS, payload })
  const failure = (payload) => ({ type: hubTypes.FETCH_ACTIVE_HUBS_FAILURE, payload })

  return async function(dispatch) {
    loading()
    try {
      const result = await hubsService.getActiveHubs(companyId);
      dispatch(success(result.data))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const getInactiveHubs = (companyId) => {
  const loading = () => ({ type: hubTypes.FETCH_INACTIVE_HUBS_LOADING })
  const success = (payload) => ({ type: hubTypes.FETCH_INACTIVE_HUBS_SUCCESS, payload })
  const failure = (payload) => ({ type: hubTypes.FETCH_INACTIVE_HUBS_FAILURE, payload })

  return async function(dispatch) {
    loading()
    try {
      const result = await hubsService.getInactiveHubs(companyId);
      dispatch(success(result.data))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

export const hubsAction = {
  createHub,
  resetCreatedHubData,
  updateHub,
  resetUpdatedHubData,
  getActiveHubs,
  getInactiveHubs
}