import { hubPackagesTypes } from '../types';

const initialState = {
  packagesData: [],
  packagesDataLoading: false,
  packagesDataErrorMessage: '',
  savedPackagesData: [],

  cargosData: [],
  cargosDataLoading: false,
  cargosDataErrorMessage: '',
};

const hubPackagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case hubPackagesTypes.GET_HUB_PACKAGES_DATA_LOADING:
      return { ...state, packagesDataLoading: true };
    case hubPackagesTypes.GET_HUB_PACKAGES_DATA_SUCCESS:
      return { ...state, packagesDataLoading: false, packagesData: action.payload };
    case hubPackagesTypes.GET_HUB_PACKAGES_DATA_ERROR:
      return { ...state, packagesDataLoading: false, packagesData: [], savedPackagesData: [], packagesDataErrorMessage: action.payload };
    
    case hubPackagesTypes.SAVE_HUB_PACKAGES_DATA_SUCCESS:
      return { ...state, savedPackagesData: action.payload };
    
    case hubPackagesTypes.GET_HUB_CARGO_DATA_LOADING:
      return { ...state, cargosDataLoading: true };
    case hubPackagesTypes.GET_HUB_CARGO_DATA_SUCCESS:
      return { ...state, cargosDataLoading: false, cargosData: action.payload };
    case hubPackagesTypes.GET_HUB_CARGO_DATA_ERROR:
      return { ...state, cargosDataLoading: false, cargosData: [], cargosDataErrorMessage: action.payload };
    default:
      return state;
  }
}

export { hubPackagesReducer };