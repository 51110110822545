import { locationTypes } from '../types';

const initialState = {
  fetchCountriesLoading: false,
  fetchCountriesErrorMessage: null,
  countries: [],
  fetchStatesLoading: false,
  fetchStatesErrorMessage: null,
  states: [],
  fetchCitiesLoading: false,
  fetchCitiesErrorMessage: null,
  cities: [],
}

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case locationTypes.FETCH_COUNTRIES_LOADING:
      return { ...state, fetchCountriesLoading: true }
    case locationTypes.FETCH_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload, fetchCountriesLoading: false }
    case locationTypes.FETCH_COUNTRIES_FAILURE:
      return { ...state, fetchCountriesErrorMessage: action.payload, fetchCountriesLoading: false }
    case locationTypes.FETCH_STATES_LOADING:
      return { ...state, fetchStatesLoading: true }
    case locationTypes.FETCH_STATES_SUCCESS:
      return { ...state, states: action.payload, fetchStatesLoading: false }
    case locationTypes.FETCH_STATES_FAILURE:
      return { ...state, fetchStatesErrorMessage: action.payload, fetchStatesLoading: false }
    case locationTypes.FETCH_CITIES_LOADING:
      return { ...state, fetchCitiesLoading: true }
    case locationTypes.FETCH_CITIES_SUCCESS:
      return { ...state, cities: action.payload, fetchCitiesLoading: false }
    case locationTypes.FETCH_CITIES_FAILURE:
      return { ...state, fetchCitiesErrorMessage: action.payload, fetchCitiesLoading: false }
    default:
      return state;
  }
}

export { locationReducer }