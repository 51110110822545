/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { message, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { companyAction, companyAdminActions } from '../../../actions';
import * as _ from 'lodash';
import './style.scss'

export default function CompanyAdminDetails() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [state] = useState(location.state);
  const { companies } = useSelector(state => state.companies);

  const {
    companyActiveAdmins,
    companyInactiveAdmins,
    companyAdminCreated,
    createCompanyAdminSuccessMessage,
    companyAdminUpdated,
    updateCompanyAdminSuccessMessage
  } = useSelector(state => state.companyAdmin);

  useEffect(() => {
    dispatch(companyAdminActions.getCompanyActiveAdmins(location.state.companyId));
    dispatch(companyAdminActions.getCompanyInactiveAdmins(location.state.companyId));
  }, [location, dispatch]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
  }, [dispatch, companies]);

  useEffect(() => {
    if (companyAdminCreated) {
      message.success(createCompanyAdminSuccessMessage);
      dispatch(companyAdminActions.resetCreatedCompanyAdminData());
    }
  }, [companyAdminCreated, createCompanyAdminSuccessMessage, dispatch]);

  useEffect(() => {
    if (companyAdminUpdated) {
      message.success(updateCompanyAdminSuccessMessage);
      dispatch(companyAdminActions.resetUpdatedCompanyAdminData());
    }
  }, [companyAdminUpdated, updateCompanyAdminSuccessMessage, dispatch]);

  const company = _.find(companies, x => String(x.id) === String(state.companyId));

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleCompanyAdminClick}>Company Admins</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="title">{company && company.name}</span>
        </p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column">
            <h2 className="heading-link" onClick={handleCompanyNameClick}>
              Filtered by: {company && company.name}
            </h2>
            <button className="secondary-button secondary-button-margin-md" onClick={handleAddAdminClick}>Add Admin</button>
          </div>
          <Divider className="gray-divider" />
          <div className="d-flex flex-column">
            <h2 className="heading heading-area">Active Company Admins</h2>
            <span className="sub-heading heading-area">Click on a company admin to edit them.</span>
          </div>
          <div className="row">
            {renderCompanyActiveAdmins()}
          </div>
          <Divider className="gray-divider mt-0" />
          <div className="d-flex flex-column">
            <h2 className="heading heading-area">Inactive Company Admins</h2>
          </div>
          <div className="row">
            {renderCompanyInactiveAdmins()}
          </div>
        </div>
      </div>
    </>
  );

  function handleCompanyNameClick() {
    history.push({
      pathname: `/company-admins`,
      state: state
    });
  }

  function renderCompanyActiveAdmins() {
    if (companyActiveAdmins && companyActiveAdmins.length > 0) {
      return companyActiveAdmins.map((admin, index) => {
        return <div
          key={index}
          className="col-md-4 col-sm-6 col-12 company-admin-link-container"
          onClick={() => handleCompanyAdminDetailsClick(admin)}>
          <span className="text-link">{admin.admin_name}</span>
          {admin.is_point_of_contact && <span className="text-span-black">&nbsp;(Point of Contact)</span>}
        </div>
      })
    } else {
      return <div className="col-md-4 col-sm-6 col-12 company-admin-link-container">
        <span className="text-span-black">None</span>
      </div>;
    }
  }

  function renderCompanyInactiveAdmins() {
    if (companyInactiveAdmins && companyInactiveAdmins.length > 0) {
      return companyInactiveAdmins.map((admin, index) => {
        return <div
          key={index}
          className="col-md-4 col-sm-6 col-12 company-admin-link-container"
          onClick={() => handleCompanyAdminDetailsClick(admin)}>
        <span className="text-link">{admin.admin_name}</span>
        </div>
      });
    } else {
      return <div className="col-md-4 col-sm-6 col-12 company-admin-link-container">
        <span className="text-span-black">None</span>
      </div>;
    }
  }

  function handleCompanyAdminDetailsClick(admin) {
    dispatch(companyAdminActions.resetCreatedCompanyAdminData());
    dispatch(companyAdminActions.resetUpdatedCompanyAdminData());

    let locationState = {
      state: state,
      admin: admin
    }
    let adminName = admin.admin_name.replace(/\s+/g, '-').toLowerCase();
    history.push({
      pathname: `/company-admins/admin/update/${adminName}`,
      state: locationState
    });
  }

  function handleCompanyAdminClick() {
    history.push('/company-admins');
  }

  function handleAddAdminClick() {
    dispatch(companyAdminActions.resetCreatedCompanyAdminData());
    dispatch(companyAdminActions.resetUpdatedCompanyAdminData());

    let locationState = {
      state: state
    }
    history.push({
      pathname: '/company-admins/admin/add',
      state: locationState
    });
  }
}
