import axios from 'axios';
import { ROOT_URL } from './rootUrl';

const getCountries = () => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/location/countries/`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getStates = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/location/states/?countries=${data.country}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getCities = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/location/cities/?countries=${data.country}&states=${data.state}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

export const locationService = {
  getCountries,
  getStates,
  getCities
}