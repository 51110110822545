/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { message, Divider } from 'antd';
import { scanUsersAction, companyAction } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as _ from 'lodash';
import './style.scss'


export default function CompanyScanUserDetails() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const {
    companyActiveScanUsers,
    companyInactiveScanUsers,
    companyScanUserCreated,
    createCompanyScanUserSuccessMessage,
    companyScanUserUpdated,
    updateCompanyScanUserSuccessMessage
  } = useSelector(state => state.scanUsers);

  const [state] = useState(location.state);

  const { companies } = useSelector(state => state.companies);
  const { activeHubs } = useSelector(state => state.hubs);

  useEffect(() => {
    dispatch(scanUsersAction.getCompanyScanUsers(params));
    dispatch(scanUsersAction.getCompanyInactiveScanUsers(params));
  }, [params, dispatch])

  useEffect(() => {
    if (companyScanUserCreated) {
      message.success(createCompanyScanUserSuccessMessage);
      dispatch(scanUsersAction.resetCreatedCompanyScanUserData());
    }
  }, [companyScanUserCreated, createCompanyScanUserSuccessMessage, dispatch]);

  useEffect(() => {
    if (companyScanUserUpdated) {
      message.success(updateCompanyScanUserSuccessMessage);
      dispatch(scanUsersAction.resetUpdatedCompanyScanUserData());
    }
  }, [companyScanUserUpdated, updateCompanyScanUserSuccessMessage, dispatch]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
  }, [companies, activeHubs, params.companyId, dispatch]);

  const company = _.find(companies, x => String(x.id) === params.companyId);
  // const hub = _.find(companyActiveHubs, x => x.hub_id === state.hubId);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleScanUserClick}>Scan Users</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className='title'>{company && company.name}</span>
        </p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column">
            <h2
              className="heading-link"
              onClick={handleCompanyNameClick}>
              Company: {company && company.name}
            </h2>
            <button className="secondary-button secondary-button-margin-md" onClick={handleAddScanUserClick}>Add Scan User</button>
          </div>
          <Divider className="gray-divider" />
          <div className="d-flex flex-column heading-area">
            <h2 className="heading heading-area">Active Scan Users</h2>
            <span className="sub-heading">Click on a scan user to edit them.</span>
          </div>
          <div className="row">
            {renderActiveUsers()}
          </div>
          {companyInactiveScanUsers && companyInactiveScanUsers.length > 0 &&
            <React.Fragment>
              <Divider className="gray-divider mt-0" />
                <div className="d-flex flex-column">
                  <h2 className="heading heading-area">Inactive Scan Users</h2>
                </div>
                <div className="row">
                  {renderInactiveUsers()}
                </div>
            </React.Fragment>
          }
        </div>
      </div>
    </>
  )

  function handleCompanyNameClick() {
    history.push({
      pathname: `/scan-users`,
      state: {
        companyId: params.companyId
      }
    })
  }

  function renderActiveUsers() {
    return companyActiveScanUsers.map((user, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 scan-user-link-container"
        onClick={() => handleScanUserDetailsClick(user)}>
        <span className="text-link">{user.hub_scanner_name}</span>
      </div>
    })
  }

  function renderInactiveUsers() {
    return companyInactiveScanUsers.map((user, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 scan-user-link-container"
        onClick={() => handleScanUserDetailsClick(user)}>
        <span className="text-link">{user.hub_scanner_name}</span>
      </div>
    })
  }

  function handleScanUserDetailsClick(user) {
    dispatch(scanUsersAction.resetCreatedCompanyScanUserData());
    dispatch(scanUsersAction.resetUpdatedCompanyScanUserData());
    history.push(`/scan-users/company/user/update/${user.hub_scanner_name.toLowerCase()}/${params.companyId}/${user.hub_scanner_id}`);
  }

  function handleScanUserClick() {
    history.push('/scan-users');
  }

  function handleAddScanUserClick() {
    dispatch(scanUsersAction.resetCreatedCompanyScanUserData());
    dispatch(scanUsersAction.resetUpdatedCompanyScanUserData());
    history.push(`/scan-users/company/user/add/new/${params.companyId}`)
  }
}
