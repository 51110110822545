import React, { useState } from 'react';
import Sidebar from '../sidebar/sidebar';
import logo from '../../assets/images/logo.png';
import './style.scss';

const Nav = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => setShowSidebar(!showSidebar)

  return (
    <div className="top-nav">
      <i className="fa fa-bars text-white fa-lg pointer" onClick={toggleSidebar}></i>
      <div className="logo_container">
        <img src={logo} alt="janio logo" />
        <span>Hub Control Tower</span>
      </div>
      <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
    </div>
  )
}

export default Nav;