import React, { useEffect, useState } from "react";
import { getHubPackagesData } from "../../../actions";
import { arrayToString } from "../../../utils/arrayToString";
import { formatDecimalToDayString } from "../../../utils/dateFormatters";
import { mapStringToArray } from "../../../utils/mapStringToArray";
import { paginateArrayElements } from "../../../utils/pagination";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Menu, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SortColumnHeader from "../../../components/sortColumnHeader/sortColumnHeader";
import TextArea from "../../../components/textArea";
import moment from "moment";

export default function HubPackages(props) {
  const dispatch = useDispatch();
  const { packagesData, packagesDataLoading } = useSelector(
    (state) => state.hubPackages
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [hubPackagesData, setHubPackagesData] = useState([]);
  const [packagesCompleteData, setPackagesCompleteData] = useState([]);
  const [locationSortBy, setLocationSortBy] = useState(null);
  const [exceptionSortBy, setExceptionSortBy] = useState(null);
  const [filteredTrackingNumbers, setFilteredTrackingNumbers] = useState("");

  const [menuVisible, setMenuVisible] = useState({
    trackingCodeVisible: false,
  });

  const onPageChange = (page) => {
    setCurrentPage(page);
    setHubPackagesData(
      paginateArrayElements(packagesCompleteData, props.pageSize, page)
    );
  };

  useEffect(() => {
    setHubPackagesData([]);
    setPackagesCompleteData([]);
    setCurrentPage(1);
    dispatch(
      getHubPackagesData({
        hub_id: props.hubId,
        page_size: 100,
        page_number: 1,
      })
    );
  }, [props.hubId, props.pageSize]);

  useEffect(() => {
    if (packagesData) {
      console.log(packagesData.length, props.pageSize, currentPage);
      setHubPackagesData(
        paginateArrayElements(packagesData, props.pageSize, currentPage)
      );
      setPackagesCompleteData(packagesData);
    }
  }, [currentPage, packagesData]);

  const onLocationSortHandler = () => {
    if (locationSortBy === null || locationSortBy === "desc") {
      setLocationSortBy("asc");
      dispatch(
        getHubPackagesData({
          hub_id: props.hubId,
          page_size: props.pageSize,
          page_number: 1,
          sort_by_location: "asc",
          tracking_no_in: filteredTrackingNumbers.includes(",")
            ? mapStringToArray(filteredTrackingNumbers, ",")
            : mapStringToArray("\n"),
        })
      );
      return;
    }
    setLocationSortBy("desc");
    dispatch(
      getHubPackagesData({
        hub_id: props.hubId,
        page_size: props.pageSize,
        page_number: 1,
        sort_by_location: "desc",
        tracking_no_in: filteredTrackingNumbers.includes(",")
          ? mapStringToArray(filteredTrackingNumbers, ",")
          : mapStringToArray("\n"),
      })
    );
  };

  const onExceptionSortHandler = () => {
    if (exceptionSortBy === null || exceptionSortBy === "desc") {
      setExceptionSortBy("asc");
      dispatch(
        getHubPackagesData({
          hub_id: props.hubId,
          page_size: props.pageSize,
          page_number: 1,
          sort_by_exception: "asc",
          tracking_no_in: filteredTrackingNumbers.includes(",")
            ? mapStringToArray(filteredTrackingNumbers, ",")
            : mapStringToArray("\n"),
        })
      );
      return;
    }
    setExceptionSortBy("desc");
    dispatch(
      getHubPackagesData({
        hub_id: props.hubId,
        page_size: props.pageSize,
        page_number: 1,
        sort_by_exception: "desc",
        tracking_no_in: filteredTrackingNumbers.includes(",")
          ? mapStringToArray(filteredTrackingNumbers, ",")
          : mapStringToArray("\n"),
      })
    );
  };

  const filteredTrackingNumbersInputHandler = (e) => {
    setFilteredTrackingNumbers(e.target.value);
  };

  const resetTrackingNumberFilter = () => {
    setFilteredTrackingNumbers("");
  };

  const filterByTrackingNumbers = () => {
    dispatch(
      getHubPackagesData({
        hub_id: props.hubId,
        page_size: props.pageSize,
        page_number: 1,
        tracking_no_in: filteredTrackingNumbers.includes(",")
          ? mapStringToArray(filteredTrackingNumbers, ",")
          : mapStringToArray(filteredTrackingNumbers, "\n"),
      })
    );
  };

  return (
    <>
      <div role="table" aria-label="Package Details Table">
        <div className="table-responsive table-fixed">
          <table className="table table-striped table-borderless packages-table">
            <thead className="table-header--bottom-border">
              <tr>
                <th>
                  {/* <span className="cursor-pointer mr-2">Tracking Number</span> */}
                  <Dropdown
                    overlay={trackingNumberFilterMenu}
                    trigger={["click"]}
                    placement="bottomRight"
                    overlayClassName="hms-dropdown-menu"
                    onVisibleChange={() =>
                      handleVisibleChange("trackingCodeVisible")
                    }
                    visible={menuVisible.trackingCodeVisible}
                  >
                    <div className="d-flex flex-nowrap">
                      <span className="cursor-pointer">
                        Tracking Number
                        <i
                          className="fa fa-filter fnt-14 cursor-pointer d-inilne-block ml-2"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </Dropdown>
                  {/* <PopOver
                    icon={<i className="fa fa-filter fnt-14 cursor-pointer d-inilne-block" aria-hidden="true"></i>}
                  >
                    <div className="mb-2 fnt-16">
                      Filter tracking code
                      </div>
                    <div>
                      <textarea rows="5" placeholder="Type here" className="w-100" onChange={filteredTrackingNumbersInputHandler} value={filteredTrackingNumbers} />
                    </div>
                    <div>
                      You may separate the tracking numbers by commas or by directly copying them from a spreadsheet column
                      </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div onClick={resetTrackingNumberFilter} className="popover-reset fnt-16">Reset</div>
                      <button className="secondary-button" onClick={filterByTrackingNumbers}>
                        Filter
                      </button>
                    </div>
                  </PopOver> */}
                </th>
                <th>
                  <SortColumnHeader
                    callBack={() => onLocationSortHandler()}
                    headerText="Location"
                  />
                </th>
                <th>
                  <span className="cursor-pointer">Date Inbounded</span>
                </th>
                <th>
                  <span className="cursor-pointer">Date Outbounded</span>
                </th>
                <th>
                  <span className="cursor-pointer">Has Outbounded</span>
                </th>
                <th>
                  <span className="cursor-pointer">Days in Hub(Aging)</span>
                </th>
                <th>
                  <span className="cursor-pointer">Has Exception</span>
                </th>
                <th>
                  <SortColumnHeader
                    callBack={() => onExceptionSortHandler()}
                    headerText="Exception Type"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {packagesDataLoading && (
                <tr>
                  <td colSpan="8" className="text-center">
                    <LoadingOutlined className="loading-spinner" />
                  </td>
                </tr>
              )}
              {!packagesDataLoading &&
                hubPackagesData &&
                hubPackagesData.length > 0 &&
                hubPackagesData.map((row, index) => {
                  return (
                    <tr key={`${row.tracking_no}_${index}`}>
                      <td className="text-span-black">{row.tracking_no}</td>
                      <td className="text-span-black">
                        {row.location ? row.location : "-"}
                      </td>
                      <td className="text-span-black">
                        {moment(row.last_inbounded).format("DD MMM YYYY")}
                      </td>
                      <td className="text-span-black">
                        {row.last_outbounded
                          ? moment(row.last_outbounded).format("DD MMM YY")
                          : "-"}
                      </td>
                      <td className="text-span-black">
                        {row.in_hub ? "No" : "Yes"}
                      </td>
                      <td className="text-span-black">
                        {formatDecimalToDayString(row.ageing, "day")}
                      </td>
                      <td className="text-span-black">
                        {row.exceptions.length > 0 ? "Yes" : "No"}
                      </td>
                      <td className="text-span-black">
                        {arrayToString(row.exceptions)}
                      </td>
                    </tr>
                  );
                })}
              {!packagesDataLoading && hubPackagesData.length === 0 && (
                <tr>
                  <td colSpan="8" className="text-center standard-font">
                    No entry found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="info-text mt-20">
          <i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>
          <span className="standard-font-gray">
            Showing {props.pageSize} most recent tracking numbers scanned.
            Filter to see all filtered tracking numbers
          </span>
        </div>
        {packagesCompleteData && packagesCompleteData.length > 0 && (
          <div className="mt-3 text-center">
            <Pagination
              current={currentPage}
              defaultPageSize={props.pageSize}
              total={packagesData.length}
              onChange={onPageChange}
              className="mini packages-table-antd-pagination"
            />
          </div>
        )}
      </div>
    </>
  );

  function handleVisibleChange(name) {
    setMenuVisible({
      ...menuVisible,
      [name]: !menuVisible[name],
    });
  }

  function trackingNumberFilterMenu() {
    return (
      <Menu className="hms-menu-item">
        <Menu.Item key="0">
          <div className="p-10">
            <TextArea
              rows={3}
              className="w-100"
              label="Filter tracking code"
              placeholder="Type here"
              value={filteredTrackingNumbers}
              onChange={filteredTrackingNumbersInputHandler}
            />
            <div className="standard-font mt-10">
              You may separate the tracking numbers by commas or by directly
              copying them from a spreadsheet column.
            </div>
            <div className="d-flex align-items-center justify-content-between mt-20">
              <div
                onClick={resetTrackingNumberFilter}
                className="popover-reset"
              >
                Reset
              </div>
              <button
                className="secondary-button"
                onClick={filterByTrackingNumbers}
              >
                Filter
              </button>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  }
}
