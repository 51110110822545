import { hubManagersTypes } from '../types';
import { hubManagersService } from '../services';

const createCompanyManager = (data) => {
  const loading = () => ({ type: hubManagersTypes.CREATE_COMPANY_MANAGER_LOADING })
  const success = (payload) => ({ type: hubManagersTypes.CREATE_COMPANY_MANAGER_SUCCESS, payload })
  const failure = (payload) => ({ type: hubManagersTypes.CREATE_COMPANY_MANAGER_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await hubManagersService.createCompanyManager(data);
      let successMessage = 'Hub Manager has been added';
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetCreatedCompanyManagerData = () => {
  return function (dispatch) {
    dispatch({ type: hubManagersTypes.RESET_CREATED_COMPANY_MANAGER_DATA });
  }
}

const updateCompanyManager = (data) => {
  const loading = () => ({ type: hubManagersTypes.UPDATE_COMPANY_MANAGER_LOADING })
  const success = (payload) => ({ type: hubManagersTypes.UPDATE_COMPANY_MANAGER_SUCCESS, payload })
  const failure = (payload) => ({ type: hubManagersTypes.UPDATE_COMPANY_MANAGER_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await hubManagersService.updateCompanyManager(data);
      let successMessage = 'Company manager has been updated';
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetUpdatedCompanyManagerData = () => {
  return function (dispatch) {
    dispatch({ type: hubManagersTypes.RESET_UPDATED_COMPANY_MANAGER_DATA });
  }
}

const getCompanyActiveManagers = (data) => {
  const loading = () => ({ type: hubManagersTypes.FETCH_COMPANY_ACTIVE_MANAGERS_LOADING })
  const success = (payload) => ({ type: hubManagersTypes.FETCH_COMPANY_ACTIVE_MANAGERS_SUCCESS, payload })
  const failure = (payload) => ({ type: hubManagersTypes.FETCH_COMPANY_ACTIVE_MANAGERS_FAILURE, payload })
  return async function (dispatch) {
    loading()
    try {
      const result = await hubManagersService.getCompanyActiveManagers(data);
      dispatch(success(result.data))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const getCompanyInactiveManagers = (data) => {
  const loading = () => ({ type: hubManagersTypes.FETCH_COMPANY_INACTIVE_MANAGERS_LOADING })
  const success = (payload) => ({ type: hubManagersTypes.FETCH_COMPANY_INACTIVE_MANAGERS_SUCCESS, payload })
  const failure = (payload) => ({ type: hubManagersTypes.FETCH_COMPANY_INACTIVE_MANAGERS_FAILURE, payload })
  return async function (dispatch) {
    loading()
    try {
      const result = await hubManagersService.getCompanyInactiveManagers(data);
      dispatch(success(result.data))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const createHubManager = (data) => {
  const loading = () => ({ type: hubManagersTypes.CREATE_HUB_MANAGER_LOADING })
  const success = (payload) => ({ type: hubManagersTypes.CREATE_HUB_MANAGER_SUCCESS, payload })
  const failure = (payload) => ({ type: hubManagersTypes.CREATE_HUB_MANAGER_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await hubManagersService.createHubManager(data);
      let successMessage = 'Hub Manager has been added';
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetCreatedHubManagerData = () => {
  return function (dispatch) {
    dispatch({ type: hubManagersTypes.RESET_CREATED_HUB_MANAGER_DATA });
  }
}

const updateHubManager = (data) => {
  const loading = () => ({ type: hubManagersTypes.UPDATE_HUB_MANAGER_LOADING })
  const success = (payload) => ({ type: hubManagersTypes.UPDATE_HUB_MANAGER_SUCCESS, payload })
  const failure = (payload) => ({ type: hubManagersTypes.UPDATE_HUB_MANAGER_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await hubManagersService.updateHubManager(data);
      let successMessage = 'Hub Manager has been updated';
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetUpdatedHubManagerData = () => {
  return function (dispatch) {
    dispatch({ type: hubManagersTypes.RESET_UPDATED_HUB_MANAGER_DATA });
  }
}

const getHubActiveManagers = (data) => {
  const loading = () => ({ type: hubManagersTypes.FETCH_HUB_ACTIVE_MANAGERS_LOADING })
  const success = (payload) => ({ type: hubManagersTypes.FETCH_HUB_ACTIVE_MANAGERS_SUCCESS, payload })
  const failure = (payload) => ({ type: hubManagersTypes.FETCH_HUB_ACTIVE_MANAGERS_FAILURE, payload })
  return async function (dispatch) {
    loading()
    try {
      const result = await hubManagersService.getHubActiveManagers(data);
      dispatch(success(result.data))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const getHubInactiveManagers = (data) => {
  const loading = () => ({ type: hubManagersTypes.FETCH_HUB_INACTIVE_MANAGERS_LOADING })
  const success = (payload) => ({ type: hubManagersTypes.FETCH_HUB_INACTIVE_MANAGERS_SUCCESS, payload })
  const failure = (payload) => ({ type: hubManagersTypes.FETCH_HUB_INACTIVE_MANAGERS_FAILURE, payload })
  return async function (dispatch) {
    loading()
    try {
      const result = await hubManagersService.getHubInactiveManagers(data);
      dispatch(success(result.data))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

export const hubManagersAction = {
  createCompanyManager,
  resetCreatedCompanyManagerData,
  updateCompanyManager,
  resetUpdatedCompanyManagerData,
  getCompanyActiveManagers,
  getCompanyInactiveManagers,
  createHubManager,
  resetCreatedHubManagerData,
  updateHubManager,
  resetUpdatedHubManagerData,
  getHubActiveManagers,
  getHubInactiveManagers
}