export const companyAdminTypes = {
  FETCH_COMPANY_ACTIVE_ADMINS_LOADING: 'FETCH_COMPANY_ACTIVE_ADMINS_LOADING',
  FETCH_COMPANY_ACTIVE_ADMINS_SUCCESS: 'FETCH_COMPANY_ACTIVE_ADMINS_SUCCESS',
  FETCH_COMPANY_ACTIVE_ADMINS_FAILURE: 'FETCH_COMPANY_ACTIVE_ADMINS_FAILURE',

  FETCH_COMPANY_INACTIVE_ADMINS_LOADING: 'FETCH_COMPANY_INACTIVE_ADMINS_LOADING',
  FETCH_COMPANY_INACTIVE_ADMINS_SUCCESS: 'FETCH_COMPANY_INACTIVE_ADMINS_SUCCESS',
  FETCH_COMPANY_INACTIVE_ADMINS_FAILURE: 'FETCH_COMPANY_INACTIVE_ADMINS_FAILURE',

  CREATE_COMPANY_ADMIN_LOADING: 'CREATE_COMPANY_ADMIN_LOADING',
  CREATE_COMPANY_ADMIN_SUCCESS: 'CREATE_COMPANY_ADMIN_SUCCESS',
  CREATE_COMPANY_ADMIN_FAILURE: 'CREATE_COMPANY_ADMIN_FAILURE',
  RESET_CREATED_COMPANY_ADMIN_DATA: 'RESET_CREATED_COMPANY_ADMIN_DATA',

  UPDATE_COMPANY_ADMIN_LOADING: 'UPDATE_COMPANY_ADMIN_LOADING',
  UPDATE_COMPANY_ADMIN_SUCCESS: 'UPDATE_COMPANY_ADMIN_SUCCESS',
  UPDATE_COMPANY_ADMIN_FAILURE: 'UPDATE_COMPANY_ADMIN_FAILURE',
  RESET_UPDATED_COMPANY_ADMIN_DATA: 'RESET_UPDATED_COMPANY_ADMIN_DATA'
}