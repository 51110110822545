/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { companyAction, hubsAction, scanTypesAction } from "../../../actions";
import * as _ from "lodash";
import "./style.scss";

export default function HubDetails(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [state] = useState(props.state);
  const { activeHubs } = useSelector((state) => state.hubs);
  const { companies } = useSelector((state) => state.companies);
  const { scanTypes } = useSelector((state) => state.scanTypes);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    }
  }, [dispatch, companies]);

  useEffect(() => {
    if (activeHubs && activeHubs.length === 0) {
      dispatch(hubsAction.getActiveHubs(state.companyId));
    }
  }, [dispatch, activeHubs, state.companyId]);

  useEffect(() => {
    if (scanTypes && scanTypes.length === 0) {
      dispatch(scanTypesAction.getActiveScanTypes(state));
    }
  }, [dispatch, state.companyId]);

  const company = _.find(companies, (x) => String(x.id) === state.companyId);
  const hub = _.find(activeHubs, (x) => String(x.hub_id) === state.hubId);

  return (
    <>
      <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column margin-bottom-30">
        <h2 className="heading">Hub Information</h2>
      </div>
      <div className="company-information">
        <div className="row">
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-md-0">
            <h4 className="label-text">Hub Name</h4>
            <span className="text-span-black">{hub && hub.hub_name}</span>
          </div>
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
            <h4 className="label-text">Hub ID</h4>
            <span className="text-span-black">{hub && hub.hub_id}</span>
          </div>
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
            <h4 className="label-text">Hub Type</h4>
            <span className="text-span-black">{hub && hub.type}</span>
          </div>
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30">
            <h4 className="label-text">Hub Managers</h4>
            <span className="text-link" onClick={gotoHubManagers}>
              Link
            </span>
          </div>
          <div className="col-md-3 col-sm-4 col-12">
            <h4 className="label-text">Scan Users</h4>
            <span className="text-link" onClick={gotoHubScanUsers}>
              Link
            </span>
          </div>
          <div className="col-12">
            <Divider className="gray-divider-29" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 margin-bottom-30">
            <h2 className="heading">Hub Address</h2>
          </div>
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30">
            <h4 className="label-text">Country</h4>
            <span className="text-span-black">{hub && hub.country}</span>
          </div>
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
            <h4 className="label-text">State/Province</h4>
            <span className="text-span-black">{hub && hub.state}</span>
          </div>
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
            <h4 className="label-text">City</h4>
            <span className="text-span-black">{hub && hub.city}</span>
          </div>
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
            <h4 className="label-text">Address</h4>
            <span className="text-span-black">{hub && hub.address}</span>
          </div>
          <div className="col-md-3 col-sm-4 col-12">
            <h4 className="label-text">Postal Code</h4>
            <span className="text-span-black">{hub && hub.postal_code}</span>
          </div>
          <div className="col-12">
            <Divider className="gray-divider" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 margin-bottom-30">
            <h2 className="heading">Hub Contact</h2>
          </div>
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
            <h4 className="label-text">Name</h4>
            <span className="text-span-black"></span>
          </div>
          <div className="col-md-3 col-sm-4 col-12 margin-bottom-30 mb-sm-0">
            <h4 className="label-text">Phone Number</h4>
            <span className="text-span-black"></span>
          </div>
          <div className="col-md-3 col-sm-4 col-12">
            <h4 className="label-text">Email</h4>
            <span className="text-span-black"></span>
          </div>
          <div className="col-12">
            <Divider className="gray-divider" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 margin-bottom-30">
            <div className="d-flex flex-column">
              <h2 className="heading">Hub Scan Type Permissions</h2>
              <span className="sub-heading sub-heading-margin">Click on a scan type to edit it.</span>
            </div>
          </div>
          {renderActiveTypes()}
          <div className="col-12">
            <Divider className="gray-divider mb-0" />
          </div>
        </div>
      </div>
    </>
  );

  function renderActiveTypes() {
    return scanTypes.map((type, index) => {
      return (
        <div key={index} className="col-md-4 col-sm-6 col-12 scan-type-link-container" onClick={() => handleHubTypeDetailsClick(type)}>
          <span className="text-link">{type.scan_type_name}</span>
        </div>
      );
    });
  }

  function handleHubTypeDetailsClick(type) {
    let typeName = type.scan_type_name.replace(/\s+/g, "-").toLowerCase();
    history.push(`/scan-types/type/update/${typeName}/${state.companyId}/${type.id}`);
  }

  function gotoHubManagers() {
    const hubName = hub.hub_name.replace(/\s+/g, "-").toLowerCase();
    history.push(`/hub-managers/${company.name.toLowerCase()}/${hubName}/${state.companyId}/${state.hubId}`);
  }

  function gotoHubScanUsers() {
    const hubName = hub.hub_name.replace(/\s+/g, "-").toLowerCase();
    history.push(`/scan-users/${company.name.toLowerCase()}/${hubName}/${company.id}/${hub.hub_id}`);
  }
}
