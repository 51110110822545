/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Divider, Modal, message } from 'antd';
import { toolsAction } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';
import CustomInput from '../../../components/input/input';
import CustomSelect from '../../../components/select/select';
import TextArea from '../../../components/textArea';
import * as _ from 'lodash';
import './style.scss';

export default function AddBulkException() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    addBulkExceptionSuccess,
    addBulkExceptionError
  } = useSelector(state => state.tools);

  const [state, setState] = useState({
    exception_type: undefined,
    exception_description: '',
    tracking_numbers: undefined,
  });

  const [showAddExceptionModal, setShowAddExceptionModal] = useState(false);

  const trackingNumbers = getTrackingNumbers();

  useEffect(() => {
    if (addBulkExceptionSuccess) {
      setShowAddExceptionModal(false);
      message.success(`Exception is added to ${trackingNumbers.length} parcels`);
      history.goBack();
    };
  }, [addBulkExceptionSuccess]);

  const exceptionTypes = [
    {
      "label": "CPI - Contains Prohibited Items",
      "value": "CPI",
    },
    {
      "label": "DMGP - Damaged",
      "value": "DMGP",
    },
    {
      "label": "DOP - Disposal of Parcel",
      "value": "DOP"
    },
    {
      "label": "DUP - Duplicate",
      "value": "DUP"
    },
    {
      "label": "FMAX - Failed Delivery (Maximum Attempts)",
      "value": "FMAX"
    },
    {
      "label": "FOCV - Failed Delivery (Out of Coverage)",
      "value": "FOCV"
    },
    {
      "label": "MSRT - Delivery Not Attempted (Missorting)",
      "value": "MSRT"
    },
    {
      "label": "NDP - No Data",
      "value": "NDP"
    },
    {
      "label": "NLP - No Label",
      "value": "NLP"
    },
    {
      "label": "ONH - On-Hold",
      "value": "ONH"
    },
    {
      "label": "OTH - Others",
      "value": "OTH"
    },
    {
      "label": "PDA - Failed Delivery (Consignee Wrong Address)",
      "value": "PDA"
    },
    {
      "label": "PDC - Failed Delivery (Consignee Unavailable)",
      "value": "PDC"
    },
    {
      "label": "PTAX - Pending Tax Documents",
      "value": "PTAX"
    },
    {
      "label": "RDP - Reschedule Delivery",
      "value": "RDP"
    },
    {
      "label": "RESC - Consignee Request Reschedule",
      "value": "RESC"
    },
    {
      "label": "RJP - Failed Delivery (Consignee Rejected)",
      "value": "RJP"
    },
    {
      "label": "RTS - Returns to Client",
      "value": "RTS"
    }
  ];

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={goToBulkException}>Bulk Exceptions</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="title">Add Exception in Bulk</span>
        </p>
        <div className="bg-container-body">
          <Form form={form} onFinish={onClickConfirmButton} initialValues={state}>
            <div className="d-flex heading-area">
              <h2 className="heading">Exception</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="exception_type"
                  className="mb-sm-0"
                  rules={[{ type: 'string', required: true, message: 'Please select an exception type first' }]}>
                  <CustomSelect
                    label="Type"
                    active={state.exception_type}
                    placeholder="Please select exception type"
                    onChange={(value) => onSelectionChange('exception_type', value)}
                    options={exceptionTypes}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="exception_description"
                  className="mb-sm-0"
                  rules={[{ type: 'string', required: true, message: 'Please type description first' }]}>
                  <CustomInput
                    label="Description"
                    name="exception_description"
                    onChange={handleChange}
                    placeholder="Tell us more about this parcel issue"
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
            </div>
            <div className="d-flex heading-area">
              <h2 className="heading">Tracking Numbers with Exception</h2>
            </div>
            <div className="row">
              <div className="col-12 mb-1">
                <Form.Item
                  name="included_scan_types"
                  className="mb-0"
                  rules={[{ type: 'string', required: true, message: 'Please type or paste tracking numbers' }]}>
                  <TextArea
                    rows={3}
                    className="w-100"
                    label="Parcel Tracking Numbers"
                    placeholder="Type here (you may separate the tracking numbers by commas or by directly copying them from a spreadsheet column)"
                    name="tracking_numbers"
                    value={state.tracking_numbers}
                    onChange={handleChange}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="blue-divider" />
              </div>
            </div>
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button className="secondary-button" onClick={goToBulkException}>Cancel</button>
              <button type="submit" className="primary-button primary-button-margin-bottom-sm">Add Exception</button>
            </div>
          </Form>
        </div>
      </div>
      <Modal
        className="generate-csv-modal"
        visible={showAddExceptionModal}
        footer={null}
        okText="Confirm"
        okButtonProps={{ className: "genereate-csv-modal-primary-button" }}
        cancelButtonProps={{ className: "generate-csv-modal-secondary-button" }}
        cancelText="Cancel"
      >
        <div className="text-left">
          <h2 className="generate-csv-heading mb-0">
            Add Exception to {trackingNumbers.length} Parcels
          </h2>
          <p className="generate-csv-paragraph">
            Are you sure you want to add this exception to {trackingNumbers.length} Parcels? <br />
            This cannot be undone.
          </p>
          <Divider className="gray-divider" />
          <div className="d-flex justify-content-between">
            <button
              className="generate-csv-modal-secondary-button"
              onClick={toggleModal}
            >
              Cancel
            </button>
            <button
              className="generate-csv-modal-primary-button"
              onClick={onAddExceptionButton}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </>
  );

  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value
    })
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    })
  }

  function toggleModal() {
    setShowAddExceptionModal(!showAddExceptionModal);
  }

  function onClickConfirmButton() {
    setShowAddExceptionModal(true);
  };

  function getTrackingNumbers() {
    if (state.tracking_numbers) {
      const separator = state.tracking_numbers.includes(',') ? ',' : state.tracking_numbers.includes(' ') ? ' ' : '\n';
      const trackingNumbersToSend = state.tracking_numbers ? state.tracking_numbers.split(separator) : [];
      return trackingNumbersToSend;
    };
    return [];
  }

  function onAddExceptionButton() {
    const data = {
      "exception_type": state.exception_type,
      "exception_note": state.exception_description,
      "tracking_nos": trackingNumbers
    };
    dispatch(toolsAction.addBulkException(data));
  }

  function goToBulkException() {
    history.push('/tools/bulkException');
  }
}
