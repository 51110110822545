import React, { useState } from "react";
import Checkbox from "react-simple-checkbox";
import logo from "../../assets/images/logo_homepage.png";
import background from "../../assets/images/login_background.png";
import { Button, Divider, Form } from "antd";
import CustomInput from "../../components/input/input";
import { connect } from "react-redux";
import { loginAction } from "../../actions";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import "./styles.scss";

const cookies = new Cookies();

const Login = ({ auth, errorMessage, ...otherProps }) => {
  const token = cookies.get("token");

  const [state, setState] = useState({
    remember_me: false,
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });

    const { dispatch } = otherProps;
    dispatch(loginAction.clearResponse());
  };

  const handleCheckbox = (val) => {
    setState({ ...state, remember_me: !state.remember_me });
  };

  const submit = () => {
    const { dispatch } = otherProps;
    const { email, password, remember_me } = state;
    const data = {
      user_id: email,
      password,
      remember_me,
    };
    dispatch(loginAction.login(data));
  };

  const { isSuccess } = otherProps;
  const errorClass = errorMessage ? "login-input-error" : "";
  return (
    <div className="login_screen">
      {(isSuccess || token) && <Redirect to="/companies" />}
      <img className="login-background" alt="background" src={background} />
      <div className="login-overlay" />
      <div className="login-form">
        <img alt="logo" src={logo} />
        <div className="form">
          <h2>HUB CONTROL TOWER</h2>
          <Divider className="gray-divider" />
          <Form onFinish={submit}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter your email address",
                },
              ]}
            >
              <CustomInput
                label="Username"
                name="email"
                onChange={handleChange}
                placeholder="Enter your Email"
                className={"login-input " + errorClass}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <CustomInput
                label="Password"
                type="password"
                name="password"
                onChange={handleChange}
                placeholder="Enter your Password"
                className={"login-input " + errorClass}
              />
            </Form.Item>
            <div className="check-group">
              <Checkbox
                className="login-checkbox"
                size={2}
                onChange={handleCheckbox}
                borderThickness={1}
                tickAnimationDuration={100}
                tickSize={3}
                checked={state.remember_me}
                color="#080593"
              />
              &nbsp;<span>Remember me</span>
            </div>
            <Divider className="blue-divider" />
            <Form.Item label="" colon={false} className="mb-2">
              <Button
                block
                htmlType="submit"
                className="btn-block btn-primary mb-0"
              >
                Log In
              </Button>
            </Form.Item>
          </Form>
          {errorMessage && (
            <div className="error-message">
              Username or Password is incorrect.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errorMessage: state.auth.errorMessage,
    isSuccess: state.auth.successMessage,
  };
};

export default connect(mapStateToProps)(Login);

