import React from "react";
import { Input } from "antd";
import "./style.scss"

const { TextArea } = Input;

export default function textArea({ label, className, ...otherProps }) {
  return (
    <div className={`custom-text-area ${className}`}>
      {label && <h6 className="form-label">{label}</h6>}
      <TextArea className="input-field" {...otherProps} />
    </div>
  )
}