import { hubTypes } from '../types'

const initialState = {
  createHubLoading: false,
  createHubErrorMessage: null,
  hubCreated: false,
  createHubSuccessMessage: '',

  updateHubLoading: false,
  updateHubErrorMessage: null,
  hubUpdated: false,
  updateHubSuccessMessage: '',

  activeHubsLoading: false,
  activeHubsErrorMessage: null,
  activeHubs: [],

  inactiveHubsLoading: false,
  inactiveHubsErrorMessage: null,
  inactiveHubs: []
}

const hubsReducer = (state = initialState, action) => {
  switch (action.type) {
    case hubTypes.CREATE_HUB_LOADING:
      return { ...state, createHubLoading: true }
    case hubTypes.CREATE_HUB_SUCCESS:
      return { ...state, hubCreated: true, createHubLoading: false, createHubSuccessMessage: action.payload }
    case hubTypes.CREATE_HUB_FAILURE:
      return { ...state, createHubErrorMessage: action.payload, createHubLoading: false }
    case hubTypes.RESET_CREATED_HUB_DATA:
      return { ...state, createHubLoading: false, createHubErrorMessage: null, hubCreated: false, createHubSuccessMessage: '' }

    case hubTypes.UPDATE_HUB_LOADING:
      return { ...state, updateHubLoading: true }
    case hubTypes.UPDATE_HUB_SUCCESS:
      return { ...state, hubUpdated: true, updateHubLoading: false, updateHubSuccessMessage: action.payload }
    case hubTypes.UPDATE_HUB_FAILURE:
      return { ...state, updateHubErrorMessage: action.payload, updateHubLoading: false }
    case hubTypes.RESET_UPDATED_HUB_DATA:
      return { ...state, updateHubLoading: false, updateHubErrorMessage: null, hubUpdated: false, updateHubSuccessMessage: '' }

    case hubTypes.FETCH_ACTIVE_HUBS_LOADING:
      return { ...state, activeHubsLoading: true }
    case hubTypes.FETCH_ACTIVE_HUBS_SUCCESS:
      return { ...state, activeHubs: action.payload, activeHubsLoading: false }
    case hubTypes.FETCH_ACTIVE_HUBS_FAILURE:
      return { ...state, activeHubsErrorMessage: action.payload, activeHubsLoading: false }
    
    case hubTypes.FETCH_INACTIVE_HUBS_LOADING:
      return { ...state, inactiveHubsLoading: true }
    case hubTypes.FETCH_INACTIVE_HUBS_SUCCESS:
      return { ...state, inactiveHubs: action.payload, inactiveHubsLoading: false }
    case hubTypes.FETCH_INACTIVE_HUBS_FAILURE:
      return { ...state, inactiveHubsErrorMessage: action.payload, inactiveHubsLoading: false }
    default:
      return state;
  }
}

export { hubsReducer }