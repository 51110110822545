/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { companyAction, hubsAction, hubManagersAction } from '../../../actions'
import CustomInput from '../../../components/input/input';
import CustomSelect from '../../../components/select/select';
import CustomMultiSelect from '../../../components/multiSelect/multiSelect';
import * as _ from 'lodash';
import './style.scss';


export default function AddHubManager() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({
    hub_id: [],
    display_name: '',
    user_id: '',
    phone_number: '',
    password: '',
    confirm_password: '',
    is_active: true,
    is_point_of_contact: false
  })

  const statusOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const {
    hubActiveManagers,
    hubInactiveManagers,
    hubManagerCreated,
    createHubManagerErrorMessage,
    hubManagerUpdated,
    updateHubManagerErrorMessage
  } = useSelector(state => state.hubManagers);

  const { companies } = useSelector(state => state.companies);
  const { activeHubs } = useSelector(state => state.hubs);

  const company = _.find(companies, x => String(x.id) === params.companyId);
  const hub = _.find(activeHubs, x => String(x.hub_id) === params.hubId);
  const hubManager = getHubManagerData();

  const [addError, setAddError] = useState(createHubManagerErrorMessage)
  const [updateError, setUpdateError] = useState(updateHubManagerErrorMessage)

  useEffect(() => {
    if (!_.isEqual(addError, createHubManagerErrorMessage)) {
      setAddError(createHubManagerErrorMessage)
    }
  }, [createHubManagerErrorMessage])

  useEffect(() => {
    if (!_.isEqual(updateError, updateHubManagerErrorMessage)) {
      setUpdateError(updateHubManagerErrorMessage);
    }
  }, [updateHubManagerErrorMessage]);

  useEffect(() => {
    if (hubManagerCreated) {
      gotoHubManagersPage();
    }
  }, [hubManagerCreated]);

  useEffect(() => {
    if (hubManagerUpdated) {
      gotoHubManagersPage();
    }
  }, [hubManagerUpdated]);

  useEffect(() => {
    if (params.hubManagerId && hubManager) {
      setState({
        ...state,
        'hub_id':[hubManager.hub_id],
        'display_name':hubManager.display_name,
        'user_id':hubManager.user_id,
        'phone_number':hubManager.phone_number,
        'is_active':hubManager.is_active,
        'is_point_of_contact':hubManager.is_point_of_contact
      })

      form.setFieldsValue({
        'hub_id':[hubManager.hub_id],
        'display_name':hubManager.display_name,
        'user_id':hubManager.user_id,
        'phone_number':hubManager.phone_number,
        'is_active':hubManager.is_active,
        'is_point_of_contact':hubManager.is_point_of_contact
      })
    }
  }, [params, hubManager, form]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    }
    if (activeHubs && activeHubs.length === 0) {
      dispatch(hubsAction.getActiveHubs(params.companyId))
    }
  }, [companies, activeHubs, params, dispatch]);

  useEffect(() => {
    if (hubActiveManagers.length === 0 || hubInactiveManagers.length === 0) {
      dispatch(hubManagersAction.getHubActiveManagers(params));
      dispatch(hubManagersAction.getHubInactiveManagers(params));
    };
  }, []);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleManagersClick}>Hub Managers</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="link-title" onClick={gotoCompanyManagersPage}>{company && company.name}</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="link-title" onClick={gotoHubManagersPage}>{hub && hub.hub_name}</span>
          <span className="bread-crumb"> {'>'} </span>
          <span className="title">{breadCrumbText()}</span>
        </p>
        <div className="bg-container-body">
          <Form form={form} onFinish={onAddHubManager} initialValues={state}>
            <div className="d-flex heading-area">
              <h2 className="heading">Hub Manager Information</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="hub_id"
                  rules={[{ type: 'array', required: true, message: 'Please select a hub first' }]}
                  validateStatus={getValidateStatus('hub_id') ? 'error' : 'validating'}
                  help={getError('hub_id')}>
                  <CustomMultiSelect
                    label="Hub(s)"
                    active={state.hub_id}
                    onChange={(value) => onSelectionChange('hub_id', value)}
                    options={getFilteredHubs()}
                    placeholder="Add hub(s)"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="display_name"
                  rules={[{ type: 'string', required: true, message: 'Please type a manager name first' }]}
                  validateStatus={getValidateStatus('display_name') ? 'error' : 'validating'}
                  help={getError('display_name')}>
                  <CustomInput
                    label="Hub Manager Name"
                    name="display_name"
                    onChange={handleChange}
                    value={state.display_name}
                    placeholder="Type here"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="user_id"
                  rules={[{ type: 'string', required: true, message: 'Please type a manager key first' }]}
                  validateStatus={getValidateStatus('user_id') ? 'error' : 'validating'}
                  help={getError('user_id')}>
                  <CustomInput
                    label="Hub Manager Username/Email"
                    name="user_id"
                    onChange={handleChange}
                    value={state.user_id}
                    placeholder="Type here"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="phone_number"
                  className="mb-md-0">
                  <CustomInput
                    label="Hub Manager Contact Number(Optional)"
                    name="phone_number"
                    onChange={handleChange}
                    value={state.phone_number}
                    placeholder="Type here"
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="gray-divider-29" />
              </div>
            </div>

            <div className="d-flex heading-area">
              <h2 className="heading">Options</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="is_active"
                  className="mb-md-0">
                  <CustomSelect
                    label="Is Active"
                    active={state.is_active}
                    onChange={(value) => onSelectionChange('is_active', value)}
                    options={statusOptions}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="is_point_of_contact"
                  className="mb-md-0">
                  <CustomSelect
                    label="Is Hub(s) point of contact"
                    active={state.is_point_of_contact}
                    onChange={(value) => onSelectionChange('is_point_of_contact', value)}
                    options={statusOptions}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
            </div>

            <div className="d-flex heading-area">
              <h2 className="heading">{params.hubManagerId ? 'Change Password' : 'Password'}</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="password"
                  className="mb-md-0"
                  rules={getPasswordRules()}
                  validateStatus={getValidateStatus('password') ? 'error' : 'validating'}
                  help={getError('password')}>
                  <CustomInput
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    value={state.password}
                    placeholder="Type here"
                    type="password"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="confirm_password"
                  className="mb-md-0"
                  rules={getPasswordRules()}
                  validateStatus={getValidateStatus('confirm_password') ? 'error' : 'validating'}
                  help={getError('confirm_password')}>
                  <CustomInput
                    label="Confirm Password"
                    name="confirm_password"
                    onChange={handleChange}
                    value={state.confirm_password}
                    placeholder="Type here"
                    type="password"
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="blue-divider" />
              </div>
            </div>
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button className="secondary-button" onClick={gotoHubManagersPage}>Cancel</button>
              <button type="submit" className="primary-button primary-button-margin-bottom-sm">{getButtonText()}</button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )

  function getHubManagerData() {
    let hubManagerData = _.find(hubActiveManagers, x => String(x.id) === params.hubManagerId);
    if (!hubManagerData) {
      hubManagerData = _.find(hubInactiveManagers, x => String(x.id) === params.hubManagerId);
    }
    return hubManagerData;
  }

  function getPasswordRules() {
    if (params.hubManagerId) {
      return null;
    }
    return [{ type: 'string', required: true, message: 'Please type a password first' }]
  }

  function getValidateStatus(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getError(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getAddError(name) {
    if (addError && addError[name] && addError[name].length > 0) {
      return addError[name][0];
    }
  }

  function getUpdateError(name) {
    if (updateError && updateError[name] && updateError[name].length > 0) {
      return updateError[name][0];
    }
  }

  function breadCrumbText() {
    if (params.hubManagerId) {
      return `Edit ${hubManager && hubManager.display_name}`;
    }
    return 'Add Hub Manager';
  }

  function getButtonText() {
    if (params.hubManagerId) {
      return 'Edit Hub Manager';
    }
    return 'Add Hub Manager';
  }

  function getFilteredHubs() {
    let filteredHubs = [];
    activeHubs.forEach((hub) => {
      hub.label = hub.hub_name;
      hub.value = hub.hub_id;
      filteredHubs.push(hub);
    });
    return filteredHubs
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value
    })
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    })
  }

  function getManagerData() {
    let data = {
      hub_id: state.hub_id.toString(),
      display_name: state.display_name,
      user_id: state.user_id,
      phone_number: state.phone_number,
      is_active: state.is_active,
      password: state.password,
      confirm_password: state.confirm_password,
      is_point_of_contact: state.is_point_of_contact,
      company_id: params.companyId,
      hubId: params.hubId
    }
    return data;
  }

  function onAddHubManager() {
    let data = getManagerData();
    if (params.hubManagerId) {
      data.id = hubManager.id;
      dispatch(hubManagersAction.updateHubManager(data));
      return;
    }
    dispatch(hubManagersAction.createHubManager(data));
  }

  function gotoCompanyManagersPage() {
    history.push(`/hub-managers/${company.name.toLowerCase()}/${params.companyId}`);
  }

  function gotoHubManagersPage() {
    let hubName = hub.hub_name.replace(/\s+/g, '-').toLowerCase();
    history.push(`/hub-managers/${company.name.toLowerCase()}/${hubName}/${params.companyId}/${params.hubId}`);
  }

  function handleManagersClick() {
    history.push('/hub-managers');
  }
}
