import React from "react";
import { Route } from "react-router-dom";
import Nav from "../../components/nav/nav";
import BulkException from "./bulkException";
import AddBulkException from "./bulkException/addBulkException";
import EditResources from "./resourceDefinition/editResources";
import FilterResourceDefinitions from "./resourceDefinition/index";
import ResourceDefinitions from "./resourceDefinition/resourceDefinitions";

export default function BulkExceptions() {
  return (
    <>
      <Nav />
      <Route exact path="/tools/bulkException" component={BulkException} />
      <Route
        exact
        path="/tools/addBulkException"
        component={AddBulkException}
      />
      <Route
        exact
        path="/tools/resource-definitions"
        component={FilterResourceDefinitions}
      />
      <Route
        exact
        path="/tools/resource-definitions/:companyName/:companyId"
        component={ResourceDefinitions}
      />
      <Route
        exact
        path="/tools/resource-definitions/:companyName/:companyId/edit-resources"
        component={EditResources}
      />
    </>
  );
}
