import axios from "axios";
import { ROOT_URL } from "./rootUrl";

const addBulkException = (data) => {
  return axios({
    method: "POST",
    url: `${ROOT_URL}/api/hms/admin/bulk_add_exceptions/`,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const createResourceApi = (data) => {
  return axios({
    method: "POST",
    url: `${ROOT_URL}/api/hms/admin/resource/`,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const updateResourceApi = (data) => {
  return axios({
    method: "PUT",
    url: `${ROOT_URL}/api/hms/admin/resource/`,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const deleteResourceApi = (data) => {
  return axios({
    method: "DELETE",
    url: `${ROOT_URL}/api/hms/admin/resource/`,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const getResourceApi = (params) => {
  return axios({
    method: "GET",
    url: `${ROOT_URL}/api/hms/admin/resource/${params}`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const getResourceTagsApi = (params) => {
  return axios({
    method: "GET",
    url: `${ROOT_URL}/api/hms/admin/resource_tag/${params}`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const createResourceTagApi = (data) => {
  return axios({
    method: "POST",
    url: `${ROOT_URL}/api/hms/admin/resource_tag/`,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const getStandardResourceReportApi = (data) => {
  return axios({
    method: "POST",
    url: `${ROOT_URL}/api/hms/admin/get_resource_usage_report/`,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const getAggregatedResourceReportApi = (data) => {
  return axios({
    method: "POST",
    url: `${ROOT_URL}/api/hms/admin/get_resource_usage_aggregated_report/`,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

export const toolsService = {
  addBulkException,
  createResourceApi,
  updateResourceApi,
  deleteResourceApi,
  getResourceApi,
  getResourceTagsApi,
  createResourceTagApi,
  getStandardResourceReportApi,
  getAggregatedResourceReportApi,
};
