import React from 'react';
import { Route } from 'react-router-dom';
import Nav from '../../components/nav/nav';
import ScanUsersPage from './scanUsersPage/scanUsersPage';
import CompanyScanUserDetails from './companyScanUserDetails/companyScanUserDetails';
import HubScanUserDetails from './hubScanUserDetails/hubScanUserDetails';
import CompanyAddScanUser from './companyAddScanUser/companyAddScanUser';
import HubAddScanuser from './hubAddScanUser/hubAddScanUser'


export default function ScanUsers() {
  return (
    <>
      <Nav />
      <Route exact path="/scan-users" component={ScanUsersPage} />
      <Route exact path="/scan-users/:companyName/:companyId" component={CompanyScanUserDetails} />
      <Route exact path="/scan-users/company/user/add/new/:companyId" component={CompanyAddScanUser} />
      <Route exact path="/scan-users/company/user/update/:user/:companyId/:userId" component={CompanyAddScanUser} />

      <Route exact path="/scan-users/:companyName/:hubName/:companyId/:hubId" component={HubScanUserDetails} />
      <Route exact path="/scan-users/company/hub/user/add/:companyId/:hubId" component={HubAddScanuser} />
      <Route exact path="/scan-users/company/hub/user/update/:user/:companyId/:hubId/:userId" component={HubAddScanuser} />
    </>
  )
}
