import React, { useEffect, useState } from "react";
import { getHubCargoData } from "../../../actions";
import { paginateArrayElements } from "../../../utils/pagination";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import moment from "moment";

export default function HubCargos(props) {
  const dispatch = useDispatch();
  const { cargosData, cargosDataLoading } = useSelector(
    (state) => state.hubPackages
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [packagesData, setPackagesData] = useState([]);
  const [packagesCompleteData, setPackagesCompleteData] = useState([]);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setPackagesData(
      paginateArrayElements(packagesCompleteData, props.pageSize, page)
    );
  };

  useEffect(() => {
    setPackagesData([]);
    setPackagesCompleteData([]);
    setCurrentPage(1);
    dispatch(
      getHubCargoData({
        hub_id: props.hubId,
        page_size: 100,
        page_number: 1,
      })
    );
  }, [props.hubId, props.pageSize]);

  //useEffect(() => {
  //dispatch(getHubCargoData({
  //"hub_id": props.hubId,
  //"page_size": props.pageSize,
  //"page_number": 1,
  //}));
  //}, [dispatch, props.hubId, props.pageSize]);

  useEffect(() => {
    if (cargosData) {
      setPackagesCompleteData(cargosData);
      setPackagesData(
        paginateArrayElements(cargosData, props.pageSize, currentPage)
      );
    }
  }, [cargosData, currentPage]);

  return (
    <>
      <div role="table" aria-label="Package Details Table">
        <div className="table-responsive table-fixed">
          <table className="table table-striped table-borderless packages-table">
            <thead className="table-header--bottom-border">
              <tr>
                <th>
                  <span className="cursor-pointer">Cargo Tracking Number</span>
                </th>
                <th>
                  <span className="cursor-pointer">Total Packages</span>
                </th>
                <th>
                  <span className="cursor-pointer">Packages Inbounded</span>
                </th>
                <th>
                  <span className="cursor-pointer">
                    First Package Inbounded
                  </span>
                </th>
                <th>
                  <span className="cursor-pointer">Last Package Inbounded</span>
                </th>
                <th>
                  <span className="cursor-pointer">Is Fully Inbounded</span>
                </th>
                <th>
                  <span className="cursor-pointer">Hub</span>
                </th>
                <th>
                  <span className="cursor-pointer">Previous Hub</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {cargosDataLoading && (
                <tr>
                  <td colSpan="8" className="text-center">
                    <LoadingOutlined className="loading-spinner" />
                  </td>
                </tr>
              )}
              {!cargosDataLoading &&
                packagesData &&
                packagesData.length > 0 &&
                packagesData.map((row, index) => {
                  return (
                    <tr key={`${row.cargo_tracking_no}_${index}`}>
                      <td className="text-span-black">
                        {row.cargo_tracking_no}
                      </td>
                      <td className="text-span-black">{row.total_packages}</td>
                      <td className="text-span-black">
                        {row.packages_inbounded}
                      </td>
                      <td className="text-span-black">
                        {row.first_package_inbounded
                          ? moment(row.first_package_inbounded).format(
                              "DD MMM YY"
                            )
                          : "-"}
                      </td>
                      <td className="text-span-black">
                        {row.last_package_inbounded
                          ? moment(row.last_package_inbounded).format(
                              "DD MMM YY"
                            )
                          : "-"}
                      </td>
                      <td className="text-span-black text-capitalize">
                        {String(row.is_fully_inbounded)}
                      </td>
                      <td className="text-span-black">{row.hub}</td>
                      <td className="text-span-black">{row.hub_from}</td>
                    </tr>
                  );
                })}
              {!cargosDataLoading && packagesData && packagesData.length === 0 && (
                <tr>
                  <td colSpan="8" className="text-center standard-font">
                    No entry found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="info-text mt-20">
          <i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>
          <span className="standard-font-gray">
            Showing {props.pageSize} most recent tracking numbers scanned.
            Filter to see all filtered tracking numbers
          </span>
        </div>
        {packagesCompleteData && packagesCompleteData.length > 0 && (
          <div className="mt-3 text-center">
            <Pagination
              current={currentPage}
              defaultPageSize={props.pageSize}
              total={packagesCompleteData.length}
              onChange={onPageChange}
              className="mini packages-table-antd-pagination"
            />
          </div>
        )}
      </div>
    </>
  );
}

