import { scanTypesTypes } from '../types';

const initialState = {
  addScanTypeLoading: false,
  addScanTypeErrorMessage: null,
  scanTypeCreated: false,
  addScanTypeSuccessMessage: '',

  updateScanTypeLoading: false,
  updateScanTypeErrorMessage: null,
  scanTypeUpdated: false,
  updateScanTypeSuccessMessage: '',

  scanTypesloading: false,
  scanTypesErrorMessage: null,
  scanTypes: [],

  inactiveScanTypesloading: false,
  inactiveScanTypesErrorMessage: null,
  inactiveScanTypes: [],

  janioScanTypesLoading: false,
  janioScanTypesErrorMessage: null,
  janioScanTypes: []
}

const scanTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case scanTypesTypes.CREATE_SCAN_TYPE_LOADING:
      return { ...state, addScanTypeLoading: true }
    case scanTypesTypes.CREATE_SCAN_TYPE_SUCCESS:
      return { ...state, scanTypeCreated: true, addScanTypeSuccessMessage: action.payload, addScanTypeLoading: false };
    case scanTypesTypes.CREATE_SCAN_TYPE_FAILURE:
      return { ...state, addScanTypeErrorMessage: action.payload, addScanTypeLoading: false }
    case scanTypesTypes.RESET_CREATE_SCAN_TYPE_DATA:
      return { ...state, addScanTypeLoading: false, addScanTypeErrorMessage: null, scanTypeCreated: false, addScanTypeSuccessMessage: '' };
    
    case scanTypesTypes.UPDATE_SCAN_TYPE_LOADING:
      return { ...state, updateScanTypeLoading: true }
    case scanTypesTypes.UPDATE_SCAN_TYPE_SUCCESS:
      return { ...state, scanTypeUpdated: true, updateScanTypeSuccessMessage: action.payload, updateScanTypeLoading: false };
    case scanTypesTypes.UPDATE_SCAN_TYPE_FAILURE:
      return { ...state, updateScanTypeErrorMessage: action.payload, updateScanTypeLoading: false }
    case scanTypesTypes.RESET_UPDATED_SCAN_TYPE_DATA:
      return { ...state, updateScanTypeLoading: false, updateScanTypeErrorMessage: null, scanTypeUpdated: false, updateScanTypeSuccessMessage: '' };
    
    case scanTypesTypes.FETCH_SCAN_TYPES_LOADING:
      return { ...state, scanTypesloading: true }
    case scanTypesTypes.FETCH_SCAN_TYPES_SUCCESS:
      return { ...state, scanTypes: action.payload, scanTypesloading: false }
    case scanTypesTypes.FETCH_SCAN_TYPES_FAILURE:
      return { ...state, scanTypesErrorMessage: action.payload, scanTypesloading: false }
    
    case scanTypesTypes.FETCH_INACTIVE_SCAN_TYPES_LOADING:
      return { ...state, inactiveScanTypesloading: true }
    case scanTypesTypes.FETCH_INACTIVE_SCAN_TYPES_SUCCESS:
      return { ...state, inactiveScanTypes: action.payload, inactiveScanTypesloading: false }
    case scanTypesTypes.FETCH_INACTIVE_SCAN_TYPES_FAILURE:
      return { ...state, inactiveScanTypesErrorMessage: action.payload, inactiveScanTypesloading: false }
    
    case scanTypesTypes.FETCH_JANIO_SCAN_TYPES_LOADING:
      return { ...state, janioScanTypesLoading: true }
    case scanTypesTypes.FETCH_JANIO_SCAN_TYPES_SUCCESS:
      return { ...state, janioScanTypes: action.payload, janioScanTypesLoading: false }
    case scanTypesTypes.FETCH_JANIO_SCAN_TYPES_FAILURE:
      return { ...state, janioScanTypesErrorMessage: action.payload, janioScanTypesLoading: false }
    default:
      return state;
  }
}

export { scanTypesReducer }




