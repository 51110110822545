/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { companyAction } from '../../actions';
import { useDispatch } from 'react-redux';
import './style.scss';

export default function companyCard({ status, company }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const cardContainerClass = status === 'active' ? 'active-company-card-container' : 'in-active-company-card-container';
  const companyNameClass = status === 'active' ? 'active-company-name mb-0' : 'in-active-company-name mb-0';

  return (
    <div className={cardContainerClass} onClick={() => CompanyDetails(status, company)}>
      <div className="d-flex flex-column text-center">
        <h2 className={companyNameClass}>{company.name}</h2>
        <p className="company-address mb-0">{company.address}, <span className="country-name">{company.country}</span></p>
      </div>
    </div>
  );

  function CompanyDetails(status, company) {
    dispatch(companyAction.resetCreateCompanyData());
    dispatch(companyAction.resetUpdateCompanyData());

    if (status === 'active') {
      history.push(`/companies/${company.id}/${company.name.toLowerCase()}`);
    }
  }
}
