import axios from "axios";
import { ROOT_URL } from "./rootUrl";

const createCompany = (companyData) => {
  return axios({
    method: "POST",
    url: `${ROOT_URL}/api/hms/admin/companies/`,
    data: companyData,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const updateCompany = (companyData) => {
  return axios({
    method: "PUT",
    url: `${ROOT_URL}/api/hms/admin/companies/?id=${companyData.id}`,
    data: companyData,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const getActiveCompanies = () => {
  return axios({
    method: "GET",
    url: `${ROOT_URL}/api/hms/admin/companies/?is_active=${1}`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data.data);
};

const getInactiveCompanies = () => {
  return axios({
    method: "GET",
    url: `${ROOT_URL}/api/hms/admin/companies/?is_active=${0}`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data.data);
};

export const companyService = {
  createCompany,
  updateCompany,
  getActiveCompanies,
  getInactiveCompanies,
};
