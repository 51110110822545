import axios from 'axios';
import { ROOT_URL } from './rootUrl';

const createCompanyManager = (data) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/hub_managers/?company_id=${data.company_id}`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const updateCompanyManager = (data) => {
  return axios({
    method: 'PUT',
    url: `${ROOT_URL}/api/hms/admin/hub_managers/?id=${data.id}`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getCompanyActiveManagers = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hub_managers/?company_id=${data.companyId}&is_active=${1}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getCompanyInactiveManagers = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hub_managers/?company_id=${data.companyId}&is_active=${0}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const createHubManager = (data) => {
  return axios({
    method: 'POST',
    url: `${ROOT_URL}/api/hms/admin/hub_managers/?company_id=${data.company_id}&hub_id=${data.hubId}`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const updateHubManager = (data) => {
  return axios({
    method: 'PUT',
    url: `${ROOT_URL}/api/hms/admin/hub_managers/?id=${data.id}`,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getHubActiveManagers = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hub_managers/?hub_id=${data.hubId}&is_active=${1}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

const getHubInactiveManagers = (data) => {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/api/hms/admin/hub_managers/?hub_id=${data.hubId}&is_active=${0}`,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

export const hubManagersService = {
  createCompanyManager,
  updateCompanyManager,
  getCompanyActiveManagers,
  getCompanyInactiveManagers,
  createHubManager,
  updateHubManager,
  getHubActiveManagers,
  getHubInactiveManagers
}