import { companiesTypes } from '../types';

const initialState = {
  addCompanyLoading: false,
  addCompanyErrorMessage: null,
  companyCreated: false,
  addCompanySuccessMessage: '',

  updateCompanyLoading: false,
  updateCompanyErrorMessage: null,
  companyUpdated: false,
  updateCompanySuccessMessage: '',

  fetchCompaniesLoading: false,
  fetchCompanyErrorMessage: null,
  companies: [],
  fetchInactiveCompaniesLoading: false,
  fetchInactiveCompanyErrorMessage: null,
  inactiveCompanies: []
}

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case companiesTypes.CREATE_COMPANY_LOADING:
      return { ...state, addCompanyLoading: true }
    case companiesTypes.CREATE_COMPANY_SUCCESS:
      return { ...state, companyCreated: true, addCompanySuccessMessage: action.payload, addCompanyLoading: false };
    case companiesTypes.CREATE_COMPANY_FAILURE:
      return { ...state, addCompanyErrorMessage: action.payload, addCompanyLoading: false }
    case companiesTypes.RESET_CREATE_COMPANY_DATA:
      return { ...state, addCompanyLoading: false, addCompanyErrorMessage: null, companyCreated: false, addCompanySuccessMessage: '' };
    
    case companiesTypes.UPDATE_COMPANY_LOADING:
      return { ...state, updateCompanyLoading: true };
    case companiesTypes.UPDATE_COMPANY_SUCCESS:
      return { ...state, companyUpdated: true, updateCompanySuccessMessage: action.payload, updateCompanyLoading: false };
    case companiesTypes.UPDATE_COMPANY_FAILURE:
      return { ...state, updateCompanyErrorMessage: action.payload, updateCompanyLoading: false };
    case companiesTypes.RESET_UPDATE_COMPANY_DATA:
      return { ...state, updateCompanyLoading: false, updateCompanyErrorMessage: null, companyUpdated: false, updateCompanySuccessMessage: '' };

    case companiesTypes.FETCH_COMPANIES_LOADING:
      return { ...state, fetchCompaniesLoading: true }
    case companiesTypes.FETCH_COMPANIES_SUCCESS:
      return { ...state, companies: action.payload, fetchCompaniesLoading: false }
    case companiesTypes.FETCH_COMPANIES_FAILURE:
      return { ...state, fetchCompanyErrorMessage: action.payload, fetchCompaniesLoading: false }
    case companiesTypes.FETCH_INACTIVE_COMPANIES_LOADING:
      return { ...state, fetchInactiveCompaniesLoading: true }
    case companiesTypes.FETCH_INACTIVE_COMPANIES_SUCCESS:
      return { ...state, inactiveCompanies: action.payload, fetchInactiveCompaniesLoading: false }
    case companiesTypes.FETCH_INACTIVE_COMPANIES_FAILURE:
      return { ...state, fetchInactiveCompanyErrorMessage: action.payload, fetchInactiveCompaniesLoading: false }
    default:
      return state;
  }
}

export { companiesReducer }