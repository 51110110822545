/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Divider } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { companyAction, hubsAction, locationAction, scanTypesAction } from "../../../actions";
import CustomInput from "../../../components/input/input";
import CustomSelect from "../../../components/select/select";
import CustomMultiSelect from "../../../components/multiSelect/multiSelect";
import * as _ from "lodash";
import "./style.scss";

export default function AddHub() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { countries, states, cities } = useSelector((state) => state.location);

  const [scanTypesState, setScanTypesState] = useState([]);
  const [janioScanTypesState, setJanioScanTypesState] = useState([]);

  const [state, setState] = useState({
    hub_id: "",
    hub_name: "",
    hub_address: "",
    is_active: true,
    country_id: undefined,
    state_id: undefined,
    city_id: undefined,
    postal_code: "",
    is_all_scan_types: true,
    excluded_scan_types: [],
    included_scan_types: [],
    type: "HUB",
  });

  const statusOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const typeOptions = [
    { value: "HUB", label: "HUB" },
    { value: "PORT", label: "PORT" },
  ];

  const { activeHubs, hubCreated, createHubErrorMessage, hubUpdated, updateHubErrorMessage } = useSelector((state) => state.hubs);

  const { companies } = useSelector((state) => state.companies);

  const { scanTypes, janioScanTypes } = useSelector((state) => state.scanTypes);

  const company = _.find(companies, (x) => String(x.id) === params.companyId);
  const hub = _.find(activeHubs, (x) => String(x.hub_id) === params.hubId);

  const [addError, setAddError] = useState(createHubErrorMessage);
  const [updateError, setUpdateError] = useState(updateHubErrorMessage);

  useEffect(() => {
    if (!_.isEqual(addError, createHubErrorMessage)) {
      setAddError(createHubErrorMessage);
    }
  }, [createHubErrorMessage]);

  useEffect(() => {
    if (!_.isEqual(updateError, updateHubErrorMessage)) {
      setUpdateError(updateHubErrorMessage);
    }
  }, [updateHubErrorMessage]);

  useEffect(() => {
    dispatch(locationAction.getCountries());
  }, [dispatch]);

  useEffect(() => {
    if (hubCreated) {
      goToHubsPage();
    }
  }, [hubCreated]);

  useEffect(() => {
    if (hubUpdated) {
      goToHubsPage();
    }
  }, [hubUpdated]);

  useEffect(() => {
    if (params.hubId && hub) {
      let stateData = {
        country: hub.country,
      };
      let cityData = {
        country: hub.country,
        state: hub.state,
      };
      dispatch(locationAction.getCountries());
      dispatch(locationAction.getStates(stateData));
      dispatch(locationAction.getCities(cityData));
      setState({
        ...state,
        hub_name: hub.hub_name,
        hub_id: hub.hub_id,
        is_active: true,
        country_id: hub.country,
        state_id: hub.state,
        city_id: hub.city,
        hub_address: hub.address,
        postal_code: hub.postal_code,
        type: hub.type,
      });
      form.setFieldsValue({
        hub_name: hub.hub_name,
        hub_id: hub.hub_id,
        is_active: true,
        country_id: hub.country,
        state_id: hub.state,
        city_id: hub.city,
        hub_address: hub.address,
        postal_code: hub.postal_code,
        type: hub.type,
      });
    }
  }, [params, hub, form, dispatch]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    }
  }, [companies, dispatch]);

  useEffect(() => {
    if (activeHubs && activeHubs.length === 0) {
      dispatch(hubsAction.getActiveHubs(params.companyId));
    }
  }, [activeHubs, dispatch]);

  useEffect(() => {
    dispatch(scanTypesAction.getActiveScanTypes(params));
    if (params.companyId !== 5) {
      dispatch(scanTypesAction.getJanioScanTypes());
    }
  }, []);

  useEffect(() => {
    if (!_.isEqual(scanTypes, scanTypesState)) {
      let scanTypesToSave = _.orderBy(scanTypes, "scan_type_name", "asc");
      scanTypesToSave = scanTypesToSave.map((type) => {
        type.value = type.scan_type_name;
        type.label = type.scan_type_name;
        return type;
      });
      setScanTypesState(scanTypesToSave);
    }
  }, [scanTypes]);

  useEffect(() => {
    if (!_.isEqual(janioScanTypes, janioScanTypesState)) {
      let scanTypesToSave = _.orderBy(janioScanTypes, "scan_type_name", "asc");
      scanTypesToSave = scanTypesToSave.map((type) => {
        type.value = type.scan_type_name;
        type.label = type.scan_type_name;
        return type;
      });
      setJanioScanTypesState(scanTypesToSave);
    }
  }, [janioScanTypes]);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleHubsClick}>
            Hubs
          </span>
          <span className="bread-crumb"> {">"} </span>
          <span className="link-title" onClick={goToHubsPage}>
            {company && company.name}
          </span>
          <span className="bread-crumb">{">"}</span>
          <span className="title">{breadCrumbText()}</span>
        </p>
        <div className="bg-container-body">
          <Form form={form} onFinish={onAddHub} initialValues={state}>
            <div className="d-flex heading-area">
              <h2 className="heading">Hub Information</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="hub_name"
                  className="mb-sm-0"
                  rules={[{ type: "string", required: true, message: "Please type a hub name first" }]}
                  validateStatus={getValidateStatus("hub_name") ? "error" : "validating"}
                  help={getError("hub_name")}
                >
                  <CustomInput label="Hub Name" name="hub_name" onChange={handleChange} placeholder="Type here" />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="hub_id"
                  className="mb-sm-0"
                  rules={[{ type: "string", required: true, message: "Please type a hub id first" }]}
                  validateStatus={getValidateStatus("hub_id") ? "error" : "validating"}
                  help={getError("hub_id")}
                >
                  <CustomInput label="Hub ID" name="hub_id" onChange={handleChange} placeholder="Type here" />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item name="is_active">
                  <CustomSelect label="Is Active" active={state.is_active} onChange={(value) => onSelectionChange("is_active", value)} options={statusOptions} />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item name="is_port" className="mb-0">
                  <CustomSelect label="Hub Type" active={state.type} onChange={(value) => onSelectionChange("type", value)} options={typeOptions} />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
            </div>
            <div className="d-flex heading-area">
              <h2 className="heading">Hub Address</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="country_id"
                  rules={[{ type: "string", required: true, message: "Please select a country first" }]}
                  validateStatus={getValidateStatus("country_id") ? "error" : "validating"}
                  help={getError("country_id")}
                >
                  <CustomSelect
                    label="Country"
                    active={state.country_id}
                    onChange={(value) => onCountrySelectionChange("country_id", value)}
                    options={countries}
                    placeholder="Select country"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="state_id"
                  rules={[{ type: "string", required: true, message: "Please select a state/province first" }]}
                  validateStatus={getValidateStatus("state_id") ? "error" : "validating"}
                  help={getError("state_id")}
                >
                  <CustomSelect
                    label="State/Province"
                    active={state.state_id}
                    onChange={(value) => onStateSelectionChange("state_id", value)}
                    options={states}
                    placeholder="Select state/province"
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="city_id"
                  rules={[{ type: "string", required: true, message: "Please select a city first" }]}
                  validateStatus={getValidateStatus("city_id") ? "error" : "validating"}
                  help={getError("city_id")}
                >
                  <CustomSelect label="City" active={state.city_id} onChange={(value) => onCitySelectionChange("city_id", value)} options={cities} placeholder="Select city" />
                </Form.Item>
              </div>
              <div className="col-md-8 col-sm-12">
                <Form.Item
                  name="hub_address"
                  className="mb-md-0"
                  rules={[{ type: "string", required: true, message: "Please select an address first" }]}
                  validateStatus={getValidateStatus("hub_address") ? "error" : "validating"}
                  help={getError("hub_address")}
                >
                  <CustomInput label="Address" name="hub_address" onChange={handleChange} placeholder="Type here" />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-12">
                <Form.Item
                  name="postal_code"
                  className="mb-0"
                  rules={[{ type: "string", required: true, message: "Please select a postal code first" }]}
                  validateStatus={getValidateStatus("postal_code") ? "error" : "validating"}
                  help={getError("postal_code")}
                >
                  <CustomInput label="Postal code" name="postal_code" onChange={handleChange} placeholder="Type here" className="login-input" />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
            </div>
            <div className="d-flex heading-area">
              <h2 className="heading">Hub Scan Type Permissions</h2>
            </div>
            <div className="row">
              {/* <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="is_all_scan_types"
                  className="mb-md-0">
                  <CustomSelect
                    label="Has All Scan Types"
                    active={state.is_all_scan_types}
                    onChange={(value) => onSelectionChange('is_all_scan_types', value)}
                    options={statusOptions}
                  />
                </Form.Item>
              </div> */}

              <div className="col-md-8 col-sm-12">
                <Form.Item name="included_scan_types" className="mb-0">
                  <CustomMultiSelect
                    label="Inclusions (Scan types included for this hub)"
                    active={state.included_scan_types}
                    onChange={(value) => onSelectionChange("included_scan_types", value)}
                    options={[...scanTypesState, ...janioScanTypesState]}
                    placeholder="Add scan types to be included, if any"
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Divider className="blue-divider" />
              </div>
            </div>
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button className="secondary-button" onClick={goToHubsPage}>
                Cancel
              </button>
              <button type="submit" className="primary-button primary-button-margin-bottom-sm">
                {getButtonText()}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );

  function getValidateStatus(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getError(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getAddError(name) {
    if (addError && addError[name] && addError[name].length > 0) {
      return addError[name][0];
    }
  }

  function getUpdateError(name) {
    if (updateError && updateError[name] && updateError[name].length > 0) {
      return updateError[name][0];
    }
  }

  function breadCrumbText() {
    if (params.hubId) {
      return "Edit Hub";
    }
    return "Add New Hub";
  }

  function getButtonText() {
    if (params.hubId) {
      return "Edit Hub";
    }
    return "Add Hub";
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function onCountrySelectionChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
    let data = {
      country: value,
    };
    dispatch(locationAction.getStates(data));
  }

  function onStateSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
    let data = {
      country: state.country_id,
      state: value,
    };
    dispatch(locationAction.getCities(data));
  }

  function onCitySelectionChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function getLocations() {
    const selectedCountry = _.find(countries, (x) => x.country_name === state.country_id);
    const selectedState = _.find(states, (x) => x.state_name === state.state_id);
    const selectedCity = _.find(cities, (x) => x.city_name === state.city_id);
    return { selectedCountry, selectedState, selectedCity };
  }

  function getHubData() {
    const { selectedCountry, selectedState, selectedCity } = getLocations();
    let data = {
      hub_name: state.hub_name,
      hub_id: state.hub_id,
      is_active: state.is_active,
      country_id: selectedCountry.country_id,
      state_id: selectedState.state_id,
      city_id: selectedCity.city_id,
      hub_address: state.hub_address,
      postal_code: state.postal_code,
      is_all_scan_types: state.is_all_scan_types,
      included_scan_types: state.included_scan_types,
      excluded_scan_types: [],
      company_id: params.companyId,
      type: state.type,
    };
    return data;
  }

  function onAddHub() {
    let data = getHubData();
    if (params.hubId) {
      data.id = params.hubId;
      dispatch(hubsAction.updateHub(data));
      return;
    }
    dispatch(hubsAction.createHub(data));
  }

  function goToHubsPage() {
    history.push(`/hubs/hubsList/${params.companyId}`);
  }

  function handleHubsClick() {
    history.push("/hubs");
  }
}
