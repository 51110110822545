export const locationTypes = {
  FETCH_COUNTRIES_LOADING: 'FETCH_COUNTRIES_LOADING',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILURE: 'FETCH_COUNTRIES_FAILURE',

  FETCH_STATES_LOADING: 'FETCH_STATES_LOADING',
  FETCH_STATES_SUCCESS: 'FETCH_STATES_SUCCESS',
  FETCH_STATES_FAILURE: 'FETCH_STATES_FAILURE',
  
  FETCH_CITIES_LOADING: 'FETCH_CITIES_LOADING',
  FETCH_CITIES_SUCCESS: 'FETCH_CITIES_SUCCESS',
  FETCH_CITIES_FAILURE: 'FETCH_CITIES_FAILURE'
};