import { combineReducers } from 'redux';
import { loginReducer } from './login.reducer';
import { hubsReducer } from './hubs.reducer';
import { hubManagersReducer } from './hub_managers.reducer';
import { hubsOrderReducer } from './hubs_order.reducer';
import { companiesReducer } from './companies.reducer';
import { companyAdminReducer } from './company_admin.reducer';
import { locationReducer } from './location.reducer';
import { scanUsersReducer } from './scan_users.reducer';
import { scanTypesReducer } from './scan_types.reducers';
import { hubPackagesReducer } from './hub_packages.reducers';
import { commonReducer } from './common.reducer';
import { toolsReducer } from './tools.reducer';


const rootReducer = combineReducers({
  auth: loginReducer,
  hubs: hubsReducer,
  hubManagers: hubManagersReducer,
  hubsOrder: hubsOrderReducer,
  companies: companiesReducer,
  companyAdmin: companyAdminReducer,
  location: locationReducer,
  scanUsers: scanUsersReducer,
  scanTypes: scanTypesReducer,
  hubPackages: hubPackagesReducer,
  common: commonReducer,
  tools: toolsReducer
})

export default rootReducer;