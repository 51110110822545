import { toolsTypes } from "../types";

const initialState = {
  addBulkExceptionLoading: false,
  addBulkExceptionSuccess: false,
  addBulkExceptionError: false,
  resourceCreated: false,
  resourceEdited: false,
  resourceDeleted: false,
  resources: [],
  tags: [],
  resourceReportData: null,
};

const toolsReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolsTypes.ADD_BULK_EXCEPTION_LOADING:
      return { ...state, addBulkExceptionLoading: true };
    case toolsTypes.ADD_BULK_EXCEPTION_SUCCESS:
      return {
        ...state,
        addBulkExceptionLoading: false,
        addBulkExceptionSuccess: true,
      };
    case toolsTypes.ADD_BULK_EXCEPTION_FAILURE:
      return {
        ...state,
        addBulkExceptionLoading: false,
        addBulkExceptionError: action.payload,
      };
    case toolsTypes.GET_RESOURCE_SUCCESS:
      return { ...state, resources: action.payload };
    case toolsTypes.GET_RESOURCE_ERROR:
      return { ...state, resources: [] };
    case toolsTypes.CREATE_RESOURCE_SUCCESS:
      return { ...state, resourceCreated: true };
    case toolsTypes.GET_RESOURCE_TAG_SUCCESS:
      return { ...state, tags: action.payload };
    case toolsTypes.RESOURCE_CLEANUP:
      return {
        ...state,
        resourceCreated: false,
        resourceEdited: false,
        resourceDeleted: false,
      };
    case toolsTypes.UPDATE_RESOURCE_SUCCESS:
      return { ...state, resourceEdited: true };
    case toolsTypes.DELETE_RESOURCE_SUCCESS:
      return { ...state, resourceDeleted: true };
    case toolsTypes.GET_STANDARD_RESOURCE_REPORT_DATA_SUCCESS:
    case toolsTypes.GET_AGGREGATED_RESOURCE_REPORT_DATA_SUCCESS:
      return { ...state, resourceReportData: action.payload };
    default:
      return state;
  }
};

export { toolsReducer };
