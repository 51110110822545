import React from 'react';
import { DatePicker } from 'antd';
import './style.scss';

const { RangePicker } = DatePicker;

function rangePicker(props) {
  const {
    label,
    placeholder,
    onChange,
    onOk
  } = props;

  return (
    <React.Fragment>
      {label && <h6 className="form-label">{label}</h6>}
      <RangePicker
        className="datepicker"
        placeholder={placeholder}
        showTime={{ format: 'HH:mm' }}
        format="YYYY-MM-DD HH:mm"
        onChange={onChange}
        // onOk={onOk}
      />
    </React.Fragment>
  )
}

export default rangePicker;