import React from 'react';
import { Route } from 'react-router-dom';
import Nav from '../../components/nav/nav';
import CompaniesList from './companiesList/companiesList';
import CompanyDetails from './companyDetails/companyDetails';
import AddCompany from './addCompany/addCompany';


export default function Companies() {
  return (
    <>
      <Nav />
      <Route exact path="/companies" component={CompaniesList} />
      <Route exact path="/companies/:companyId/:companyName" component={CompanyDetails} />
      <Route exact path="/companies/company/add/new" component={AddCompany} />
      <Route exact path="/companies/company/update/:companyId/:companyName" component={AddCompany} />
    </>
  )
}
