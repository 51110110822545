/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Divider, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import CustomInput from '../../../components/input/input';
import CustomSelect from '../../../components/select/select';
import { companyAction, scanTypesAction } from '../../../actions';
import * as _ from 'lodash';
import './style.scss';


export default function AddScanTypes() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { companies } = useSelector(state => state.companies);
  const {
    scanTypes,
    inactiveScanTypes,
    scanTypeCreated,
    scanTypeUpdated,
    addScanTypeErrorMessage,
    updateScanTypeErrorMessage
  } = useSelector(state => state.scanTypes);

  const company = _.find(companies, x => String(x.id) === params.companyId);
  const type = getTypeData();

  const [state, setState] = useState({
    scan_type: '',
    process_type: undefined,
    is_active: true,
    import_private_status: undefined,
    export_private_status: undefined,
    domestic_private_status: undefined,
    transhipment_private_status: undefined
  });

  const [addError, setAddError] = useState(addScanTypeErrorMessage);
  const [updateError, setUpdateError] = useState(updateScanTypeErrorMessage);

  const processTypesOptions = [
    { value: 'inbound', label: 'Inbound' },
    { value: 'outbound', label: 'Outbound' },
    { value: 'others', label: 'Others' },
  ]

  const statusOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  useEffect(() => {
    if (params.scanTypeId && type) {
      setState({
        ...state,
        'is_active': type.is_active,
        'scan_type': type.scan_type_name,
        'process_type': type.leg
      });

      form.setFieldsValue({
        'is_active': type.is_active,
        'scan_type': type.scan_type_name,
        'process_type': type.leg
      });
    }
  }, [type, params, form]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    }
  }, [companies, dispatch]);

  useEffect(() => {
    if (scanTypeCreated) {
      history.push(`/scan-types/${company.name.toLowerCase()}/${params.companyId}`);
    }
  }, [scanTypeCreated, history, params]);

  useEffect(() => {
    if (scanTypeUpdated) {
      history.push(`/scan-types/${company.name.toLowerCase()}/${params.companyId}`);
    }
  }, [scanTypeUpdated, history, params]);

  useEffect(() => {
    if (!_.isEqual(addError, addScanTypeErrorMessage)) {
      setAddError(addScanTypeErrorMessage);
    }
    if (addScanTypeErrorMessage && addScanTypeErrorMessage.message) {
      message.error(addScanTypeErrorMessage.message)
    }
  }, [addScanTypeErrorMessage]);

  useEffect(() => {
    if (!_.isEqual(updateError, updateScanTypeErrorMessage)) {
      setUpdateError(updateScanTypeErrorMessage);
    }
    if (updateScanTypeErrorMessage && updateScanTypeErrorMessage.message) {
      message.error(updateScanTypeErrorMessage.message)
    }
  }, [updateScanTypeErrorMessage]);

  useEffect(() => {
    if (scanTypes.length === 0 || inactiveScanTypes.length === 0) {
      dispatch(scanTypesAction.getActiveScanTypes(params));
      dispatch(scanTypesAction.getInactiveScanTypes(params));
    }
  }, [params, dispatch]);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleScanTypesClick}>Scan Types</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="link-title" onClick={goToTypesDetailsPage}>{company && company.name}</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="title">{breadCrumbText()}</span>
        </p>
        <div className="bg-container-body">
          <Form form={form} onFinish={onAddScanType} initialValues={state}>
            <div className="d-flex heading-area">
              <h2 className="heading mb-0">Scan Type Information</h2>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="scan_type"
                  className="mb-md-0"
                  rules={[{ type: 'string', required: true, message: 'Please type a Name first' }]}
                  validateStatus={getValidateStatus('scan_type') ? 'error' : 'validating'}
                  help={getError('scan_type')}>
                  <CustomInput
                    label="Name"
                    name="scan_type"
                    onChange={handleChange}
                    placeholder="Type here"
                    disabled={params.scanTypeId}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="process_type"
                  className="mb-md-0"
                  rules={[{ type: 'string', required: true, message: 'Please type a Process Type first' }]}
                  validateStatus={getValidateStatus('process_type') ? 'error' : 'validating'}
                  help={getError('process_type')}>
                  <CustomSelect
                    label="Process Type"
                    active={state.process_type}
                    onChange={(value) => onSelectionChange('process_type', value)}
                    options={processTypesOptions}
                    placeholder="Select process type"
                    disabled={params.scanTypeId}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4 col-sm-6 col-12">
                <Form.Item
                  name="is_active"
                  className="mb-0"
                >
                  <CustomSelect
                    label="Is Active"
                    active={state.is_active}
                    onChange={(value) => onSelectionChange('is_active', value)}
                    options={statusOptions}
                  />
                </Form.Item>
              </div>
            </div>
            {state.process_type &&
              <React.Fragment>
                <div className="row">
                  <div className="col-12 mt-1">
                    <Divider className="gray-divider" />
                  </div>
                </div>
                <div className="d-flex flex-column heading-area">
                  <h2 className="heading heading-area">Process Type: {state.process_type}</h2>
                    <span className="sub-heading">
                      For each shipment leg for the process type, add a private status. Leave it blank if there is no private status for a given shipment leg.
                    </span>
                </div>
                <div className="row">
                  <div className="col-md-2 col-sm-4">
                    <span className="text-span-black">Leg</span>
                  </div>
                  <div className="col-md-4 col-sm-8">
                    <span className="text-span-black">Private Status</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12 mt-1">
                    <Divider className="gray-divider" />
                  </div>
                </div>
                <div className="row align-items-center heading-area">
                  <div className="col-md-2 col-sm-4">
                    <h6 className="text-span-black">Export</h6>
                  </div>
                  <div className="col-md-4 col-sm-8">
                    <Form.Item
                      name="export_private_status"
                      className="mb-0"
                    >
                      <CustomInput
                        name="export_private_status"
                        onChange={handleChange}
                        placeholder="Type or select private status"
                      />
                      {/* <CustomSelect
                        active={state.export_private_status}
                        onChange={(value) => onSelectionChange('export_private_status', value)}
                        options={statusOptions}
                        placeholder="Type or select private status"
                        /> */}
                    </Form.Item>
                  </div>
                </div>
                <div className="row align-items-center heading-area">
                    <div className="col-md-2 col-sm-4">
                      <h6 className="text-span-black">Transhipment</h6>
                    </div>
                    <div className="col-md-4 col-sm-8">
                      <Form.Item
                        name="transhipment_private_status"
                        className="mb-0"
                      >
                        <CustomInput
                          name="transhipment_private_status"
                          onChange={handleChange}
                          placeholder="Type or select private status"
                        />
                        {/* <CustomSelect
                          active={state.transhipment_private_status}
                          onChange={(value) => onSelectionChange('transhipment_private_status', value)}
                          options={statusOptions}
                          placeholder="Type or select private status"
                        /> */}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row align-items-center heading-area">
                    <div className="col-md-2 col-sm-4">
                      <h6 className="text-span-black">Import</h6>
                    </div>
                    <div className="col-md-4 col-sm-8">
                      <Form.Item
                        name="import_private_status"
                        className="mb-0"
                      >
                        <CustomInput
                          name="import_private_status"
                          onChange={handleChange}
                          placeholder="Type or select private status"
                        />
                        {/* <CustomSelect
                          active={state.import_private_status}
                          onChange={(value) => onSelectionChange('import_private_status', value)}
                          options={statusOptions}
                          placeholder="Type or select private status"
                        /> */}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-2 col-sm-4">
                      <h6 className="text-span-black">Domestic</h6>
                    </div>
                    <div className="col-md-4 col-sm-8">
                      <Form.Item
                        name="domestic_private_status"
                        className="mb-0"
                      >
                        <CustomInput
                          name="domestic_private_status"
                          onChange={handleChange}
                          placeholder="Type or select private status"
                        />
                        {/* <CustomSelect
                          active={state.domestic_private_status}
                          onChange={(value) => onSelectionChange('domestic_private_status', value)}
                          options={statusOptions}
                          placeholder="Type or select private status"
                        /> */}
                      </Form.Item>
                    </div>
                  </div>
              </React.Fragment>
            }
            <div className="row">
              <div className="col-12">
                <Divider className="blue-divider" />
              </div>
            </div>
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button className="secondary-button" onClick={goToTypesDetailsPage}>Cancel</button>
              <button type="submit" className="primary-button primary-button-margin-bottom-sm">{getButtonText()}</button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );

  function getTypeData() {
    let typeData = _.find(scanTypes, x => String(x.id) === params.scanTypeId);
    if (!typeData) {
      typeData = _.find(inactiveScanTypes, x => String(x.id) === params.scanTypeId);
    }
    return typeData;
  }

  function getValidateStatus(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getError(name) {
    if (addError) {
      return getAddError(name);
    } else if (updateError) {
      return getUpdateError(name);
    }
  }

  function getAddError(name) {
    if (addError && addError[name] && addError[name].length > 0) {
      return addError[name][0];
    }
  }

  function getUpdateError(name) {
    if (updateError && updateError[name] && updateError[name].length > 0) {
      return updateError[name][0];
    }
  }

  function breadCrumbText() {
    if (params.scanTypeId) {
      return `Edit ${type && type.scan_type_name}`;
    }
    return 'Add Scan Type';
  }

  function getButtonText() {
    if (params.scanTypeId) {
      return 'Edit Scan Type'
    }
    return 'Add Scan Type';
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value
    })
    resetAddErrorData(name);
    resetUpdateErrorData(name);
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value
    })
    resetAddErrorData(name);
    resetUpdateErrorData(name);
  }

  function resetAddErrorData(name) {
    if (addError && addError[name] && addError[name].length > 0) {
      let addErrorState = { ...addError };
      delete addErrorState[name];
      setAddError(addErrorState);
    }
  }

  function resetUpdateErrorData(name) {
    if (updateError && updateError[name] && updateError[name].length > 0) {
      let updateErrorState = { ...updateError };
      delete updateErrorState[name];
      setUpdateError(updateErrorState);
    }
  }

  function getScanTypeData() {
    let scanTypeData = {
      scan_type: state.scan_type,
      process_type: state.process_type,
      is_active: state.is_active,
      import_private_status: state.import_private_status,
      export_private_status: state.export_private_status,
      domestic_private_status: state.domestic_private_status,
      transhipment_private_status: state.transhipment_private_status
    }
    return scanTypeData;
  }

  function onAddScanType() {
    let scanTypeData = getScanTypeData();
    if (params.scanTypeId) {
      scanTypeData.id = type.id;
      dispatch(scanTypesAction.updateScanType(scanTypeData));
      return;
    }
    dispatch(scanTypesAction.createScanType(scanTypeData));
  }

  function goToTypesDetailsPage() {
    history.push(`/scan-types/${company.name.toLowerCase()}/${params.companyId}`);
  }

  function handleScanTypesClick() {
    history.push('/scan-types');
  }
}
