import React, { useEffect, useState} from 'react';
import { Divider, Modal, Radio, message, Alert, Form } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { companyAction, hubsAction } from '../../../actions';
import { hubPackageServices } from '../../../services';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import CustomInput from '../../../components/input/input';
import CustomSelect from '../../../components/select/select';
import CustomRangePicker from '../../../components/rangePicker/rangePicker';
import TextArea from '../../../components/textArea';
import HubDetails from './hubDetails';
import HubPackages from './hubPackages';
import HubCargos from './hubCargos';
import * as _ from 'lodash';

const HubPackageOrDetailsContainer = () => {
  const COMPONENT_PACKAGE = 'packages';
  const CARGO_RECONCILIATION = 'cargo_reconciliation';
  const COMPONENT_HUB_DETAILS = 'hub details';

  const reportTypeOptions = [
    { value: 'cargo', label: 'Cargo (includes all cargos in hub)'},
    { value: 'parcel', label: 'Parcel (includes all parcels in 1 cargo)' }
  ]

  const { packagesData, savedPackagesData } = useSelector(state => state.hubPackages);
  const params = useParams();

  const history = useHistory();
  const [showComponent, setShowComponet] = useState(COMPONENT_PACKAGE);
  const [pageSize, setPageSize] = useState(20);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [csvDownloadError, setCsvDownloadError] = useState(false);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [radioValue, setRadioValue] = useState('filtered');
  const [showReportModal, setShowReportModal] = useState(false);

  const [reportState, setReportState] = useState({
    email: '',
    reportType: undefined,
    reportDateRange: undefined,
    reportTrackingNumber: undefined,
    reportError: false,
    reportTrackingNumberError: false
  });

  const trackingNumberErrorClass = reportState.reportTrackingNumberError ? 'input-error' : '';
  const csvEmailErrorClass = csvDownloadError ? 'red-border' : '';

  const dispatch = useDispatch();

  const { activeHubs } = useSelector(state => state.hubs)
  const { companies } = useSelector(state => state.companies);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
  }, [dispatch, companies]);

  useEffect(() => {
    if (activeHubs && activeHubs.length === 0) {
      dispatch(hubsAction.getActiveHubs(params.companyId));
    }
  }, [dispatch, activeHubs, params.companyId]);


  const company = _.find(companies, x => String(x.id) === params.companyId);
  const hub = _.find(activeHubs, x => String(x.hub_id) === params.hubId);

  function goToHubsPage() {
    let locationStateToSend = { companyId: params.companyId };
    history.push(`/hubs/hubsList/${params.companyId}`);
  }

  function handleHubsClick() {
    history.push('/hubs');
  }

  function handleEditHubClick() {
    history.push(`/hubs/company/hub/update/${hub.hub_name.toLowerCase()}/${hub.hub_id}/${params.companyId}`);
  }

  const displayGenerateReportModal = () => {
    setShowReportModal(true);
  }

  const hideGenerateReportModal = () => {
    setShowReportModal(false);
    setReportState({
      email: '',
      reportType: undefined,
      reportDateRange: undefined,
      reportTrackingNumber: undefined,
      reportError: false,
      reportTrackingNumberError: false
    });
    form.setFieldsValue({
      email: '',
      reportType: undefined,
      reportDateRange: undefined,
      reportTrackingNumber: undefined,
    })
  }

  const generateReport = () => {
    if (reportState.reportTrackingNumber !== undefined &&
      reportState.reportTrackingNumber !== '') {
      generateParcelLevelReport();
    } else {
      generateCargoLevelReport();
    }
  }

  const getReportTrackingNumber = () => {
    const separator = reportState.reportTrackingNumber.includes(',') ? ',' : '\n';
    let trackingNumbers = reportState.reportTrackingNumber.split(separator);
    return trackingNumbers;
  }

  const checkifIncludesJNC = (cargoTrackingNumbers) => {
    let result = false;
    cargoTrackingNumbers.forEach((trackingNumber) => {
      if (trackingNumber.includes('JNC')) {
        result = true;
      } else {
        result = false;
      }
    });
    return result;
  }

  const generateParcelLevelReport = async () => {
    const cargoTrackingNumbers = getReportTrackingNumber();
    const includesJNC = checkifIncludesJNC(cargoTrackingNumbers);
    if (!includesJNC) {
      setReportState({
        ...reportState,
        reportTrackingNumberError: 'Invalid Cargo Number(s)'
      })
      return;
    }
    const data = {
      hub_id: hub.hub_id,
      email_id: reportState.email,
      cargo_tracking_nos: cargoTrackingNumbers
    };
    try {
      await hubPackageServices.generatePackageLevelReport(data);
      message.success('Parcel report has been sent to your email');
      hideGenerateReportModal();
    } catch (err) {
      setReportState({
        ...reportState,
        reportError: true
      });
      setTimeout(() => {
        setReportState({
          ...reportState,
          reportError: false,
          reportTrackingNumberError: false
        });
      }, 2000)
    }
  }

  const generateCargoLevelReport = async () => {
    const data = {
      hub_id: hub.hub_id,
      email_id: reportState.email,
      timestamp_from: reportState.reportDateRange ? reportState.reportDateRange[0] : null,
      timestamp_to: reportState.reportDateRange ? reportState.reportDateRange[1] : null,
    };
    try {
      await hubPackageServices.generateCargoLevelReport(data);
      message.success('Cargo report has been sent to your email');
      hideGenerateReportModal();
    } catch (err) {
      setReportState({
        ...reportState,
        reportError: true
      });
      setTimeout(() => {
        setReportState({
          ...reportState,
          reportError: false
        });
      }, 2000)
    }
  }

  const dispalyGenerateCSVModal = () => {
    setShowCSVModal(true);
  }

  const hideGenereateCSVModal = () => {
    setShowCSVModal(false);
    setEmailInputValue('');
    setRadioValue('filtered');
    setCsvDownloadError(false)
  }

  const generateCSVHandler = async () => {
    const data = getPackagesDownloadData();
    try {
      await hubPackageServices.generatePackageCSV(data);
      message.success('A report has been sent to your email');
      hideGenereateCSVModal();
    } catch (err) {
      const errorMessage = getError(err.response.data);
      setCsvDownloadError(errorMessage);
    }
  }

  const getError = (errorData) => {
    if (errorData.non_field_errors) {
      return errorData.non_field_errors[0];
    } else if (errorData.email_id) {
      return errorData.email_id[0];
    }
    return 'An error occured. Please try again.'
  }

  const getPackagesDownloadData = () => {
    const data = {
      hub_id: hub.hub_id,
      sort_by_exception: "desc",
      email_id: emailInputValue,
      tracking_no_in: radioValue === 'all' ? getTrackingNumbers(savedPackagesData) : getTrackingNumbers(packagesData)
    };
    return data;
  }

  const getTrackingNumbers = (trackingNumbersData) => {
    const trackingNumbers = trackingNumbersData.map((data) => {
      return data.tracking_no;
    })
    return trackingNumbers
  }

  const handleEmailInputChange = (e) => {
    setEmailInputValue(e.target.value);
    setCsvDownloadError(false);
  }

  const radioValueHandler = (e) => {
    setRadioValue(e.target.value);
  }

  const handlePageSizeSelect = (value) => {
    setPageSize(parseInt(value));
  }

  const reportTypeOptionClass = reportState.reportType ? '' : 'mt-3 mb-3';
  const rangePickerClass = reportState.reportType === 'cargo' ? 'mt-3' : 'd-none';
  const reportTrackingClass = reportState.reportType === 'parcel' ? 'mt-3' : 'd-none';
  const [form] = Form.useForm();

  return (
    <div className="bg-container">
      <p className="bread-crumb-container">
        <span className="link-title" onClick={handleHubsClick}>Hubs</span>
        <span className="bread-crumb">{'>'}</span>
        <span className="link-title" onClick={goToHubsPage}>{company && company.name}</span>
        <span className="bread-crumb">{'>'}</span>
        <span className="title">{hub && hub.hub_name}</span>
      </p>
      <div className="bg-container-body">
        <div className="d-flex p-2 justify-content-between align-items-center mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className={`mr-3 cursor-pointer ${showComponent === COMPONENT_PACKAGE ? 'active' : 'inactive'}`} onClick={() => setShowComponet(COMPONENT_PACKAGE)}>Packages</div>
            <div className="vertical-line"></div>
            <div className={`ml-3 mr-3 cursor-pointer ${showComponent === CARGO_RECONCILIATION ? 'active' : 'inactive'}`} onClick={() => setShowComponet(CARGO_RECONCILIATION)}>Cargo Reconciliation</div>
            <div className="vertical-line"></div>
            <div className={`ml-3 cursor-pointer ${showComponent === COMPONENT_HUB_DETAILS ? 'active' : 'inactive'}`} onClick={() => setShowComponet(COMPONENT_HUB_DETAILS)}>Hub Details</div>
          </div>
          <div>
            {renderHubOrPackgeButtons()}
          </div>
        </div>
        {renderHubOrPackageComponent()}
      </div>
      {generateReportModal()}
      {generateCSVModal()}
    </div>
  )

  function renderHubOrPackageComponent() {
    if (showComponent === COMPONENT_PACKAGE && hub) {
      return <HubPackages hubId={hub.hub_id} pageSize={pageSize} />
    }
    else if (showComponent === CARGO_RECONCILIATION) {
      return <HubCargos hubId={hub.hub_id} pageSize={pageSize} />
    }
    else if (showComponent === COMPONENT_HUB_DETAILS) {
      return <HubDetails state={params} />
    };
    return null;
  }

  function pageSizeDropDown() {
    return (
      <DropdownButton
        alignRight
        title={`${pageSize} items/page`}
        id="dropdown-menu-align-right"
        className="btn-group ml-2 dropdown-btn"
        onSelect={handlePageSizeSelect}
      >
        <Dropdown.Item eventKey={20}>20 items</Dropdown.Item>
        <Dropdown.Item eventKey={50}>50 items</Dropdown.Item>
        <Dropdown.Item eventKey={100}>100 items</Dropdown.Item>
      </DropdownButton>
    );
  }

  function renderHubOrPackgeButtons() {
    if (showComponent === COMPONENT_PACKAGE) {
      return (
        <>
          <button className="secondary-button" onClick={dispalyGenerateCSVModal}>Generate CSV</button>
          {pageSizeDropDown()}
        </>
      )
    }
    else if (showComponent === CARGO_RECONCILIATION) {
      return (
        <>
          <button className="secondary-button" onClick={displayGenerateReportModal}>Generate Report</button>
          {pageSizeDropDown()}
        </>
      )
    }
    else if (showComponent === COMPONENT_HUB_DETAILS) {
      return <button className="secondary-button secondary-button-margin-md" onClick={handleEditHubClick}>Edit Hub</button>
    }
    return null;
  }

  function handleReportStateChange(name, value) {
    setReportState({
      ...reportState,
      [name]: value
    });
  }

  function handleRangeChange(value, dateString) {
    setReportState({
      ...reportState,
      reportDateRange: dateString
    });
  }

  function generateReportModal() {
    return (
      <Modal
        className="generate-csv-modal"
        visible={showReportModal}
        footer={null}
        okText="Confirm"
        okButtonProps={{ className: "genereate-csv-modal-primary-button" }}
        cancelButtonProps={{ className: "generate-csv-modal-secondary-button" }}
        cancelText="Cancel"
      >
        <div className="text-left">
          <h2 className="generate-csv-heading mb-0">
            Generate Report
          </h2>
          <p className="generate-csv-paragraph mb-0"> The report in CSV will be sent to your email address after it is generated.
          </p>
          <Form form={form} onFinish={generateReport} initialValues={reportState}>
            <Form.Item
              name="email"
              className="mt-3 mb-3"
              rules={[{ type: 'email', required: true, message: 'Please enter email address first' }]}
            >
              <CustomInput
                label="Email address"
                name="email"
                placeholder="Type here"
                value={reportState.email}
                onChange={(e) => handleReportStateChange('email',e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="reportType"
              className={reportTypeOptionClass}
              rules={[{ type: 'string', required: true, message: 'Please select a report level first' }]}
            >
              <CustomSelect
                className="w-100"
                label="Report Level"
                name="reportType"
                placeholder="Select"
                active={reportState.reportType}
                onChange={(value) => handleReportStateChange('reportType', value)}
                options={reportTypeOptions}
              />
            </Form.Item>
            <Form.Item
              name="reportDateRange"
              className={rangePickerClass}
            >
              <CustomRangePicker
                className="w-100"
                label="Cargo Inbound Date & Time Range (Optional)"
                placeholder={['From', 'To']}
                value={reportState.reportDateRange}
                onChange={handleRangeChange}
              />
            </Form.Item>

            {reportState.reportType === 'parcel' &&
            <Form.Item
              name="reportTrackingNumber"
              className="mt-3"
              rules={[{ type: 'string', required: true, message: 'Please enter cargo tracking number(s) first' }]}
            >
              <TextArea
                rows={4}
                className={"w-100 " + trackingNumberErrorClass}
                label="Tracking Number(s) of Cargo"
                placeholder="Type here (you may separate the tracking numbers by commas or by directly copying them from a spreadsheet column)"
                value={reportState.reportTrackingNumber}
                onChange={(e) => handleReportStateChange('reportTrackingNumber', e.target.value)}
              />
            </Form.Item>
            }
            {reportState.reportTrackingNumberError &&
              <div className="ant-form-item-explain mt-0 mb-0">
                {reportState.reportTrackingNumberError}
              </div>}
            <Divider className="gray-divider" />
            {reportState.reportError &&
              <Alert
                className="mb-3"
                message="An error occured! Please try again."
                type="error"
              />}
            <div className="d-flex justify-content-between">
              <button
                className="generate-csv-modal-secondary-button"
                onClick={hideGenerateReportModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="generate-csv-modal-primary-button"
              >
                Confirm
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }

  function generateCSVModal() {
    return (
      <Modal
        className="generate-csv-modal"
        visible={showCSVModal}
        footer={null}
        okText="Confirm"
        okButtonProps={{ className: "genereate-csv-modal-primary-button" }}
        cancelButtonProps={{ className: "generate-csv-modal-secondary-button" }}
        cancelText="Cancel"
      >
        <div className="text-left">
          <h2 className="generate-csv-heading mb-0">
            Generate CSV
          </h2>
          <p className="generate-csv-paragraph mb-0"> The CSV will be sent to your email address after it is generated.
          </p>
          <div className="mt-3 mb-3">
            <label htmlFor="generateCSVEmail" className="generate-csv-modal-email-label">Email address</label>
            <input
              type="email"
              name="email"
              className={"form-control " + csvEmailErrorClass}
              id="generateCSVEmail"
              aria-describedby="emailHelp"
              placeholder="Type Here"
              value={emailInputValue}
              onChange={handleEmailInputChange}
            />
          </div>
          <Radio.Group className="generate-csv-modal-radio-group" value={radioValue} onChange={radioValueHandler}>
            <Radio value="filter data" className="d-block fnt-16" value="filtered">Only include filtered data in the table</Radio>
            <Radio value="all data" className="d-block fnt-16" value="all">Include all data</Radio>
          </Radio.Group>
          <Divider className="gray-divider" />
          {csvDownloadError && <Alert className="mb-3" message={csvDownloadError} type="error" />}
        </div>
        <div className="text-center">
          <button
            className="generate-csv-modal-secondary-button"
            onClick={hideGenereateCSVModal}
          >
            Cancel
          </button>
          <button
            className="generate-csv-modal-primary-button"
            onClick={generateCSVHandler}
          >
            Confirm
          </button>
        </div>
      </Modal>
    );
  }
}

export default HubPackageOrDetailsContainer;
