export const scanUsersTypes = {
  CREATE_COMPANY_SCAN_USER_LOADING: 'CREATE_COMPANY_SCAN_USER_LOADING',
  CREATE_COMPANY_SCAN_USER_SUCCESS: 'CREATE_COMPANY_SCAN_USER_SUCCESS',
  CREATE_COMPANY_SCAN_USER_FAILURE: 'CREATE_COMPANY_SCAN_USER_FAILURE',
  RESET_CREATED_COMPANY_SCAN_USER_DATA: 'RESET_CREATED_COMPANY_SCAN_USER_DATA',

  UPDATE_COMPANY_SCAN_USER_LOADING: 'UPDATE_COMPANY_SCAN_USER_LOADING',
  UPDATE_COMPANY_SCAN_USER_SUCCESS: 'UPDATE_COMPANY_SCAN_USER_SUCCESS',
  UPDATE_COMPANY_SCAN_USER_FAILURE: 'UPDATE_COMPANY_SCAN_USER_FAILURE',
  RESET_UPDATED_COMPANY_SCAN_USER_DATA: 'RESET_UPDATED_COMPANY_SCAN_USER_DATA',

  FETCH_COMPANY_SCAN_USERS_LOADING: 'FETCH_COMPANY_SCAN_USERS_LOADING',
  FETCH_COMPANY_SCAN_USERS_SUCCESS: 'FETCH_COMPANY_SCAN_USERS_SUCCESS',
  FETCH_COMPANY_SCAN_USERS_FAILURE: 'FETCH_COMPANY_SCAN_USERS_FAILURE',

  FETCH_INACTIVE_COMPANY_SCAN_USERS_LOADING: 'FETCH_INACTIVE_COMPANY_SCAN_USERS_LOADING',
  FETCH_INACTIVE_COMPANY_SCAN_USERS_SUCCESS: 'FETCH_INACTIVE_COMPANY_SCAN_USERS_SUCCESS',
  FETCH_INACTIVE_COMPANY_SCAN_USERS_FAILURE: 'FETCH_INACTIVE_COMPANY_SCAN_USERS_FAILURE',

  CREATE_HUB_SCAN_USER_LOADING: 'CREATE_HUB_SCAN_USER_LOADING',
  CREATE_HUB_SCAN_USER_SUCCESS: 'CREATE_HUB_SCAN_USER_SUCCESS',
  CREATE_HUB_SCAN_USER_FAILURE: 'CREATE_HUB_SCAN_USER_FAILURE',
  RESET_CREATED_HUB_SCAN_USER_DATA: 'RESET_CREATED_HUB_SCAN_USER_DATA',

  UPDATE_HUB_SCAN_USER_LOADING: 'UPDATE_HUB_SCAN_USER_LOADING',
  UPDATE_HUB_SCAN_USER_SUCCESS: 'UPDATE_HUB_SCAN_USER_SUCCESS',
  UPDATE_HUB_SCAN_USER_FAILURE: 'UPDATE_HUB_SCAN_USER_FAILURE',
  RESET_UPDATED_HUB_SCAN_USER_DATA: 'RESET_UPDATED_HUB_SCAN_USER_DATA',

  FETCH_HUB_SCAN_USERS_LOADING: 'FETCH_HUB_SCAN_USERS_LOADING',
  FETCH_HUB_SCAN_USERS_SUCCESS: 'FETCH_HUB_SCAN_USERS_SUCCESS',
  FETCH_HUB_SCAN_USERS_FAILURE: 'FETCH_HUB_SCAN_USERS_FAILURE',
  
  FETCH_INACTIVE_HUB_SCAN_USERS_LOADING: 'FETCH_INACTIVE_HUB_SCAN_USERS_LOADING',
  FETCH_INACTIVE_HUB_SCAN_USERS_SUCCESS: 'FETCH_INACTIVE_HUB_SCAN_USERS_SUCCESS',
  FETCH_INACTIVE_HUB_SCAN_USERS_FAILURE: 'FETCH_INACTIVE_HUB_SCAN_USERS_FAILURE'
}