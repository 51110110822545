import React from 'react';
import { Route } from 'react-router-dom';
import Nav from '../../components/nav/nav';
import FilterScanTypes from './filterScanType/filterScanType';
import ScanTypeDetails from './scanTypeDetails/scanTypeDetails';
import AddScanType from './addScanType/addScanType';


export default function ScanTypes() {
  return (
    <>
      <Nav />
      <Route exact path="/scan-types" component={FilterScanTypes} />
      <Route exact path="/scan-types/:typeName/:companyId" component={ScanTypeDetails} />
      <Route exact path="/scan-types/type/add/:companyId" component={AddScanType} />
      <Route exact path="/scan-types/type/update/:typeName/:companyId/:scanTypeId" component={AddScanType} />
    </>
  );
}
