/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { message, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { scanTypesAction, companyAction } from '../../../actions';
import * as _ from 'lodash';
import './style.scss'

export default function ScanTypeDetails() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { companies } = useSelector(state => state.companies);
  const {
    scanTypes,
    inactiveScanTypes,
    scanTypeCreated,
    addScanTypeSuccessMessage,
    scanTypeUpdated,
    updateScanTypeSuccessMessage
  } = useSelector(state => state.scanTypes);

  useEffect(() => {
    dispatch(scanTypesAction.getActiveScanTypes(params));
    dispatch(scanTypesAction.getInactiveScanTypes(params));
  }, [params, dispatch]);

  useEffect(() => {
    if (companies && companies.length === 0) {
      dispatch(companyAction.getActiveCompanies());
    };
  }, [dispatch, companies]);

  useEffect(() => {
    if (scanTypeCreated) {
      message.success(addScanTypeSuccessMessage);
      dispatch(scanTypesAction.resetCreateScanTypeData());
    }
  }, [scanTypeCreated, addScanTypeSuccessMessage, dispatch]);

  useEffect(() => {
    if (scanTypeUpdated) {
      message.success(updateScanTypeSuccessMessage);
      dispatch(scanTypesAction.resetUpdatedScanTypeData());
    }
  }, [scanTypeUpdated, updateScanTypeSuccessMessage, dispatch]);

  const company = _.find(companies, x => String(x.id) === params.companyId);

  return (
    <>
      <div className="bg-container">
        <p className="bread-crumb-container">
          <span className="link-title" onClick={handleScanTypesClick}>Scan Types</span>
          <span className="bread-crumb">{'>'}</span>
          <span className="title">{company && company.name}</span>
        </p>
        <div className="bg-container-body">
          <div className="d-flex justify-content-md-between align-items-md-end flex-md-row flex-column">
            <h2 className="heading-link" onClick={handleCompanyNameClick}>
              Company: {company && company.name}
            </h2>
            <button className="secondary-button secondary-button-margin-md" onClick={handleAddScanTypeClick}>Add Scan Type</button>
          </div>
          <Divider className="gray-divider" />
          <div className="d-flex flex-column heading-area">
            <h2 className="heading heading-area">Active Scan Types</h2>
            <span className="sub-heading">Click on a scan type to edit it.</span>
          </div>
          <div className="row">
            {renderActiveTypes()}
          </div>

          {inactiveScanTypes && inactiveScanTypes.length > 0 &&
            <React.Fragment>
            <Divider className="gray-divider mt-0" />
              <div className="d-flex flex-column">
                <h2 className="heading heading-area">Inactive Scan Types</h2>
              </div>
                <div className="row">
                {renderInactiveTypes()}
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </>
  );

  function handleCompanyNameClick() {
    history.push({
      pathname: `/scan-types`,
      state: {
        companyId: params.companyId
      }
    });
  }

  function renderActiveTypes() {
    return scanTypes && scanTypes.length > 0 && scanTypes.map((type, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 scan-type-link-container"
        onClick={() => handleScanTypeDetailsClick(type)}>
        <span className="text-link">{type.scan_type_name}</span>
      </div>
    })
  }

  function renderInactiveTypes() {
    return inactiveScanTypes.map((type, index) => {
      return <div
        key={index}
        className="col-md-4 col-sm-6 col-12 scan-type-link-container"
        onClick={() => handleScanTypeDetailsClick(type)}>
        <span className="text-link">{type.scan_type_name}</span>
      </div>
    })
  }

  function handleScanTypeDetailsClick(type) {
    dispatch(scanTypesAction.resetCreateScanTypeData());
    dispatch(scanTypesAction.resetUpdatedScanTypeData());
    let typeName = type.scan_type_name.replace(/\s+/g, '-').toLowerCase();
    history.push(`/scan-types/type/update/${typeName}/${params.companyId}/${type.id}`);
  }

  function handleScanTypesClick() {
    history.push('/scan-types');
  }

  function handleAddScanTypeClick() {
    dispatch(scanTypesAction.resetCreateScanTypeData());
    dispatch(scanTypesAction.resetUpdatedScanTypeData());
    history.push(`/scan-types/type/add/${params.companyId}`);
  }
}
