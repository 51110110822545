import axios from 'axios';
import { ROOT_URL } from './rootUrl';

const login = (data) => {
  return axios.post(`${ROOT_URL}/api/hms/login/`, data)
  .then(res => {
    return res.data
  })
}

export const loginService = {
  login
}
