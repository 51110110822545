import { companyAdminTypes } from '../types';
import { companyAdminService } from '../services';

const createCompanyAdmin = (adminData) => {
  const loading = () => ({ type: companyAdminTypes.CREATE_COMPANY_ADMIN_LOADING })
  const success = (payload) => ({ type: companyAdminTypes.CREATE_COMPANY_ADMIN_SUCCESS, payload })
  const failure = (payload) => ({ type: companyAdminTypes.CREATE_COMPANY_ADMIN_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await companyAdminService.createCompanyAdmin(adminData);
      let successMessage = `New Company Admin ${adminData.admin_name} has been added`;
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetCreatedCompanyAdminData = () => {
  return function (dispatch) {
    dispatch({ type: companyAdminTypes.RESET_CREATED_COMPANY_ADMIN_DATA });
  }
}

const updateCompanyAdmin = (adminData) => {
  const loading = () => ({ type: companyAdminTypes.UPDATE_COMPANY_ADMIN_LOADING })
  const success = (payload) => ({ type: companyAdminTypes.UPDATE_COMPANY_ADMIN_SUCCESS, payload })
  const failure = (payload) => ({ type: companyAdminTypes.UPDATE_COMPANY_ADMIN_FAILURE, payload })

  return async function (dispatch) {
    loading()
    try {
      await companyAdminService.updateCompanyAdmin(adminData);
      // let successMessage = `Company ${adminData.admin_name} is ${adminData.is_active ? 'active' : 'inactive'}`;
      let successMessage = `Company admin ${adminData.admin_name} has been edited`;
      dispatch(success(successMessage))
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage))
    }
  }
}

const resetUpdatedCompanyAdminData = () => {
  return function (dispatch) {
    dispatch({ type: companyAdminTypes.RESET_UPDATED_COMPANY_ADMIN_DATA });
  }
}

const getCompanyActiveAdmins = (companyId) => {
  const loading = () => ({ type: companyAdminTypes.FETCH_COMPANY_ACTIVE_ADMINS_LOADING })
  const success = (payload) => ({ type: companyAdminTypes.FETCH_COMPANY_ACTIVE_ADMINS_SUCCESS, payload })
  const failure = (payload) => ({ type: companyAdminTypes.FETCH_COMPANY_ACTIVE_ADMINS_FAILURE, payload })

  return async function (dispatch) {
    loading();
    try {
      let companyActiveAdmins = await companyAdminService.getCompanyActiveAdmins(companyId);
      dispatch(success(companyActiveAdmins));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}

const getCompanyInactiveAdmins = (companyId) => {
  const loading = () => ({ type: companyAdminTypes.FETCH_COMPANY_INACTIVE_ADMINS_LOADING })
  const success = (payload) => ({ type: companyAdminTypes.FETCH_COMPANY_INACTIVE_ADMINS_SUCCESS, payload })
  const failure = (payload) => ({ type: companyAdminTypes.FETCH_COMPANY_INACTIVE_ADMINS_FAILURE, payload })

  return async function (dispatch) {
    loading();
    try {
      let companyInactiveAdmins = await companyAdminService.getCompanyInactiveAdmins(companyId);
      dispatch(success(companyInactiveAdmins));
    } catch (err) {
      const errorMessage = err.response.data;
      dispatch(failure(errorMessage));
    }
  }
}

export const companyAdminActions = {
  createCompanyAdmin,
  resetCreatedCompanyAdminData,
  updateCompanyAdmin,
  resetUpdatedCompanyAdminData,
  getCompanyActiveAdmins,
  getCompanyInactiveAdmins
}