export const hubTypes = {
  FETCH_ACTIVE_HUBS_LOADING: 'FETCH_ACTIVE_HUBS_LOADING',
  FETCH_ACTIVE_HUBS_SUCCESS: 'FETCH_ACTIVE_HUBS_SUCCESS',
  FETCH_ACTIVE_HUBS_FAILURE: 'FETCH_ACTIVE_HUBS_FAILURE',
  
  FETCH_INACTIVE_HUBS_LOADING: 'FETCH_INACTIVE_HUBS_LOADING',
  FETCH_INACTIVE_HUBS_SUCCESS: 'FETCH_INACTIVE_HUBS_SUCCESS',
  FETCH_INACTIVE_HUBS_FAILURE: 'FETCH_INACTIVE_HUBS_FAILURE',
  
  CREATE_HUB_LOADING: 'CREATE_HUB_LOADING',
  CREATE_HUB_SUCCESS: 'CREATE_HUB_SUCCESS',
  CREATE_HUB_FAILURE: 'CREATE_HUB_FAILURE',
  RESET_CREATED_HUB_DATA: 'RESET_CREATED_HUB_DATA',

  UPDATE_HUB_LOADING: 'UPDATE_HUB_LOADING',
  UPDATE_HUB_SUCCESS: 'UPDATE_HUB_SUCCESS',
  UPDATE_HUB_FAILURE: 'UPDATE_HUB_FAILURE',
  RESET_UPDATED_HUB_DATA: 'RESET_UPDATED_HUB_DATA'
}