export const toolsTypes = {
  ADD_BULK_EXCEPTION_LOADING: "ADD_BULK_EXCEPTION_LOADING",
  ADD_BULK_EXCEPTION_SUCCESS: "ADD_BULK_EXCEPTION_SUCCESS",
  ADD_BULK_EXCEPTION_FAILURE: "ADD_BULK_EXCEPTION_FAILURE",

  RESOURCE_CLEANUP: "RESOURCE_CLEANUP",
  CREATE_RESOURCE_SUCCESS: "CREATE_RESOURCE",
  CREATE_RESOURCE_ERROR: "CREATE_RESOURCE_ERROR",
  UPDATE_RESOURCE_SUCCESS: "UPDATE_RESOURCE_SUCCESS",
  UPDATE_RESOURCE_ERROR: "UPDATE_RESOURCE_ERROR",
  DELETE_RESOURCE_SUCCESS: "DELETE_RESOURCE_SUCCESS",
  DELETE_RESOURCE_ERROR: "DELETE_RESOURCE_ERROR",
  GET_RESOURCE_SUCCESS: "GET_RESOURCE_SUCCESS",
  GET_RESOURCE_ERROR: "GET_RESOURCE_ERROR",

  CREATE_RESOURCE_TAG_SUCCESS: "CREATE_RESOURCE_TAG_SUCCESS",
  UPDATE_RESOURCE_TAG: "UPDATE_RESOURCE_TAG",
  DELETE_RESOURCE_TAG: "DELETE_RESOURCE_TAG",
  GET_RESOURCE_TAG_SUCCESS: "GET_RESOURCE_TAG_SUCCESS",
  GET_STANDARD_RESOURCE_REPORT_DATA_SUCCESS:
    "GET_STANDARD_RESOURCE_REPORT_DATA_SUCCESS",
  GET_AGGREGATED_RESOURCE_REPORT_DATA_SUCCESS:
    "GET_AGGREGATED_RESOURCE_REPORT_DATA_SUCCESS",
};
